/* eslint-disable max-len */
export default {
  'a1Certificate': 'Social security certificate (e.g. A1)',
  'accessPermit.addAndProceedToOrientation': 'Add and proceed to orientation',
  'accessPermit.addMissingCompetence': 'Add missing competence',
  'accessPermit.addMissingCompetences': 'Add missing competences',
  'accessPermit.addMissingScanOfCertificate': 'Add missing photo of certificate',
  'accessPermit.addRequiredCompetencesFirst': 'Please add the required competences first.',
  'accessPermit.admittance.department.valid': 'The department specific admittance is valid {0} - {1}',
  'accessPermit.admittance.site.invalid': 'The employee has no admittance to the site.',
  'accessPermit.admittance.site.valid': 'The site specific admittance is valid {0} - {1}',
  'accessPermit.admittanceInfo': 'Admittance to the site is based on the access permits and the validity of required competences.',
  'accessPermit.apprix.courseInfo.addEmail': 'Add an email address for the person to link the person to Apprix.',
  'accessPermit.apprix.courseInfo.fail': 'Linking failed',
  'accessPermit.apprix.courseInfo.info': 'The site is using Apprix training environment. You can link the employee to Apprix again by clicking the button below. The employee will receive an email with a link to the training environment.',
  'accessPermit.apprix.courseInfo.relink': 'Link again',
  'accessPermit.apprix.courseInfo.success': 'Linking succeeded',
  'accessPermit.approval.allRequestedChangedDone': 'All requested changes have been made',
  'accessPermit.approval.approve': 'Approve person',
  'accessPermit.approval.approved': 'Approved {0}',
  'accessPermit.approval.changesAreStillBeingMade': 'Changes are still being made',
  'accessPermit.approval.changesRequested': 'Changes requested',
  'accessPermit.approval.changesRequestedBy': 'Changes requested by: {0}',
  'accessPermit.approval.checkAccessPermitLabels': 'Check labels of the access permit.',
  'accessPermit.approval.confirm': 'Approve permit <strong>{1}</strong> of <strong>{0}</strong>?',
  'accessPermit.approval.decision': 'Decision',
  'accessPermit.approval.heading': 'Verify right to work',
  'accessPermit.approval.headingForSummary': 'Right to work',
  'accessPermit.approval.notPossible': 'Access permit cannot be approved.',
  'accessPermit.approval.missingEmail': 'The person does not have an email address set.',
  'accessPermit.approval.missingPhone': 'The person does not have a phone number set.',
  'accessPermit.approval.missingPhoneEmail': 'The person does not have an email address nor phone number set.',
  'accessPermit.approval.periodType.max': 'Maximum possible duration',
  'accessPermit.approval.removeLabels': 'Remove labels',
  'accessPermit.approval.requestChanges': 'Request changes',
  'accessPermit.approval.requestedChangesAlert': 'Verification of right to work failed. Some of the information was incorrect and/or missing and should be corrected.',
  'accessPermit.approval.requestedChangesStatus': 'Have all the requested changes been made?',
  'accessPermit.approval.requirements.expat.residencePermitDurationMinusOne': 'Day of departure from the country taken into account. The official date of the document is {0}.',
  'accessPermit.approval.requirementsLoadError': 'Requirements could not be loaded.',
  'accessPermit.approval.retainLabels': 'Retain labels',
  'accessPermit.approval.sendChangeRequest': 'Request changes',
  'accessPermit.approval.updateLabels': 'Update labels',
  'accessPermit.approval.validationPeriod': 'Access permit validity period',
  'accessPermit.approved': 'Approved',
  'accessPermit.approvedInfo': 'The access permit has been approved and it cannot be modified.',
  'accessPermit.cannotBeRemoved': 'Permit cannot be removed',
  'accessPermit.changesInWorkHourBalance': 'Changes in balance',
  'accessPermit.checkContactInfo': 'Please make sure this person\'s contact information is up to date.',
  'accessPermit.confirmRemoval': 'Remove access permit <strong>{0}</strong> from person <strong>{1}</strong>?',
  'accessPermit.contactInfoChecked': 'Yes, contact information is up to date',
  'accessPermit.created': 'Created',
  'accessPermit.createdAsVisitorInfo': '<strong>Visitor permit.</strong> The site has used visitor permits in the past when this permit was created.',
  'accessPermit.createdWithVehicleInfo': '<strong>Vehicle permit.</strong> The site has used vehicle permits in the past when this access permit was created. You can remove the vehicle permit if you want.',
  'accessPermit.createFromUnresolvedCheckIn.areYouSure': 'Are you sure you want to create new a access permit? The check in can be also attached to the existing permits by clicking the \'Handle\' -link.',
  'accessPermit.createFromUnresolvedCheckIn.personHasAlreadyPermitsAtSite': 'Person has already the following access permits at site',
  'accessPermit.earlierA1Certificate': 'Earlier social security certificate is valid until <strong>{0}</strong>.',
  'accessPermit.earlierExpatRep': 'Earlier representative has been <strong>{0}</strong>.',
  'accessPermit.earlierExpatRepListStart': 'Person who has worked as a representative before',
  'accessPermit.earlierIdConfirmation': 'Previously used id ({0})',
  'accessPermit.earlierSiteCard': 'Previously used site card ({0})',
  'accessPermit.employer': 'Employer',
  'accessPermit.expatInfo': 'Generally, a posted worker means a worker under following conditions:<ul><li>The employer is not Finnish.</li><li>The employee normally works outside of Finland.</li><li>The employee is posted to Finland temporarily.</li></ul><a target="_blank" href="https://www.tyosuojelu.fi/web/en/employment-relationship/posted-worker">Read more <span class="far fa-external-link"></span></a>',
  'accessPermit.expatInfoMessage': 'The person has been marked as a posted worker, as their principal country of employment is not Finland. A certificate of social security is required from the posted worker. <a href="https://tyosuojelu.fi/tyosuhde/lahetetty-tyontekija\" rel="noopener" target="_blank">More information <span class="far fa-external-link"></span></a> (tyosuojelu.fi). Additionally, the person must be reported to the Regional State Administrative Agency: <a href="https://asiointipalvelu.ahtp.fi/forms/2627047\" rel="noopener" target="_blank">Notification of posting workers <span class="far fa-external-link"></span></a> (asiointipalvelu.ahtp.fi).',
  'accessPermit.expatPersonnelReport': 'Report of expat workers',
  'accessPermit.expatPersonnelReportDate': 'Report date',
  'accessPermit.expatUncheckModal.cancel': 'No',
  'accessPermit.expatUncheckModal.confirm': 'Yes, change the principal country of employment',
  'accessPermit.expatUncheckModal.content': '<p>The person has been marked as a posted worker because a country other than Finland has been marked as the principal country of employment. The principal country of employment is the country where the person works for more than 6 months of the year.</p><p>Is Finland the person’s principal country of employment and should this be recorded in the person\'s information?</p>',
  'accessPermit.expatUncheckModal.error': 'Error: Could not update the posted worker status.',
  'accessPermit.expatUncheckModal.heading': 'Principal country of employment',
  'accessPermit.expatUncheckModal.saving': 'Changing the principal country of employment...',
  'accessPermit.expertise.contextInfo': 'The employee will receive an sms with details about completing the course after the access permit has been created.',
  'accessPermit.expertise.missingExpertise': 'The employee is missing expertise (or has expertise expiring within the month) required on the site.',
  'accessPermit.expertise.missingExpertisePlural': 'The employee is missing expertise (or has expertise expiring within the month) required on the site.',
  'accessPermit.expertise.missingOptionalExpertise': 'The employee is missing an optional competence. This does not block the admittance to the site.',
  'accessPermit.expertise.missingOptionalExpertisePlural': 'The employee is missing optional competences. This does not block the admittance to the site.',
  'accessPermit.expertise.missingRequiredExpertise': 'Deficiencies',
  'accessPermit.expertise.missingScanOfCompetenceCertificate': 'Employee is missing the photo of the certificate for a competence.',
  'accessPermit.expertise.missingScanOfCompetenceCertificatePlural': 'Employee is missing a photo of certificate for the following competences.',
  'accessPermit.expertise.newRequirement': 'New requirement',
  'accessPermit.expertise.performedInExternalSystem': 'The employee performs the course in an external system.',
  'accessPermit.expertise.validityBasedOnCertificateScanInfoPlural': 'The permit will be saved, but it becomes valid only after the photos of certificates have been attached.',
  'accessPermit.expertise.validityBasedOnCertificateScanInfoSingular': 'The permit will be saved, but it becomes valid only after the photo of the certificate has been attached.',
  'accessPermit.expertise.validityInfoPlural': 'The permit will be saved, but it becomes valid only after valid competences have been added.',
  'accessPermit.expertise.validityInfoSingular': 'The permit will be saved, but it becomes valid only after a valid competence has been added.',
  'accessPermit.foreignEmployeeRepresentative': 'Representative in Finland for expat or foreign employee',
  'accessPermit.foreignEmployeeRepresentativeMany': 'Representative in Finland for expats or foreign employees',
  'accessPermit.foremanNeedsContactInfo': '<strong>Person serves as a foreman</strong> and, as such, contact information is needed.',
  'accessPermit.fortyhours': '40 hours',
  'accessPermit.hasDataCannotRemove': 'Permit has check ins or invoice hour rows and, therefore, cannot be removed. It\'s termination, however, is possible.',
  'accessPermit.idCardPhotoAdded': 'Added',
  'accessPermit.idCardPhotoNotAdded': 'Not yet added',
  'accessPermit.labels.infoText': 'Everyone can see these. Only buyer and main contractor can modify.',
  'accessPermit.labels.noLabels': 'No remarks',
  'accessPermit.labels': 'Remarks',
  'accessPermit.latestCheckIn': 'Latest check in',
  'accessPermit.locked': 'Locked',
  'accessPermit.maxPeriodExceeded': 'The end date has been set further than what the requirements allow.',
  'accessPermit.maxPeriodFromRequirements': 'Requirements fulfilled during period',
  'accessPermit.maxPeriodFromRequirementsChanged': 'Requirements not fulfilled. Requirements have probably changed after permit approval.',
  'accessPermit.maxPeriodFromRequirementsSet': 'Duration is set to maximum length, according to the related contract and possible requirements.',
  'accessPermit.maxPeriodNotAvailable': 'Validity period could not be calculated from requirements. Probably due to a missing requirement.',
  'accessPermit.newCompetenceRequirement': 'New competence requirement',
  'accessPermit.newWorkHourBalance': 'New balance',
  'accessPermit.noIdentifierNoVisitorOption2': '<strong>The person has no identifiers set and visitor permits are not allowed on the site.</strong> Add an identifier, for an example a tax number, for the employee. Alternatively the main contractor can allow visitor permits from site settings and create visitor permits.',
  'accessPermit.noIdentifiers': 'The person needs to have an identifier, like a tax number, set in order to create an employee access permit.',
  'accessPermit.noIdentifiersNoVisitorOption1': '<strong>The person has no identifiers set.</strong> Only visitor permits are allowed without an identifier. You do not have permission to create a visitor permit. Add an identifier, for an example a tax number, for the employee or ask the main contractor to add the visitor permit.',
  'accessPermit.noPreviousWorkHourBalanceUpdates': 'No previous balance updates',
  'accessPermit.noWorkHourBalance': 'No balance',
  'accessPermit.orientationAfterCheckIn': 'Orientation performed after checking in',
  'accessPermit.orientationAfterCheckInDetails': 'This person checked in on <strong>{0}</strong>, but the orientation is marked to have happened on <strong>{1}</strong>. Orientation should be completed before working on the site.',
  'accessPermit.overlapInfo': 'You can create a new permit, but check ins created from a check in device will be copied to every permit. If the employee creates a check in with his own device, he has to choose the permit for which the check in is made.',
  'accessPermit.overlapNotPossible': 'Only one active permit allowed for each employer on each site.',
  'accessPermit.pending': 'Pending',
  'accessPermit.personMissingExpertise': 'The access permit will become valid and orientation can be performed only after the required expertise has been added.',
  'accessPermit.personMissingExpertiseCertificates': 'Some competences are missing required photo of certificates.',
  'accessPermit.protacon.beginCourse': 'Begin the course in another window',
  'accessPermit.protacon.course': 'Completing the course',
  'accessPermit.protacon.courseInfo.addEmail': 'Add an email address for the person to link the person to Type.',
  'accessPermit.protacon.courseInfo.fail': 'Linking failed',
  'accessPermit.protacon.courseInfo.linkSuccessful': 'The employee has been linked to all Protacon Type courses required by the permit.',
  'accessPermit.protacon.courseInfo.missingCourses': 'The employee has not been linked to all <strong>Protacon Type</strong> courses required by the permit.',
  'accessPermit.protacon.courseInfo.notSuccessful': 'Are you sure that <strong>{0}</strong> is the correct email address? If it is correct you can try to link the person again by clicked the button below.',
  'accessPermit.protacon.courseInfo.relink': 'Link again',
  'accessPermit.protacon.linkFetchFail': 'Ordering failed. Try again later.',
  'accessPermit.protacon.linkValidUntil': 'The link is valid until {0}',
  'accessPermit.protacon.siteUsesProtacon': 'The site uses <strong>Protacon Type</strong> online courses. The employee needs an email address to complete the courses.',
  'accessPermit.reInviteForTrainings': 'Send the training invitation again',
  'accessPermit.removeAccessPermit': 'Remove access permit',
  'accessPermit.removeHeading': 'Remove access permit?',
  'accessPermit.removePreventedByCheckins': 'Access permit can\'t be removed because there are check ins related to it.',
  'accessPermit.removePreventedByInvoiceHours': 'Access permit can\'t be removed because it has approved invoice hour rows. If you still want to remove the row, please contact Zeroni support (support@zeroni.fi)',
  'accessPermit.removePreventedHeading': 'Can\'t remove access permit',
  'accessPermit.requirements': 'Requirements',
  'accessPermit.requirementsFulfilledDuringPeriod': 'Requirements are fulfilled during {0}.',
  'accessPermit.requirementsFulfilledDuringValidity': 'Requirements are fulfilled throughout the entire validity period of the access permit.',
  'accessPermit.revoked': 'Rejected',
  'accessPermit.roles': 'Roles on site',
  'accessPermit.searchOrSelectLaborAgreement': 'Collective labor agreement',
  'accessPermit.setOrientationToFirstCheckIn': 'Change orientation date to match the first check in on {0}',
  'accessPermit.site.change': 'Change',
  'accessPermit.shortenedBy': 'Shortened according to {0}',
  'accessPermit.siteCardPhotoAdded': 'Added',
  'accessPermit.siteCardPhotoNotAdded': 'Not added',
  'accessPermit.thirtysevenandhalfhours': '37.5 hours',
  'accessPermit.useContractTimePeriod': 'Set permit to be valid according to contract ({0})',
  'accessPermit.useTimePeriod': 'Set permit to be valid according to ({0})',
  'accessPermit.validationPeriodIsSetByApprover': 'The validity period of the access permit is confirmed upon approval.',
  'accessPermit.vehicleNeedsContactInfo': '<strong>Vehicle permit</strong> requires the person to have up to date contact information.',
  'accessPermit.verification': 'Verification of right to work',
  'accessPermit.waitingForIntroduction.fsm': 'Waiting for orientation',
  'accessPermit.waitingForIntroduction': 'Waiting for orientation',
  'accessPermit.whyNotVisible': 'Why not visible?',
  'accessPermit.whyNotVisibleHelpText': 'You do not have the right to view / modify this information. If you need the rights, contact your nearest administrator.',
  'accessPermit.withdrawApproval.confirm': 'Withdraw the verification of right to work for permit <strong>{1}</strong> of <strong>{0}</strong>? After this change the right of work has to be verified again.',
  'accessPermit.withdrawApproval.heading': 'Withdraw verification of right to work?',
  'accessPermit.workHourBalance.startDate': 'Start date',
  'accessPermit.workHourBalance.updateAuthor': 'Updated by',
  'accessPermit.workHourBalance': 'Balance',
  'accessPermit.workHoursPerWeek': 'Work hours per week',
  'actions': 'Actions',
  'activation.checkIns.failed': 'Loading of check-ins-failed.',
  'activation.failed': 'Activation failed.',
  'activation.retry': 'Try again',
  'activeLayouts': 'Active floor plans',
  'addRequiredInformationBeforeSign': 'Add required information before signing',
  'admin.devicesImeiOrCompany': 'Device IMEI or company',
  'alert.andWatchIntroductionVideo': 'and watch new <a href={0} target=\'_blank\'>introduction video.</a>',
  'alert.readMore': 'Learn more about version history',
  'alert.released': 'is released.',
  'alert.siteManagerVersion': 'Zeroni version',
  'allLayouts': 'All floor plans',
  'anonymousreport.anonymousReport': 'Anonymous report',
  'anonymousreport.anonymousReports': 'Anonymous reports',
  'anonymousreport.anonymousReportsShort': 'Anonymous',
  'anonymousreport.blackmarket': 'Black market',
  'anonymousreport.change': 'Change',
  'anonymousreport.confirmReport': 'Confirm report',
  'anonymousreport.feedback': 'Feedback',
  'anonymousreport.geolocationFailed': 'Could not get your location. Make sure you have allowed sharing of your location.',
  'anonymousreport.gettingLocation': 'Getting your location...',
  'anonymousreport.locateAgain': 'Locate again',
  'anonymousreport.located': 'Location found',
  'anonymousreport.lookingForSites': 'Looking for nearby sites...',
  'anonymousreport.noNearbySites': 'No sites found',
  'anonymousreport.refresh': 'Refresh',
  'anonymousreport.removeReport': 'Remove tapahtuma',
  'anonymousreport.reportSent': 'Report sent!',
  'anonymousreport.selectObservationType': 'Select observation type',
  'anonymousreport.showMoreSites': 'Show more sites',
  'anonymousreport.turnOnGeolocationInstructions': 'How can I enable location services on my device?',
  'app.newVersionAvailable.refreshCallToAction': 'Update now',
  'app.newVersionAvailable.title': 'Update for Zeroni available.',
  'assetManagement': 'Asset management',
  'attachment.showAttachment': 'Show attachment',
  'attachment.showAttachmentNo': 'Show attachment {0}',
  'beamer.showFeed': 'Show announcements',
  'beta.activate': 'Activate',
  'beta.addPersonsIntro': 'We rebuilt the way access permits are added. Try the new tool now!',
  'beta.openBeta': 'Change to updated tool',
  'beta.returnToOld': 'Back to the old view',
  'beta.useUpdatedInterface': 'Try the updated tool',
  'beta.youCanReturn': 'You can switch back to the original tool if you wish.',
  'beta': 'Beta',
  'checkIn.approve': 'Approve',
  'checkIn.approved': 'Approved',
  'checkIn.autoGeneratedTooltip': 'Check in is automatically generated',
  'checkIn.cannotResolve.info': 'Note, some check-ins cannot be attached to access permit because during the time of check in the tag holder has not had active access permit.',
  'checkIn.checkInCannotResolve': 'Check-in cannot be attached to any access permit.',
  'checkIn.checkInDelete': 'Delete this check-in?',
  'checkIn.checkInDeleteMessage': 'You are about to delete the check-in <strong>{0}: <span class="far fa-sign-in"></span> {1} <span class="far fa-sign-out"></span> {2}</strong>, from <strong>{3}</strong>. Are you sure you wish to delete this check-in?',
  'checkIn.checkInType.all': 'All check-ins',
  'checkIn.checkInType.gate': 'Other check-ins',
  'checkIn.checkInType.workHour': 'Work hour check-ins',
  'checkIn.contractAndSubcontractDigest': 'Cumulative work time breakdown for contract and its subcontracts',
  'checkIn.contractDigest': 'Work time breakdown for contract',
  'checkIn.devices.confirmRemoteOpening': 'Confirm remote opening',
  'checkIn.devices.missing': 'Device missing from the list?',
  'checkIn.devices.missingHelp': 'You can modify and add new devices on page:',
  'checkIn.devices.opening': 'Opening...',
  'checkIn.devices.remoteOpening': 'Remote opening',
  'checkIn.devices.removeFromOtherSites': 'Remove device from other sites',
  'checkIn.employeeDigest': 'Work time breakdown for employee',
  'checkIn.handle': 'Handle',
  'checkIn.handleCheckIn': 'Handle check-in',
  'checkIn.handleHelpText': 'The check-in will be either removed or attached to the existing work permit.',
  'checkIn.hideContractDigest': 'Hide work time breakdown for contract',
  'checkIn.hideEmployeeDigest': 'Hide work time breakdown for employee',
  'checkIn.includeSubcontractors': 'Include sub contractors',
  'checkIn.lunchSubtracted': 'Lunch (30min) subtracted',
  'checkIn.onlyIncomplete': 'Show only incomplete',
  'checkIn.printDigests': 'Print work time breakdowns',
  'checkIn.remove': 'Remove',
  'checkIn.removeConfirmation': 'Remove check-in?',
  'checkIn.removeOrResolveHelpText': 'One can either remove or attach given check-in to an existing access permit.',
  'checkIn.resolveSeveralCheckIns.headerText': 'Tag has several check-ins',
  'checkIn.resolveSeveralCheckIns.info': 'Multiple unattached check-ins are created with the given tag <strong>{0}</strong>. Below are options to attach or delete the listed check-ins. After selections, click \'Process\'.',
  'checkIn.resolveStraglerCheckInInfoExtra': 'Employee has active access permit into which the check-in can be attached.',
  'checkIn.searchJobNumber': 'Job number',
  'checkIn.searchResults': 'Search results',
  'checkIn.selectJobNumber': 'Select job number',
  'checkIn.showContractDigest': 'Show work time breakdown for contract',
  'checkIn.showDigestsAlways': 'Always show breakdown',
  'checkIn.showEmployeeDigest': 'Show work time breakdown for employee',
  'checkIn.showOnlyApprovableByMe': 'Only approvable by me',
  'checkIn.showOnlyApproved': 'Only approved',
  'checkIn.showOnlyFirstAndLastCheckins': 'Show only the first checkin and last checkout of the day',
  'checkIn.stragler.connected': 'Check-in connected to a permit',
  'checkIn.stragler.removed': 'Check-in removed',
  'checkIn.toExcel': 'Excel report',
  'checkIn.toExcelDetailed': 'Excel report(more details)',
  'checkIn.tryToConnectToAccessPermit': 'Attach to access permit',
  'checkIn.unallocated': 'Unallocated',
  'checkIn.warning.editAndDeleteDisabled': 'Editing and removing disabled when \'Show only first check-in and last check-out of the day\'-filter is used.',
  'checkIn.warning.noPermissions': 'Check-in modification is disabled. Consult the site\'s developer or main contractor for more information.',
  'checkIn.workHour': 'Work hour check-in',
  'checkIns.searchResults': 'Search results',
  'claned.allOk': 'The employee has been linked to all required Claned courses.',
  'claned.getSignInLink': 'Get the sign in link for the employee.',
  'claned.info': 'The site is using Claned training system.',
  'claned.linkFail': 'Linking failed',
  'claned.missingCourses': 'The employee has not yet been linked to all required Claned courses.',
  'claned.missingEmail': 'Add an email address for the employee to link him to Claned.',
  'claned.reLink': 'Link again',
  'company.addCertificate': 'Add certificate',
  'company.addNewCertificate': 'Add new certificate',
  'company.businessIdUpdateConfirmation': 'You\'re about to update company\'s business ID. <b>Please note that business ID can be updated via UI only if it was entered incorrectly during company\'s creation.</b> If business ID has changed due to merger, diffusion or any other non user error related reason, change must be done directly into database. Check company information before updating: <a href="https://tietopalvelu.ytj.fi/yritys/{0}" target="_blank">https://tietopalvelu.ytj.fi/yritys/{0}</a>',
  'company.businessIdUpdateConfirmationShort': 'Are you sure you want to update company\'s business ID?',
  'company.businessLine': '<strong>Business line:</strong>',
  'company.businessLines': '<strong>Business lines:</strong>',
  'company.certificate.expirationDate': 'Valid until {0}',
  'company.certificate.missing': 'Missing',
  'company.certificate.validUntil': 'Valid until {0}',
  'company.certificate': 'Certificate',
  'company.certificateAdded': 'Certificate <strong>{0}</strong> added.',
  'company.certificates': 'Certificates',
  'company.certificateUpdated': 'Certificate <strong>{0}</strong> updated.',
  'company.changesForAYear': 'Changes for the last 12 months.',
  'company.companyChange': 'Business id has changed {0}.',
  'company.companyContinuesAsMany': 'The company continues as {0} (business id {1}) among other companies. You can find more information from <a target=\'_blank\' rel=\'noopener\' href="https://www.ytj.fi/">YTJ database</a>, for example.',
  'company.companyContinuesAsOne': 'The company continues as {0} (business id {1}). You can find more information from <a target=\'_blank\' rel=\'noopener\' href="https://www.ytj.fi/">YTJ database</a>, for example.',
  'company.companyHasAcquired': '<strong>The company has acquired the following companies:</strong>',
  'company.companyHasSplit': '<strong>The company has been split among the following companies:</strong>',
  'company.companyHomeCountry': 'Company home country',
  'company.companyInLiquidation': 'The company has been {0} since {1}.',
  'company.companyInLiquidationWithName': 'The company {0} has been {1} since {2}.',
  'company.companyMergedTo_': '<strong>Company has merged into company: </strong>',
  'company.companyMergedToNoName': 'The company has been using a new business id {0} (name is not available) since {1}.',
  'company.companyMergedToWithName': 'The company has merged to {0} ({1}) {2}.',
  'company.contractlessIntro': '<b>This company will be added without a contract</b>. Add relevant info so that company can be used when searching for a provider for material or labor.',
  'company.discontinued': 'The company has been brought to an end {0}.',
  'company.error.evaluationRemoved': 'Error while removing vendor evaluation',
  'company.error.evaluationSaving': 'Error while saving vendor evaluation.',
  'company.evaluate': 'Evaluation',
  'company.evaluationRemoved': 'Vendor evaluation removed',
  'company.evaluationSaved': 'Vendor evaluation saved',
  'company.fixingIncorrectBusinessId': 'I\'m fixing incorrect business ID',
  'company.generalInfo': 'General information',
  'company.hasPaidWorkForce': 'Company has paid work force',
  'company.jobTitleExamples': 'E.g. excavation, electrics, demolition',
  'company.managesItself': '<strong>{0}</strong> manages its own data. If changes are needed, you may send them to company contact person (see below).',
  'company.materialExamples': 'E.g. timber, metal products, stoneware',
  'company.newCertificate': 'New certificate',
  'company.noCertificates': 'No certificates',
  'company.onlyWithActiveContracts': 'Active contracts',
  'company.paidWorkForce': 'Paid work force',
  'company.prhNameSuggestion': 'Use name <strong>{0}</strong> (The name corresponding to the business id since {1} according to PRH.)',
  'company.prhSource': 'Source: <a target="_blank" rel="noopener" href="http://avoindata.prh.fi/index_en.html">Finnish Patent and Registration Office (PRH).</a> The content has been modified. Licence <a target="_blank" rel="noopener" href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0.</a>',
  'company.reliablePartner.foreignCompanyInfo': 'Vastuu Group does not offer an automatic check of the Reliable Partner status for foreign companies. You can manually verify the information using the Company Search service provided by <a target="_blank" rel="noopener" href="https://www.vastuugroup.fi/">Vastuu Group</a>.',
  'company.removedFromTradeRegister': 'The company {0} has been removed from the trage register {1}.',
  'company.search.evaluation': 'Evaluation',
  'company.search.jobTitle': 'Job title',
  'company.search.jobTitles': 'Job titles',
  'company.searchByBusinessIdOrName': 'Type either the finnish business id or the beginning of the company\'s name.',
  'company.searchCertificates': 'Search certificates',
  'company.searchOrSelectCompanies': 'Companies',
  'company.searchOrSelectCompany': 'Company',
  'company.summary.activeContracts': 'Active contracts',
  'company.summary.endedContracts': 'Ended contracts',
  'company.updateCertificate': 'Update certificate',
  'company.whyBusinessIdIsLocked': 'Business id is locked. If it has been changed, please contact support (support@zeroni.fi).',
  'company.whyCantIEdit': 'Why can\'t I edit this?',
  'company.withoutContract': 'Without a contract',
  'companyEditor.fusionInfo': 'The company has gone through a merger <strong>{0}</strong> and is currently part of company {1} ({2}).',
  'companyEditor.hasNoAddress': 'Company is missing an address',
  'companyEditor.select': 'Select',
  'companyEditor.skip': 'No, thank you',
  'companyEditor.suggestAddressMessage': 'Would you like to select one of the following?',
  'companyPersons.showTags': 'Show tags',
  'competence.cardNumber': 'Card number',
  'competence.code': 'Code',
  'competence.confirmRemoval': 'Remove competence <strong>{0}</strong> from person <strong>{1}</strong>?',
  'competence.correctInformation': 'No, I\'m correcting old information',
  'competence.createNew': 'Yes, I\'m adding a new card',
  'competence.duration': 'Duration',
  'competence.durationPlaceHolder': 'Expiration date',
  'competence.expired': 'Expired',
  'competence.months': 'months',
  'competence.monthsShort': 'mo',
  'competence.ongoing': 'Ongoing',
  'competence.updateOrCreateNew': 'You changed competence\'s key information. The current competence\'s changes are made here but if you have a new competence card, that will be added for a new competence. Have you obtained a new card or other certificate for the competence?',
  'competence.validUntil': 'Valid until',
  'competences': 'Competences',
  'connectionError': 'Connection error',
  'contactInfo.addressIsUsedMultipleTimesWarning': 'This address is also being used for {0} other persons. When the address is verified, the others won\'t be able to use it.',
  'contactInfo.confirmEmail': 'Address is correct',
  'contactInfo.confirmInfo': 'Confirm contact information',
  'contactInfo.confirmPhone': 'Number is correct',
  'contactInfo.editEmail': 'Edit email',
  'contactInfo.editorModal': 'Contact information editor dialog',
  'contactInfo.editPhone': 'Edit phone number',
  'contactInfo.emailVerified': 'Email verified',
  'contactInfo.enterCodeFromYourEmail': 'Enter the code that was sent to your email',
  'contactInfo.enterCodeFromYourPhone': 'Enter the code that was sent to you as an SMS',
  'contactInfo.helloSuperuser': 'Hey, you, super user!',
  'contactInfo.isAddressCorrect': 'Is this your address?',
  'contactInfo.isAddressStillCorrect': 'Is this still your address?',
  'contactInfo.isNumberCorrect': 'Is this your number?',
  'contactInfo.isNumberStillCorrect': 'Is this still your number?',
  'contactInfo.iWillTakeCareOfThisLater': 'I will take care of this later',
  'contactInfo.notVerified': 'Not verified',
  'contactInfo.numberIsUsedMultipleTimesWarning': 'This number is also being used for {0} other persons. When the number is verified, the others won\'t be able to use it.',
  'contactInfo.phoneVerified': 'Phone verified',
  'contactInfo.superUserShouldKnowAboutEditingVerifiedData': 'Editing a verified contact information will remove the verification and the user has to verify the new data.',
  'contactInfo.superUserShouldKnowAboutLoggingIn': 'Contact info is being used to login, and providing false contact information may prevent the user from logging in.',
  'contactInfo.verificationModal': 'Contact information verification dialog',
  'contactInfo.verified': 'Verified',
  'contactInfo.verifyEmail': 'Verify email',
  'contactInfo.verifyPhone': 'Verify phone number',
  'contactInfo.wrongCodeTryAgain': 'Wrong code. Try again.',
  'contactInfoStatusBadge.verified': 'Verified',
  'continueToPersons': 'Continue to persons tab',
  'contract.accessAreas.specialAreasInfo': 'This contract contains access areas that are not inherited from the parent contract. Areas can only be changed by the owner of the site.',
  'contract.activeMainContractor': 'Active main contractor',
  'contract.addChosenPerson': 'Add access permit for selected person',
  'contract.addChosenPersons': 'Add access permits for selected {0} persons',
  'contract.addHotWorkPermit': 'Add hot work permit',
  'contract.addJobTitles': 'Add scope',
  'contract.addNthContact': 'Add {0}. contact person',
  'contract.addPermitAndCheckIns': 'Add access permit and check ins related to this contract.',
  'contract.addPersons.aboutToLeave': 'You\'re about to leave and cancel adding persons.',
  'contract.addPersons.add.employees': 'Employees',
  'contract.addPersons.add.visitors': 'Visitors',
  'contract.addPersons.add': 'Add',
  'contract.addPersons.additionalDetails': 'Additional details',
  'contract.addPersons.andNOthers': 'and <strong>{0}</strong> others.',
  'contract.addPersons.approvalInfo': '<strong>The site requires a separate permit approval.</strong> An email will be sent to the contract\'s contact after the approval.',
  'contract.addPersons.areYouSureYouWantToDeselectPerson': 'Are you sure you want to deselect <b>{0}</b>?',
  'contract.addPersons.areYouSureYouWantToLeave': 'Are you sure you want to leave?',
  'contract.addPersons.checkAndSubmit.abbr': 'Subm.',
  'contract.addPersons.checkAndSubmit': 'Check and submit',
  'contract.addPersons.deselectPerson': 'Deselect person',
  'contract.addPersons.deselectPersonQuestion': 'Deselect person?',
  'contract.addPersons.differentEmployerOnValttiTag': 'Different employer on Valtti tag.',
  'contract.addPersons.differentEmployerOnValttiTagAlert': 'You\'re about to add an employee for <strong>{0}</strong>, but this person\'s Valtti tag is assigned to <strong>{1}</strong>. Have you chosen the right contract and employee?',
  'contract.addPersons.differentEmployerOnValttiTagAlertSameName': 'You\'re about to add an employee for <strong>{0}</strong> (business ID {1}), but this person\'s Valtti tag is assigned to <strong>{2}</strong> (business ID {3}). Have you chosen the right contract and employee?',
  'contract.addPersons.employment': 'Employment',
  'contract.addPersons.existingOrientation': 'Person has received orientation for this site on {0}.',
  'contract.addPersons.foreignerDetails': 'Foreigner details',
  'contract.addPersons.foreignerRep.abbr': 'Repr.',
  'contract.addPersons.foreignerRepShort': 'Representative in Finland',
  'contract.addPersons.foremanIntro': '<p>No foreman selected for the contract <b>{0}</b>. Foreman is crucial for ensuring safety and efficiency in work.</p><p>Typical responsibilies for a foreman:</p><ul><li>Receiving and distributing information related to the operation and safety of the construction site.</li><li>Acting as the responsible person for correcting safety deviations.</li></ul>',
  'contract.addPersons.foremanNotAmongSelected': 'Foreman is not among the selected persons.',
  'contract.addPersons.foremanSelectionUpdatesAllContracts': 'The foreman selection will be updated for all selected contracts.',
  'contract.addPersons.ids': 'Certificates of identity',
  'contract.addPersons.invalid': 'Invalid data',
  'contract.addPersons.leaveQuestion': 'Leave now?',
  'contract.addPersons.leavingDiscardsAllChanges': 'If you leave before submitting the data, you will lose all selections and input you have made.',
  'contract.addPersons.manyPersonsWithMissingCompetences': '<strong>{0} persons</strong> do not have all the required competences. An access permit will be saved for each person, but it will become valid when the person has the required competences.',
  'contract.addPersons.modal.title': 'Persons cannot be added to contract',
  'contract.addPersons.multiplePersons': 'Multiple persons',
  'contract.addPersons.nextPerson': 'Next person',
  'contract.addPersons.nextStep': 'Next step',
  'contract.addPersons.noSelections': 'No persons selected.',
  'contract.addPersons.onePerson': '1 person',
  'contract.addPersons.orientationCopyNotPossible': 'Orientation can\'t be copied because the competences are not up to date.',
  'contract.addPersons.orientationStillValid': 'Orientation is still valid',
  'contract.addPersons.overlappingAccessPermits': 'Overlapping access permits',
  'contract.addPersons.prohibited': 'Person cannot be added',
  'contract.addPersons.relationAndValidationPeriod': 'Relation and validation period',
  'contract.addPersons.requiresNewOrientation': 'New required',
  'contract.addPersons.resultPerson': '<b>1</b> person selected',
  'contract.addPersons.resultPersons': '<b>{0}</b> persons selected',
  'contract.addPersons.selectAtOnce': 'Select at once',
  'contract.addPersons.selectedPersons': 'Selected persons',
  'contract.addPersons.selectFirstPersons': 'Select first persons',
  'contract.addPersons.selectMorePersons': 'Select more persons',
  'contract.addPersons.selectNPersons': 'Select {0} persons',
  'contract.addPersons.selectNPersonsShort': 'Select {0}',
  'contract.addPersons.selectPerson': 'Select person',
  'contract.addPersons.selectPersons': 'Select persons',
  'contract.addPersons.selectPersonShort': 'Select',
  'contract.addPersons.sharedDetails.abbr': 'Com.',
  'contract.addPersons.sharedDetails.short': 'Common for all',
  'contract.addPersons.sharedDetails': 'Common for all selected persons',
  'contract.addPersons.showOrientationForm': 'Show orientation form (PDF)',
  'contract.addPersons.singlePersonWithMissingCompetences': '<strong>{0}</strong> does not have all the required competences. An access permit will be saved, but it will become valid when the person has the required competences.',
  'contract.addPersons.theseAccessPermitsMayAffectApproving': 'These access permits may affect the approval of this access permit.',
  'contract.addPersons.tooltip.notAllowedToAddPersons.and': ' and ',
  'contract.addPersons.tooltip.notAllowedToAddPersons.bSideInvalid': 'the company basic information has been filled',
  'contract.addPersons.tooltip.notAllowedToAddPersons.liabilityDocs': 'the documents for contractor\'s obligations are in order',
  'contract.addPersons.tooltip.notAllowedToAddPersons': 'You can add employees to the contract only after ',
  'contract.addPersons.useUniqueDetails': 'Set separately',
  'contract.addPersons.valid': 'Valid',
  'contract.addPersons.valttiEmployerAlertDismiss': 'Yes, continue adding the employee',
  'contract.addPersons.valttiEmployerAlertRemove': 'No, remove the person',
  'contract.addPersons.yesLeave': 'Yes, leave',
  'contract.addPersons': 'Add persons',
  'contract.addPersonSearchPlaceholder': 'Search person (by name, tax number or Valtti)',
  'contract.addPersonsHelp.orAddNew': 'add a new person',
  'contract.addPersonsHelp': '<strong>Select one or more persons.</strong> Select from existing persons below or',
  'contract.addPersonsStrong': 'Add <strong>employees</strong>',
  'contract.addPersonsToContract': 'Add persons to the contract',
  'contract.addSubContract': 'Add subcontract',
  'contract.alreadyExists': 'Contract already exists',
  'contract.alreadyExistsAddedBySomebody': 'Contract for contractor <strong>{0}</strong> has been already added by somebody.',
  'contract.approver': 'Approver',
  'contract.askAboutPermitEndDateChange': 'Do you also want to change the end date of access permits of this contract and its subcontracts? This option updates all access permits <strong>whose end date is {0}. The new end date is {1}.',
  'contract.askAboutPermitStartDateChange': 'Do you also want to change the start date of access permits of this contract and its subcontracts? This option updates all access permits <strong>whose start date is {0} and which are not orieted. The new start date is {1}.',
  'contract.askAboutSubcontractEndDateChange': 'Do you want to change the end date of this contract\'s subcontracts? This option updates all contracts <strong>whose end date is {0}</strong>. The new end date is {1}.',
  'contract.askAboutSubcontractStartDateChange': 'Do you want to change the start date of this contract\'s subcontracts? This option updates all contracts <strong>whose starting date is {0}</strong>. The new start date is {1}.',
  'contract.automaticPeriodChange': 'The start date and end date of access permits and subcontracts are changed automatically so that they are within the period {0} - {1}.',
  'contract.automaticPeriodChangeContractsOnly': 'The start date and end date of subcontracts are changed automatically so that they are within the period {0} - {1}.',
  'contract.bSideInvalid.addingEmployeesPrevented': 'You can add employees to the contract only after the information has been filled.',
  'contract.bSideInvalid': 'The company information is missing.',
  'contract.buyer': 'Buyer',
  'contract.cantMoveHeading': 'Contract can\'t be moved',
  'contract.cantMoveInfo': 'There are approved invoice hours on the contract. Therefore the contract can\t be moved. If you still need to move the contract, please contact Zeroni support (support@zeroni.fi).',
  'contract.cantRemoveHeading': 'Contract can\'t be removed',
  'contract.cantRemoveInfo': 'There are approved invoice hours on the contract. Therefore the contract can\t be removed. If you still need to remove the contract, please contact Zeroni support (support@zeroni.fi).',
  'contract.chain': 'Chain',
  'contract.chooseFromListButEmpty': 'Job titles must be selected from the list, but job title list is empty.',
  'contract.chooseNewParent': 'Please select the contract under which the contract <strong>{0}</strong> will be moved:',
  'contract.companyPendingCertificates': 'Needs certificates',
  'contract.confirmSafetyLevel': 'Confirm safety level',
  'contract.contacts': 'Contacts',
  'contract.continuing': 'Continuing...',
  'contract.contractAll': 'Contract all',
  'contract.contractContacts': 'Contractor contacts',
  'contract.contractEditorNotifications': 'Notifications',
  'contract.contractHasNoForemanInfo0': 'The contract has no primary foreman.',
  'contract.contractHasNoForemanInfo1': 'You can select the foreman on the persons page or ',
  'contract.contractHasNoForemanInfo2': 'you can choose not to use foreman on the contract.',
  'contract.contractHasNoForemanInfoFull.noContractModifyPermission': 'The contract has no primary foreman. You can select the foreman on the persons page.',
  'contract.contractHasNoForemanInfoFull': 'The contract has no primary foreman. You can select the foreman on the persons page or you can choose not to use foreman on the contract.',
  'contract.contractMissing': 'Contract missing?',
  'contract.contractValidityPeriod': 'Contract duration',
  'contract.developerAccessWarning': 'The developer contact persons will get admin rights and can edit the site like the main contractor.',
  'contract.document.location.elsewhere': 'Saved in a separate system',
  'contract.documents.accidentInsuranceLegend.EE': 'A1-/E101 document given by <em>Sotsiaalkindlustusamet</em> office or proof of accident insurance given by a finnish insurance company which has insured contract partners employees',
  'contract.documents.accidentInsuranceLegend.generic': 'Proof of accident insurance given by a finnish insurance company which has insured contract partners employees.',
  'contract.documents.accidentInsuranceLegend.LT': 'A1-/E101-document given by <em>Valstybinio socialinio draudimo fondo valdybos</em> office or proof of accident insurance given by a finnish insurance company which has insured contract partners employees',
  'contract.documents.accidentInsuranceLegend.LV': 'A1-/E101-document given by <em>Valsts sociālās apdrošināšanas aģentūra (VSAA)</em> office or proof of accident insurance given by a finnish insurance company which has insured contract partners employees',
  'contract.documents.accidentInsuranceLegend.NO': 'A1-/E101-document given by <em>Norges arbeids- og velferdsetaten (NAV)</em> office or proof of accident insurance given by a finnish insurance company which has insured contract partners employees',
  'contract.documents.accidentInsuranceLegend.PL': 'A1-/E101-document given by <em>Zakładu Ubezpieczeń Społecznych (ZUS)</em> office or proof of accident insurance given by a finnish insurance company which has insured contract partners employees',
  'contract.documents.accidentInsuranceLegend.SE': 'A1-/E101-document given by <em>Pensionsmyndigheten</em> office or proof of accident insurance given by a finnish insurance company which has insured contract partners employees',
  'contract.documents.approvedDocuments': 'Latest approved documents',
  'contract.documents.archivedDocuments': 'Archived documents',
  'contract.documents.areContractorLiabilityDocumentsRequired': 'Is contract partner exempted from delivering contractor liability documents?',
  'contract.documents.confirmExemption': 'Confirm exemption',
  'contract.documents.confirmExemptionInfo': 'Confirm exemption as noted on the Luotettava Kumppani -report above.',
  'contract.documents.decider': 'Documents checked by',
  'contract.documents.documentsExpirationDate': 'Expiration date',
  'contract.documents.employerRegisterLegend.NO': 'Registration document given by <em>Skatteetaten</em> office',
  'contract.documents.employerRegisterLegend.SE': 'A registration document given by <em>Skatteverket</em> office for being part of arbetsgivarregister',
  'contract.documents.exemptPending': 'Developer or main contractor must confirm exemption.',
  'contract.documents.expirationInPast': 'Please note that the expiration date is in the past.',
  'contract.documents.expirationToday': 'Please note that the expiration date is already today.',
  'contract.documents.genericHelp': 'Read more info from Occupational Safety and Health Administration website',
  'contract.documents.hideHelp': 'Hide required documents',
  'contract.documents.inExternalSystem': 'Documents have been saved to an external system.',
  'contract.documents.info': 'Approved documents are moved to Latest approved documents section. If new documents are approved to replace old expired documents, the old documents are moved to Archived documents section and new documents to Latest approved documents section. Approved documents cannot be deleted.',
  'contract.documents.intro.EE': '<strong>Your companys homecountry is Estonia</strong> The following documents are required from Estonian companies.',
  'contract.documents.intro.FI': '<strong>Your companys homecountry is Finland</strong> The following documents are required from Finnish companies.',
  'contract.documents.intro.LT': '<strong>Your companys homecountry is Lithuania</strong> The following documents are required from Lithuanian companies.',
  'contract.documents.intro.LV': '<strong>Your companys homeountry is Latvia</strong> The following documents are required from Latvian companies.',
  'contract.documents.intro.NO': '<strong>Your companys homecountry is Norway</strong> The following documents are required from Norwegian companies.',
  'contract.documents.intro.PL': '<strong>Your companys homecountry is Poland</strong> The following documents are required from Polish companies.',
  'contract.documents.intro.RU': '<strong>Your companys homecountry is Russia</strong> The following documents are required from Russian companies.',
  'contract.documents.intro.SE': '<strong>Your companys homecountry is Sweden</strong> The following documents are required from Swedish companies.',
  'contract.documents.intro.UDEFINED': '<strong>Companys homecountry has not been specified</strong> The following documents are required from <em>Finnish</em>.',
  'contract.documents.intro.UNDEFINED': '<strong>Contractor doesn’t have an address.</strong> For <em>a finnish</em> contractor the following documents are required.',
  'contract.documents.location': 'Documents\' location',
  'contract.documents.noEmployerRegister': 'Not required (no employer register)',
  'contract.documents.noFiles': 'No documents',
  'contract.documents.pending': 'Documents are pending approval.',
  'contract.documents.pensionInsuranceLegend.EE': 'A1-/E101 document given by <em>Sotsiaalkindlustusamet</em> office or a document given by finnish employment pension office which has insured contract partners employees',
  'contract.documents.pensionInsuranceLegend.LT': 'Registration document given by <em>Valstybinio socialinio draudimo fondo valdybos</em> office or a document given by finnish employment pension office which has insured contract partners employees',
  'contract.documents.pensionInsuranceLegend.LV': 'A1-/E101 document given by <em>Valsts sociālās apdrošināšanas aģentūra (VSAA)</em> office or a document given by finnish employment pension office which has insured contract partners employees.',
  'contract.documents.pensionInsuranceLegend.NO': 'A1-/E101 document given by <em>Norges arbeids- og velferdsetaten (NAV)</em> office or a document given by finnish employment pension office which has insured contract partners employees',
  'contract.documents.pensionInsuranceLegend.PL': 'A1-/E101 document given by <em>Zakładu Ubezpieczeń Społecznych (ZUS)</em> office or a document given by finnish employment pension office which has insured contract partners employees.',
  'contract.documents.pensionInsuranceLegend.RU': 'TYÖKOMENNUS KESTÄÄ ENINTÄÄN 2 VUOTTA:<br>enintään 2 vuotta kestävän <br>työkomennuksen osalta <br>todistus Venäjän <br>työeläkejärjestelmän <br>mukaisesta vakuutuksesta ja <br>eläkevakuutusmaksujen <br>maksamisesta. <br>TYÖKOMENNUS KESTÄÄ <br>YLI 2 VUOTTA:<br>2 vuotta ylittävän <br>komennuksen ajalta selvitys <br>Eläketurvakeskuksen <br>päätöksestä, jolla on myönnetty <br>vapautus työntekijän eläkelaimukaisesta <br>vakuuttamisvelvollisuudesta ja <br>todistus Venäjän <br>työeläkejärjestelmän <br>mukaisesta vakuutuksesta ja <br>eläkevakuutusmaksujen <br>maksamisesta <br><u>tai <br>sopimuskumppanin työntekijät <br>vakuuttaneen suomalaisen <br>työeläkelaitoksen antama <br>todistus</u>.<br>(Huom. Jos työntekijä <br>työkomennuksen alkaessa <br>kuuluu Suomen sosiaaliturvan <br>piiriin taikka häneen <br>sovelletaan EU:n sosiaaliturvan <br>perusasetusta, tulee esittää <br>suomalaisen työeläkelaitoksen <br>antama todistus taikka A1-/E101-todistus.)',
  'contract.documents.pensionInsuranceLegend.SE': 'A1-/E101 document given by <em>Pensionsmyndigheten</em> office or a document given by finnish employment pension office which has insured contract partners employees.',
  'contract.documents.prepaymentLegend.EE': 'Registration document given by <em>Maksu- ja Tolliamet</em> office about being listed in register of persons liable for payment',
  'contract.documents.prepaymentLegend.LT': 'Registration document given by <em>Valstybinė mokesčių inspekcija prie Lietuvos Respublikos finansų ministerijos (VMI)</em> office',
  'contract.documents.prepaymentLegend.LV': 'Registration document given by <em>Valsts ieņēmumu dienests (VID)</em> office',
  'contract.documents.prepaymentLegend.NO': 'Registration document given by <em>Skatteetaten</em> office',
  'contract.documents.prepaymentLegend.PL': 'Registration document given by <em>Urzad Skarbowy (US)</em> office (NIP-code)',
  'contract.documents.prepaymentLegend.RU': 'Registration document given by <em>Федеральная налоговая служба (transl. Federalnaja nalogovaja sluzhba)</em> office about being listed in tax office',
  'contract.documents.prepaymentLegend.SE': 'Registration document given by <em>Skatteverket</em> office',
  'contract.documents.privateDocs': 'Private documents',
  'contract.documents.privateDocsInfo': 'Private documents are visible only to users of company {0} whose user role includes the permission to see private documents.',
  'contract.documents.privateDocsInfoWithLink': 'Private documents are visible only to users of company {0} whose <a href="#!/manage/usergroups" target="_blank">user role</a> includes the permission to see private documents.',
  'contract.documents.publicDocsInfo': 'The documents are available to the contractor and to all companies that are above the contractor in the contract tree.',
  'contract.documents.removeSelection': 'Remove selection',
  'contract.documents.requiredDocuments': 'Required documents',
  'contract.documents.showHelp': 'View required documents',
  'contract.documents.taxPaymentsLegend.EE': 'Registration document given by <em>Maksu- ja Tolliamet</em> office',
  'contract.documents.taxPaymentsLegend.LT': 'Registration document given by <em>Valstybinė mokesčių inspekcija prie Lietuvos Respublikos finansų ministerijos (VMI)</em> office',
  'contract.documents.taxPaymentsLegend.LV': 'Registration document given by <em>Valsts ieņēmumu dienests (VID)</em> office',
  'contract.documents.taxPaymentsLegend.NO': 'Registration document given by <em>Skatteetaten</em> office',
  'contract.documents.taxPaymentsLegend.PL': 'Registration document provided by <em>Urzad Skarbowy (US)</em> office (form ZAS-W)',
  'contract.documents.taxPaymentsLegend.RU': 'Registration document given by <em>Федеральная налоговая служба (transl. Federalnaja nalogovaja sluzhba)</em> or by <em>Межрайонная ИФНС России (transl. Mezhraionnaja IFNS Rossii)</em> office',
  'contract.documents.taxPaymentsLegend.SE': 'Registration document given by <em>Skatteverket</em> office',
  'contract.documents.tesLegend.generic': 'Legend given by contract partner about collective labor agreement or other labor agreements',
  'contract.documents.tradeRegisterLegend.EE': 'A registration document given by <em>Registrite ja Infosüsteemide Keskus (RIK)</em> office for being listed in business register (äriregister)',
  'contract.documents.tradeRegisterLegend.LT': 'Registration document given by <em>Juridinių asmenu registras </em> office for being listed in <em>registravimo pazymėjimas</em>',
  'contract.documents.tradeRegisterLegend.LV': 'A registration document given by <em>Latvijas Republikas uzņēmumu registrs</em>office for being listed in business register',
  'contract.documents.tradeRegisterLegend.NO': 'A registration document given by <em>Enhetsregisteret</em> office for being listed in business register (foretaksregisteret)',
  'contract.documents.tradeRegisterLegend.PL': 'Companies owned by polish: Registration document given by <em>Główny Urząd Statystyczny (GUS)</em> office, which contains REGON-code. (The same registration document is valid for companies owned by the polish or by foreigners.) Companies owned by foreigners: Registration document provided by <em>Krajowy Rejestr Sadowy (KRS)</em> office.',
  'contract.documents.tradeRegisterLegend.RU': 'A registration document given by <em>Федеральная налоговая служба (transl. Federalnaja nalogovaja sluzhba)</em> office for legal persons governmental registration (свидетельство о государственной регистрации юридических лиц)',
  'contract.documents.tradeRegisterLegend.SE': 'A registration document given by <em>bolgasverkett</em> office for being listed in Bolagsregister',
  'contract.documents.vatObligationLegend.EE': 'Registration document given by <em>Maksu- ja Tolliamet</em> office',
  'contract.documents.vatObligationLegend.LT': 'Registration document (<em>pridėtinės vertės mokesčio mokėtojo registracijos pažymėjimas</em>) given by <em>Valstybinė mokesčių inspekcija prie Lietuvos Respublikos finansų ministerijos (VMI)</em> office',
  'contract.documents.vatObligationLegend.LV': 'Registration document given by <em>Valsts ieņēmumu dienests (VID)</em> office',
  'contract.documents.vatObligationLegend.NO': 'Registration document given by <em>Skatteetaten</em> office',
  'contract.documents.vatObligationLegend.PL': 'Registration document given by <em>Urzad Skarbowy (US)</em> office, which contains Numer Identifikacji Podatkowej (NIP-code)',
  'contract.documents.vatObligationLegend.RU': 'Same as section 1. document',
  'contract.documents.vatObligationLegend.SE': 'Registration document given by <em>Skatteverket</em> office',
  'contract.doNotSendContractTaxReports': '<strong>Do not</strong> send reports on contracts',
  'contract.doNotSendEitherTaxReports': '<strong>Do not</strong> send reports on contracts nor employees',
  'contract.doNotSendEmployeeTaxReports': '<strong>Do not</strong> send reports on employees',
  'contract.duplicateUserEmails': '<strong>Persons\' email addresses must be unique!</strong> <br>Each person must have a personal email address. The same address cannot be used by more than one person.',
  'contract.editContract': 'Edit contract',
  'contract.email': 'E-mail',
  'contract.emails': 'E-mails',
  'contract.evaluations': 'Evaluations',
  'contract.expandAll': 'Expand all',
  'contract.expanding': 'Expanding...',
  'contract.expertiseRequirement.all': 'All competences and courses',
  'contract.expertiseRequirement.custom': 'Only courses',
  'contract.expertiseRequirement.none': 'No requirements',
  'contract.extend': 'Extend',
  'contract.extendingEndDate': 'You are changing the end date to a later date.',
  'contract.extendingStartDate': 'You are changing the contract\'s starting date to an earlier date.',
  'contract.extendNone': 'Don\'t extend',
  'contract.extendOnlyThis': 'Only for this contract',
  'contract.extendPermits': 'You are extending the duration of the contract. Also extend the duration of the access permits?',
  'contract.extendThisAndBelow': 'Also for subcontracts',
  'contract.forceManagedInfo': 'Site management rights will be transfered to the company {0} because of the new main contract.',
  'contract.getCurrentJobTitleList': 'Get current list of work labels',
  'contract.getCurrentMaterialsList': 'Get current list of materials',
  'contract.getCurrentScopeList': 'Get current list of scopes',
  'contract.hasBeenRejected': 'Contract has been rejected',
  'contract.hasMergedTo': 'Has merged to',
  'contract.hideSubcontracts': 'Hide subcontracts',
  'contract.highlight': 'Highlight',
  'contract.informContractorPresence': 'Send an email alert when contractor enters the site',
  'contract.insurerInfo': 'You have to select the insurer for hot work permits before you can create them. The insurer is selected from the site settings.',
  'contract.insurerMissing': 'Insurer missing',
  'contract.interrupting': 'Interrupting...',
  'contract.invoiceHourApprover': 'Invoice hours approver',
  'contract.invoiceHourReporterInfo': 'Invoice hours are reported by {{0}} and approved by {{1}}.',
  'contract.invoiceHours': 'Invoice hours',
  'contract.invoiceHoursNotUsed': 'Invoice hours not used.',
  'contract.invoiceHoursRole': 'Invoice hours role',
  'contract.invoiceHoursUsed': 'Invoice hours used',
  'contract.jobTitles': 'Scope',
  'contract.jobTitlesAndMaterials': 'Scope',
  'contract.logbooks.goToLogbook': 'Go to daily reports',
  'contract.logbooks.useLogbook': 'Start using daily reports',
  'contract.mainContractDescription': 'A <strong>maincontract</strong> construction usually includes foundation, body, and interior contruction engineering.',
  'contract.managed': 'Access rights',
  'contract.managed.deny': 'No access rights',
  'contract.managed.deny.info': 'Personnel under this contract are added to the site by a higher entity in the contract chain.',
  'contract.managed.grant': 'Grant access rights',
  'contract.managed.grant.info': 'The company can add its own personnel to the site. Access rights for this contract are granted to the contact persons listed here.',
  'contract.managed.label': 'Access to own contract',
  'contract.moveAsSideContract': 'Turn to side-contract',
  'contract.myContract': 'Own contract',
  'contract.newCompanyHelp': '<strong>New company is added via contract.</strong> The new company is available in the company registry as soon as it is a promisor of any contract.',
  'contract.newPerson': 'New person',
  'contract.noContractDocumentsModal': 'No documents to display',
  'contract.noContractDocumentsModalMessage': 'No contracts were found whose documents could be displayed. Ensure that you have permissions to contracts that have documents.',
  'contract.noContractTaxReportsBelowThreshold': 'As per request from the Tax Administration no reports on contracts will be sent if the estimated value is below 15 000 €.',
  'contract.noEvaluations': 'No evaluations',
  'contract.noForemanPossibleForUsersWithModifyPermission': 'It is possible to set contract as not needing a foreman. However, this operation needs a permission to modify the contract, and your permissions do not include it.',
  'contract.noForemanRequired': 'Foreman is not required',
  'contract.noJobTitlesAvailable': 'No scope available',
  'contract.noMatchingResults': 'No matching results.',
  'contract.noMaterialsAvailable': 'No materials available',
  'contract.noPersons': 'Contract doesn\'t have any persons yet.',
  'contract.noReportsToTaxAdmin': 'No reports to tax admin',
  'contract.noReportToTaxAdmin': 'No tax reporting',
  'contract.notificationOfPostingOfWorkers': 'Notification of posting of workers',
  'contract.notificationOfPostingOfWorkersDate': 'Notification date',
  'contract.notificationOfPostingOfWorkersInfoMessage': 'The obligation to notify enters into force on 1st of September 2017. <a href="https://anon.ahtp.fi/_layouts/Lomake.ashx?LomakeID=30103" target="_blank" rel="noopener">Info (tyosuojelu.fi) <span class="far fa-external-link-alt"></span></a>',
  'contract.notUsed': 'Not used',
  'contract.onlyActive': 'Active only',
  'contract.onlyOwn': 'Own only',
  'contract.openSettings': 'Open settings',
  'contract.permitsNeedContracts': 'Extending permits requires extending subcontracts.',
  'contract.persons': 'Persons',
  'contract.personsInContract': 'Persons<br><small>in contract{0}</small>',
  'contract.reasonForRejection': 'Reason for decline',
  'contract.rejected': 'Rejected',
  'contract.rejectingParty': 'Rejecting party',
  'contract.rejection': 'Rejection',
  'contract.removeThisContact': 'Remove this contact person',
  'contract.reporter': 'Reporter',
  'contract.requestApproval': 'Does <strong>{0}</strong> accept the new contract for contractor <strong>{1}</strong> ({2})?',
  'contract.requiredCertificates': 'Required certificates',
  'contract.safetyLevel': 'Safety level',
  'contract.safetyLevelForContract': 'Safety level for contract <strong>{0}</strong> ({1})',
  'contract.scope': 'Scope',
  'contract.search': 'Contract search',
  'contract.searchOrSelectContractContactPlaceholder': 'Contact person',
  'contract.searchOrSelectJobTitlePlaceholder': 'Scope (e.g. excavation, electrics, demolition)',
  'contract.searchSelectOrAddJobTitlePlaceholder': 'Scope (e.g. excavation, electrics, demolition)',
  'contract.selectContact': 'Select a contact person',
  'contract.selectContractForNewPersons': 'Select a contract for the employees.',
  'contract.sendBothTaxReports': '<strong>Send</strong> reports on contracts and employees',
  'contract.sendContractTaxReports': '<strong>Send</strong> reports on contracts',
  'contract.sendEmployeeTaxReports': '<strong>Send</strong> reports on employees',
  'contract.showOnlyActive': 'Show only active',
  'contract.showOnlyOwn': 'Show only own',
  'contract.showRemainingSubcontracts': 'Show remaining {0} subcontracts',
  'contract.showSubcontracts': 'Show subcontracts ({0})',
  'contract.showSuggestionsForContact': 'Show suggestions for contact',
  'contract.showSuggestionsForSecurityContact': 'Show suggestions for security contacts',
  'contract.sideContractDescription': '<strong>side-contract</strong> is a contract which is not part of the maincontract, ordered by the developer.',
  'contract.siteCompetences': 'Required competences',
  'contract.siteCompetencesRequired': 'Required',
  'contract.statusOnCreation': 'Status when contract was created',
  'contract.subContractDescription': '<strong>Subcontract</strong> is a entity of its own defined by contractor, which is carried out by a subcontractor selected by the contractor.',
  'contract.suggestSafetyLevel': 'Suggest safety level',
  'contract.suspended': 'Suspended',
  'contract.taxReportsOnSiteNeither': 'Site settings: neither reports on contracts nor employees will be sent',
  'contract.taxReportsOnSiteNoContracts': 'Site settings: reports on contracts will not be sent.',
  'contract.taxReportsOnSiteNoEmployees': 'Site settings: reports on employees will not be sent.',
  'contract.toBeMoved': 'Contract to be moved',
  'contract.valtticardNotValid': 'It is not possible to create an access permit because the Valtti card is not valid.',
  'contractDocuments.furtherInfoCLReportUpdate': 'Info for contractor liability report\'s update',
  'contractEditor.contractExistsForB': 'This site already has a contract for this contractor:',
  'contractEditor.emailDomainEqualsToOwn': '<strong>Enter contact information for your contractor:</strong> Given email address is similar to your own. Please make sure that this person is contractor\'s contact person.',
  'contractEditor.furtherInfoOfManualCLupdate': 'Further info related updating contractor liability status',
  'contractEditor.iWantToAddMultipleContractsForThisCompany': 'I want to add multiple contracts for this company',
  'contractEditor.newMainContract': 'New main contract',
  'contractEditor.newSideContract': 'New side contract',
  'contractEditor.parentContract': 'Parent contract',
  'contractEditor.parentContractEnds': 'End date for parent contract <strong>{0}</strong>: <strong>{1}</strong>.',
  'contractEditor.showLiabilityDocs': 'Show documents for contractor\'s obligations',
  'contractEditor.updateContractAndCLReport': 'Update contract\'s status and documents for contractor\'s obligations',
  'contractEditor.updateContractorLiabilityDocuments': 'Update contractor liability documents',
  'contractEditor.useHelperDuration': 'Set this contract to align with parent contract',
  'contractEditor.useHelperDuration2': 'Set this contract\'s start date to equal the check in\'s date and end date to align with parent contract',
  'contractEditor.useHelperDuration3': 'The start date of the contract has to be before the merge date <strong>{0}</strong>. If the date should be later, please select {1} ({2}) as the contract partner.',
  'contractorObligationGetCompanyInfo': 'Results given by Vastuu Group.',
  'contractorSignature': 'Contractor\'s signature',
  'country.eu': 'EU',
  'country.notEu': 'Not EU',
  'course.fromExternalSystem': 'From external system',
  'course.noManualCreation': 'This training is added to persons in an external system, so you cannot add it in Zeroni.',
  'dailyReport.buyer': 'Buyer',
  'dailyReport.buyerRep': 'Buyer representative',
  'dailyReport.byCheckIns': 'By check-ins',
  'dailyReport.byPreviousDay': 'By previous day',
  'dailyReport.changeMonth': 'Change month',
  'dailyReport.contractorRep': 'Contractor representative',
  'dailyReport.copyValues': 'Use',
  'dailyReport.day': 'Day',
  'dailyReport.dayWeather': 'Weather during the day',
  'dailyReport.documents': 'Documents',
  'dailyReport.employeeUnitShort': 'Person(s)',
  'dailyReport.extinguishingEquipments': 'Extinguishing equipment and supervising hot work',
  'dailyReport.firstEnterRequiredInfo': 'At first fill in required information',
  'dailyReport.jobTitle': 'Job title',
  'dailyReport.logbook.dailyReportsAvailableAfterSiteBegins': 'Daily reports are available after the site has started {0}',
  'dailyReport.logbook.noPagesToPrint': 'No pages to print.',
  'dailyReport.logbook.printAllDays': 'Print all days',
  'dailyReport.logbook.printingLogbookPleaseWait': 'Preparing logbook for printing. This may take a few minutes. Please wait...',
  'dailyReport.logbook': 'Logbook',
  'dailyReport.logbooks.changeLogbook': 'Change logbook',
  'dailyReport.logbooks.name': 'Logbook name',
  'dailyReport.logbooks.new': 'New logbook',
  'dailyReport.logbooks.open': 'Open',
  'dailyReport.logbooks.printLogbook': 'Print',
  'dailyReport.logbooks.printLogbookWeek': 'Print week',
  'dailyReport.logbooks.searchResults.many': '{0} logbooks',
  'dailyReport.logbooks.searchResults.one': 'One logbook',
  'dailyReport.logbooks.selectContract': 'Choose the contract for which you want to add a logbook.',
  'dailyReport.logbooks.selectDaysToPrint': 'Select days to print',
  'dailyReport.logbooks.siteSpecific': 'Site logbook',
  'dailyReport.logbooks': 'Logbooks',
  'dailyReport.machine': 'Machine',
  'dailyReport.machines': 'Machines',
  'dailyReport.machineUnitShort': 'pcs',
  'dailyReport.morning': 'Morning',
  'dailyReport.morningWeather': 'Morning weather',
  'dailyReport.noEmployees': 'No employees',
  'dailyReport.noPermissionsToSupervisorNotes': 'You don\'t have permission to modify your supervisor\'s notes.',
  'dailyReport.noSignedReport': 'No signed report',
  'dailyReport.noSupervisorNotes': 'Supervisor notes are missing',
  'dailyReport.openNext': 'Open next report',
  'dailyReport.openNextReport': 'Sign and open next unsigned report',
  'dailyReport.others': 'Others',
  'dailyReport.otherShort': 'Other',
  'dailyReport.resources': 'Resources',
  'dailyReport.resourcesInitialized': 'Staff is determined according to check-ins. Machines are initialized to previous day\'s levels. Check the information.',
  'dailyReport.resourcesShort': 'Res.',
  'dailyReport.sectionTitle': 'Section title',
  'dailyReport.showSuggestion': 'Suggestion',
  'dailyReport.sign': 'Sign',
  'dailyReport.signatureFromPersonWhoHasPermission': 'Signature from a person who has the right to complete a daily report',
  'dailyReport.signatureFromSupervisorNeeded': 'Signature from a person who has right to record supervisor\'s notes',
  'dailyReport.signatures': 'Signatures',
  'dailyReport.signaturesShort': 'Sign',
  'dailyReport.staff': 'Staff',
  'dailyReport.supervisor': 'Supervisor',
  'dailyReport.supervisorHelp': '<strong>If necessary, an external supervisor can be appointed to the site.</strong> For example, your organization\'s administrator can create a user who is given a supervisor role limited to this site',
  'dailyReport.supervisorShort': 'SUP',
  'dailyReport.supervisorSignature': 'SUPERVISOR\'S SIGNATURE',
  'dailyReport.supervisorsNotes': 'Supervisor\'s notes',
  'dailyReport.temperature': 'Temperature',
  'dailyReport.template': 'Daily Construction Report Template',
  'dailyReport.templateHelp': '<p><strong>The fields in the Other section can be changed.</strong></p><p>You may add or remove fields as you wish in the <strong>Daily Construction Report Template</strong>.</p>',
  'dailyReport.templates': 'Daily Construction Report Templates',
  'dailyReport.templateUpdatedNotification': 'Daily Construction Report template updated',
  'dailyReport.useThis': 'Use this',
  'dailyReport.weather.cloudy': 'cloudy',
  'dailyReport.weather.partlyCloudy': 'Partly cloudy',
  'dailyReport.weather.rain': 'Rain',
  'dailyReport.weather.sleet': 'Sleet',
  'dailyReport.weather.snow': 'Snow',
  'dailyReport.weather.strongWind': 'Strong wind',
  'dailyReport.weather.sunny': 'Sunny',
  'dailyReport.weather.thunder': 'Thunder',
  'dailyReport.weather': 'Weather',
  'dailyReport.weatherCondition': 'Weather condition',
  'dailyReport.workMonitoring': 'Work monitoring',
  'dailyReports.missingReportLegend': 'Missing diary entry',
  'dailyReports.noSafetyMeasurements': 'No safety measurements.',
  'dailyReports.printHelp': 'This day\'s page is available for printing after it has been signed.',
  'dailyReports.reportWaitingForSupervisorLegend': 'Supervisor notice or signature is missing',
  'dashboard.accessControl': 'Access control',
  'dashboard.activeCompanies': 'Active companies',
  'dashboard.activeContracts': 'active contracts',
  'dashboard.activeEmployees': 'Active employees (permit is active)',
  'dashboard.activeOwn': 'active own',
  'dashboard.activeOwnEmployees': 'Active own employees',
  'dashboard.activeOwnPartners': 'Active contract partners',
  'dashboard.activeVisitors': 'Active visitors',
  'dashboard.activeWorkers': 'Workers on site',
  'dashboard.activeWorkersAction': 'Show checked in workers',
  'dashboard.activeWorkersDescription': '{0}% of workers with permit',
  'dashboard.addSubContract': 'Add subcontract',
  'dashboard.allActiveSites': 'All active sites',
  'dashboard.allEmployeesFromStart': 'All employees',
  'dashboard.assignEmployeeToSite': 'Assign employee to site',
  'dashboard.averageActiveSitesInSixMonths': 'Average amount of sites within the last six months',
  'dashboard.averageLevel': 'Average level for {0}-measurements: <strong>{1}%</strong>',
  'dashboard.averageStrengthFromLastSixMonths': 'Average strength on all sites within the last six months',
  'dashboard.checkInDevices': 'Check in devices',
  'dashboard.companies': 'Companies',
  'dashboard.contractCompletedPayments': 'Completed payments',
  'dashboard.contractors': 'Contractors',
  'dashboard.contractorWithoutForeman': 'Contractor <strong>{0}</strong> has no appointed foreman with access permit.',
  'dashboard.contractsWithoutCheckIns': 'Following contracts have no check ins:',
  'dashboard.contractWithoutCheckIns': 'Following Contract has no check-ins:',
  'dashboard.editCompanyInfo': 'Edit company',
  'dashboard.employees': 'Employees',
  'dashboard.expired': 'Expired',
  'dashboard.expiring': 'Expiring',
  'dashboard.foreignCompanies': 'Foreign companies',
  'dashboard.gateDevices': 'Gate devices',
  'dashboard.hotWorkPermitExpired': 'Employee\'s <strong>hot work permit</strong> has expired within last three days.',
  'dashboard.hotWorkPermitsExpired': '<strong>{0}</strong> employees\' <strong>hot work permit</strong> has expired within last three days.',
  'dashboard.jumpToSite': 'Go to site',
  'dashboard.latestSafetyLevel': 'Latest {0} measurement: {1}%',
  'dashboard.loadNotifications': 'Load notifications',
  'dashboard.mainContractorSites': 'Main contracting / developing',
  'dashboard.nationalityEU': 'Other EU',
  'dashboard.nationalityFin': 'Finnish',
  'dashboard.nationalityNONEU': 'Non-EU',
  'dashboard.noFilesYet': 'No files uploaded',
  'dashboard.noForeman': 'No foreman',
  'dashboard.noMeasurements': 'No safety measurements',
  'dashboard.noNotifications': 'All is well! No notifications or warnings.',
  'dashboard.noPermissionToCheckIns': 'No permission to access check ins',
  'dashboard.noPermissionToContracts': 'No permission to access contracts',
  'dashboard.noPermissionToSafety': 'No permission to safety measurements',
  'dashboard.noSafetyMeasurementType': 'Safety measurement type is not selected for this site',
  'dashboard.noSitesSoAddNew': '<strong>No sites.</strong> The registry does not include any sites or you don\'t have the necessary permissions.',
  'dashboard.noSitesSoAddNew2': 'Add a new site',
  'dashboard.notification.noOrientationPermission': 'You don\'t have a permission to confirm orientation. Please contact main contractor.',
  'dashboard.notification.personPendingChanges': 'Person <a href="#!/sites/{0}/persons/{1}">{2}</a> did not pass verification of right to work.',
  'dashboard.notification.personPendingVerification': 'Person <a href="#!/sites/{0}/persons/{1}">{2}</a> is pending verification of right to work.',
  'dashboard.notification.personsPendingChanges': '<strong>{0} persons</strong> did not pass verification of right to work, waiting for changes.',
  'dashboard.notification.personsPendingVerification': '<strong>{0} persons</strong> are pending verification of right to work.',
  'dashboard.notification.vehiclePermitAwaitingApproval': '<strong>1</strong> vehicle permit is waiting for approval or actions.',
  'dashboard.notification.vehiclePermitsAwaitingApproval': '<strong>{0}</strong> vehicle permits are waiting for approval or actions.',
  'dashboard.notifications': 'Notifications',
  'dashboard.onlyOwnEmployees': 'Only own employees',
  'dashboard.openFiles': 'Files',
  'dashboard.openGraphs': 'Graphs',
  'dashboard.openNotifications': 'Notifications',
  'dashboard.openOverview': 'Overview',
  'dashboard.orientationAndOtherAttachments': 'Orientation and Attachments',
  'dashboard.overallLevel': 'Percentage of correct observations from all safety measurement.',
  'dashboard.overallLevelLastFourWeeks': 'Percentage of all correct observations from all safety measurement during the last four weeks.',
  'dashboard.overallLevelLastWeek': 'Percentage of all correct observations from all safety measurement during last 7 days.',
  'dashboard.overview.noReportsOfContractsSent': 'No tax reports of contract payments sent',
  'dashboard.overview.noReportsOfEmployeesSent': 'No employee reports are sent',
  'dashboard.overview': 'Overview',
  'dashboard.quickActions': 'Quick actions',
  'dashboard.residencePermitContinuationNeedsToBeChecked': '<strong>Residence permit</strong> continuation status needs to be checked.',
  'dashboard.residencePermitContinuationsNeedsToBeChecked': '<strong>{0} residence permits\' </strong> continuation status needs to be checked.',
  'dashboard.safetyMeasurementsAverage': 'Average of safety measurements',
  'dashboard.safetyMeasurementsAverageAction': 'Latest measurement ({0}%)',
  'dashboard.searchHelp': 'Search for people, contractors, sites and others',
  'dashboard.searchPlaceholder': 'Enter search terms',
  'dashboard.selectForeman': 'Select foreman',
  'dashboard.siteContractsWithoutCheckIns': 'The following contracts of site <strong>{0}</strong> have no check ins:',
  'dashboard.siteContractWithoutCheckIns': 'The contract of site <strong>{0}</strong> has no check-ins:',
  'dashboard.siteDocuments': 'General site documents',
  'dashboard.siteLinks': 'Site links',
  'dashboard.siteOrientationMaterial': 'Site orientation material',
  'dashboard.sites': 'Sites',
  'dashboard.subContractorSites': 'subcontracting sites',
  'dashboard.totalDays': '{0} number of work days (7,5h)',
  'dashboard.totalHours': 'Total amount of work hours',
  'dashboard.totalNumberOfHazards': 'Pending safety deviations',
  'deviation.photoOfDeviation': 'Photo of deviation',
  'deviation.photoOfReparation': 'Photo of repair',
  'device.basicInformation': 'Basic information',
  'device.confirmRemovalFromOtherSites': 'The device you added is already in use on another site. <strong>Do you want to remove the device from other sites?</strong>',
  'device.connectDepartmentDevices': 'Connect devices of the department {0}',
  'device.connectDepartmentDevicesInfo': 'The site belongs to the department {0} for which has been defined {1} devices. Do you want to connect the devices to the site?',
  'device.connectDevices': 'Connect devices to the site',
  'device.copyFromDevice': 'Copy from device',
  'device.deletionPermanent': '<strong>Irreversible:</strong> The device and all its information will be deleted. This deletion cannot be cancelled.',
  'device.doNotRemoveDeviceFromOtherSites': 'Do not remove device from other sites',
  'device.furtherInformation': 'Other information',
  'device.isNotOnAnySite': 'Device is not assigned to any site.',
  'device.locationOnSite': 'Location on site',
  'device.model': 'Model',
  'device.purchaseDate': 'Purchase date',
  'device.purchasePrice': 'Purchase price (€)',
  'device.removeDevice': 'Remove device',
  'device.removeFromOtherSites': 'Remove device from other sites?',
  'device.removeFromSite': 'Remove from site',
  'device.showDevices': 'Show devices',
  'device.telephone': 'Telephone',
  'deviceSearch.searchDevice': 'Search a device',
  'deviceSubscriptionEnded': 'Cellular subscription ended',
  'dhs.companyContacts': 'Company contacts <span class="text-muted">(number of companies, industry, status)</span>',
  'dhs.companyContactsEstonia': 'Company contacts in Estonia',
  'dhs.companyFinancials': '<strong>Company financials</strong>',
  'dhs.courtInfo': 'Informatin about companies and persons in charge judicature',
  'dhs.creditDisturbance': 'Bad credit history, debt collection, credit rating',
  'dhs.creditReport': 'Real time distaint information',
  'dhs.deliveryTime': 'Delivery time(workdays)',
  'dhs.dhsReport1': 'Report 1',
  'dhs.dhsReport2': 'Report 2',
  'dhs.dhsReports': 'DHS-reports',
  'dhs.employeeInfo': 'Employee information',
  'dhs.EstPersonsInChargeCompanyContactsInEst': 'Estonian persons\' in charge company contacts in Estonia',
  'dhs.financialStatementAnalysis': 'Financial statement analysis <span class="text-muted">(based on last 5 years of financial statements)</span>',
  'dhs.hideReportDetails': 'Hide report details',
  'dhs.orderDhsReport1': 'Order <strong>Report 1</strong>',
  'dhs.orderDhsReport2': 'Order <strong>Report 2</strong>',
  'dhs.orderingReport': 'Ordering...',
  'dhs.orderNew': 'Order new',
  'dhs.orderReady': 'Was ready',
  'dhs.orderReceived': 'Order received',
  'dhs.paymentDefaultEntry': 'Payment default entry <span class="text-muted">(negative credit history, ban on business operations)</span>',
  'dhs.personsInCharge': 'Persons in charge',
  'dhs.prevPersCreditInfoAndCompanyContacts': '<em>Former</em> persons in charge (now - 2 years) Payment default entries and company contacts',
  'dhs.price': 'Price(€, alv 0%)',
  'dhs.registryData': '<strong>Company\'s registry data</strong>',
  'dhs.reports': 'Reports',
  'dhs.taxes': 'VAT, advance collection, employer and tax liability registry data',
  'dhs.whatsIncludedInReports': 'View content and prices of the reports',
  'employeecheckin.areas.checkedIn': 'You checked in area {0}',
  'employeecheckin.areas.checkIn': 'Check into area',
  'employeecheckin.areas.checkOut': 'Check out from area {0}',
  'employeecheckin.loadWorkTimeReports': 'Show working hours for all sites',
  'employeecheckin.noAccessPermits': 'You have no access permits.',
  'employeecheckin.noContractorReadPermissionForCheckIns': 'Contractors have no read permission for clockings on this site.',
  'employeeMenu.accessPermits': 'Access permits',
  'employeeMenu.checkInsHistory': 'Check-ins history',
  'employeeMenu.competences': 'Competences',
  'employerChangeTool.checkingPhoneAvailability': 'Checking phone number availability...',
  'employerChangeTool.error': 'Failed to update contact information.',
  'employerChangeTool.name': 'Change contact details',
  'employerChangeTool.description':
    'The email address serves as the username for Zeroni. By changing the email address, you can create a new user or link the person to an existing user.',
  'employerChangeTool.description.action': 'Change contact details',
  'employerChangeTool.failedToCheckEmail': 'Email address check failed.',
  'employerChangeTool.failedToCheckPhone': 'Failed to verify the uniqueness of the phone number.',
  'employerChangeTool.phoneNotUniqueWarning': 'This number is used by another person. Once the number has been verified, it will no longer be accessible to others.',
  'employerChangeTool.step1description': 'Enter a new email address for {0}. Email address is used as username.',
  'employerChangeTool.notAllowedToUpdate': 'The email address you entered is not available.',
  'employerChangeTool.success': 'Successfully updated person’s contact information.',
  'employerChangeTool.phoneCantBeChanged': 'There is already a user with this email address in Zeroni. The person\'s phone number is set to the phone number of this user. <br/><br/>For security reasons, the telephone number will not be displayed in the person\'s data until this action has been performed. If you also want to change the phone number for the person, this must be done via Zeroni support.',
  'error.endDateHasToBeGreaterThanStartDate': 'End date has to greater than start date.',
  'error.endDateHasToBeTodayOrInTheFuture': 'End date has to be today or in the future.',
  'error.valttiCardBusinessIdMismatch': 'Valtti card has wrong business id.',
  'error.valttiCardCouldNotBeAdded': 'Failed to add Valtti card.',
  'error.valttiCardNotFound': 'Valtti card not found.',
  'error.valttiCardWrongPerson': 'Valtti card belongs to another person.',
  'errorHandler.emailSent': 'Our customer support has been notified. We\'ll be in touch as soon as the issue is resolved.',
  'errorHandler.hideDetails': 'Hide details',
  'errorHandler.insufficientPermissions': 'You don\'t have permission to modify this resource.',
  'errorHandler.ohNo': 'An unexpected error occurred.',
  'errorHandler.pleaseHideItAlready': 'Oh, please hide it already!',
  'errorHandler.readDetails': 'read technical details about the error',
  'errorHandler.readDetailsShort': 'Read technical details about the error',
  'errorHandler.times': 'It has now failed {0} times.',
  'errorHandler.tryAgainOr': 'You can try again or',
  'evaluation.addCriteriaLink': 'Add criteria at: Manage &rarr; General settings',
  'evaluation.addNewEvaluation': 'Add new evaluation',
  'evaluation.averageExplanation': 'Rating is the average of all active criteria. Removed criteria do not affect the rating.',
  'evaluation.category.header.existing': 'Choose existing',
  'evaluation.category.header.manual': 'Input manually',
  'evaluation.category.manual': 'Input name and number manually',
  'evaluation.confirmRemoveEvaluation.body': 'Are you sure you want to remove this evaluation?',
  'evaluation.confirmRemoveEvaluation.title': 'Remove evaluation?',
  'evaluation.doNotApply': 'Do not apply',
  'evaluation.editEvaluation': 'Edit evaluation',
  'evaluation.evaluations': 'evaluations',
  'evaluation.howAverageIsCalculated': 'How is it calculated?',
  'evaluation.interestedModal.askForQuote': 'Would you like to evaluate your subcontractors? We will contact your company.',
  'evaluation.interestedModal.heading': 'Interested in evaluation?',
  'evaluation.interestedModal.readMore': 'Read more about evaluation',
  'evaluation.interestedModal.readMoreIntro': '<b>Company evaluation</b> is a paid module.',
  'evaluation.interestedModal.yesInterested': 'Yes, I\'m interested',
  'evaluation.newEvaluation': 'New evaluation',
  'evaluation.noCriteria': 'No existing evaluation criteria.',
  'evaluation.removeCriterion.body': 'Are you sure you want to remove criterion <b>{0}</b>?',
  'evaluation.removeCriterion.heading': 'Remove criterion?',
  'evaluation.removeEvaluation': 'Remove evaluation',
  'evaluation.requestSentModal.body': 'Thank you! We will soon contact your company.',
  'evaluation.requestSentModal.heading': 'Contact request sent',
  'evaluation.settings.readMore': 'Read more',
  'evaluation.settings.readMoreIntro': 'Company evaluation is not enabled.',
  'evaluation.unknownSite': 'Unknown site',
  'evaluation': 'evaluation',
  'expertise.pendingTraining': 'Training to be completed',
  'expertise.pendingTrainings': 'Trainings to be completed',
  'expertise.removeExpertise': 'Remove expertise',
  'expertise.removeExpertiseFailed': 'Error while removing expertise.',
  'expertise.removeHeading': 'Remove expertise?',
  'expertise.testFailed': 'Test failed',
  'faultList': 'Fault list',
  'faultLists.addFirst': 'Add first fault list',
  'faultLists.addList': 'Add list',
  'faultLists.defaultContractor': 'Default contractor',
  'faultLists.defaultContractorHelp': '<strong>Default contractor</strong> is a default value for a contractor responsible for new quality deviations on this list.',
  'faultLists.delete.body': '<p>All the defects will remain intact but they won\'t belong to any list.</p><p>Are you sure you want to remove the list <strong>{0}</strong>?',
  'faultLists.delete.heading': 'Remove list?',
  'faultLists.lastSync': 'Saved at',
  'faultLists.newFaultList': 'New fault list',
  'faultLists.newList': 'New list',
  'faultLists.noLists': 'No lists yet',
  'faultLists.reportNotSynced': '<b>1</b> deviation not synced.',
  'faultLists.reports.all': 'Defects',
  'faultLists.reports.repaired': 'repaired',
  'faultLists.reports.waiting': 'waiting',
  'faultLists.reportsNotSynced': '<b>{0}</b> deviations not synced.',
  'faultLists.searchByName': 'Search by name',
  'faultLists.searchOrSelectFaultList': 'Fault list',
  'faultLists.searchResults.many': '{0} deviations',
  'faultLists.searchResults.one': '1 deviation',
  'faultLists.syncedJustNow': 'Synced just now.',
  'faultLists.syncing': 'Syncing...',
  'faultLists.types.acceptanceInspection': 'Acceptance inspection',
  'faultLists.types.other': 'Other',
  'faultLists.types.selfInspection': 'Self inspection',
  'faultLists': 'Fault lists',
  'feedback.continue': 'Continue to Zeroni',
  'file.abortAndContinue': 'Abort upload and leave the page',
  'file.fileName': 'File name',
  'file.showFile': 'Show file',
  'file.size': 'Size',
  'file.uploadCancelInfo': 'You are about to leave the page that is uploading a file. If you continue, the upload will be aborted.',
  'file.uploadInProgress': 'File upload in progress...',
  'files': 'Files',
  'fileUploadFailed': 'Uploading the file failed. Please try again or contact support.',
  'footer.call': 'Call',
  'footer.or': 'or',
  'footer.privacyPolicy': '<a target=\'_blank\' rel=\'noopener\' href=\'https://www.zeroni.fi/privacy-policy/\'>Privacy policy</a>',
  'footer.sendMessage': 'Send a message',
  'footer.support': 'Support:',
  'foreman.removeContractForeman': 'Remove contract foreman',
  'foremanNotSelected': 'No foreman selected for contract',
  'foremen.activeEmployees': 'Active employees',
  'foremen.chooseForemen': 'Choose foremen',
  'foremen.chooseForemenInfo': 'Choose foremen for the contractor <strong>{0}</strong>. Foreman status is saved to the access permit.',
  'foremen.contractForeman': 'Contract foreman',
  'foremen.deputyForeman': 'Deputy foreman',
  'foremen.employeesNotFound': 'There are no employees with a valid access permit on this contract.',
  'foremen.foreman': 'Foreman',
  'foremen.foremen': 'Foremen',
  'foremen.noContractForemanInfo': 'Contract foreman has not been selected. You can select contract foreman by choosing from the list.',
  'foremen.notForeman': 'Not foreman',
  'foremen.selectAsContractForeman': 'Select this person as the contract foreman.',
  'foremen.skipContractForemanRequirement': 'If you don\'t want to select a contract foreman, click here.',
  'frontPage': 'Front page',
  'graph.active_employees_count': 'Active employees',
  'graph.activeCompanies': 'Active companies',
  'graph.activeContracts': 'Active contracts',
  'graph.activeOwnContractsCompanies': 'Subcontractors to {0}',
  'graph.chainedcontractor': 'Subcontractors',
  'graph.chainedcontractors': 'Subcontractors',
  'graph.companiesByPostcode': 'Number of companies',
  'graph.contractpartners': 'Contract partners',
  'graph.contractsByLevel': 'Contracts on the site by type',
  'graph.contractsValueByPostcode': 'Value of contracts (t€)',
  'graph.eperehdytys_count': 'E-orientated',
  'graph.eperehdytysCount': 'E-orientation',
  'graph.finnish': 'Finnish',
  'graph.firstLevelSubcontracts': '1. level subcontracts',
  'graph.foreign': 'Foreign',
  'graph.graphsWillBeShownAfterSiteStarted': 'Graphs are shown when the site is active.',
  'graph.maincontractor': 'Main contractor',
  'graph.mainContracts': 'Main contract',
  'graph.nationalities': 'Nationalities',
  'graph.newcomerTrainings': 'Newcomer trainings',
  'graph.noGraphsSelectSite': 'Graphs are site-specific. Please select a site to see graphs.',
  'graph.noGraphsYet': 'No graphs yet',
  'graph.numberOfActiveEmployees': 'Number of active employees',
  'graph.otherCompanies': 'Other companies',
  'graph.partnerContracts': 'Contract partners',
  'graph.permitsByCompanies': 'Permits by companies',
  'graph.secondLevelSubcontracts': '2. level subcontracts',
  'graph.sidecontractors': 'Sidecontractors',
  'graph.sideContracts': 'Side contracts',
  'graph.sitesContractsbyLevel': 'Contract types',
  'graph.siteStarts': 'Site start date is set to {0}.',
  'graph.strength': 'Personnel count',
  'graph.thirdOrMoreLevelSubcontracts': '3+ level subcontracts',
  'graph.workHours': 'Work hours',
  'graph': 'Graph',
  'graphs': 'Graphs',
  'help.addToVendorRegister': 'You can add a company without creating a contract',
  'help.closeQuickTours': 'Close the quick tours?',
  'help.dontShowQuickToursAgain': 'Don\'t show quick tours again',
  'help.helpForAdmin': 'Help for admin',
  'help.helpForSubcontractor': 'Help for subcontractor',
  'help.quickTours': 'Quick tours',
  'hotWork.filename': 'hotworkpermit',
  'hotWork.guardingTimeAfterWork.hours': 'hours',
  'hotWork.guardingTimeAfterWork': 'After work',
  'hotWorkPermit.addHotWorkPermit': 'Add hot work permit',
  'hotWorkPermit.addPermit': 'Add permit',
  'hotWorkPermit.address': 'Hot work permit address',
  'hotWorkPermit.addressPlaceHolder': 'Addresss',
  'hotWorkPermit.approver': 'Permits approver',
  'hotWorkPermit.approverSignatureAndClarification': 'Approver\'s signature and signature clarification',
  'hotWorkPermit.backToHotWorkPermits': 'Back to hot work permit listing',
  'hotWorkPermit.basicInfoHeader': 'Hot work, plan and location',
  'hotWorkPermit.cannotAddNew.noPermission': 'You can\'t create new hot work permits because you don\'t have permissions.',
  'hotWorkPermit.cannotAddNew': 'Cannot create new hot work permit as the employee lacks required active competences for hot work or access permit to the site.',
  'hotWorkPermit.careTaker': 'Name and telephone of the person in charge of the fire alarm/sprinkler system',
  'hotWorkPermit.client.contact': 'Contact person and phone number',
  'hotWorkPermit.client': 'The client',
  'hotWorkPermit.companyOrCommunity': 'Company / community',
  'hotWorkPermit.companyOrContractor': 'Company / contractor',
  'hotWorkPermit.contact': 'Developer\'s contact person',
  'hotWorkPermit.contractor': 'The contractor',
  'hotWorkPermit.contractorHasNoForemen': 'Contractors do not have foremen to be selected as permit holders.',
  'hotWorkPermit.contractorsPlan': 'contractor\'s',
  'hotWorkPermit.dangerAssessment.section.1': 'Optional working methods are assessed before issuing the hot work permit.',
  'hotWorkPermit.dangerAssessment.section.10': 'Sparks and spatters may spread to a wide area because hot work is done at high place.',
  'hotWorkPermit.dangerAssessment.section.11': 'Hot work site has air conditioners and air inlets.',
  'hotWorkPermit.dangerAssessment.section.12': 'Hot work consist disc cutting or grinding which make sparks to cause danger in the radius of 10 meter from the hot work location.',
  'hotWorkPermit.dangerAssessment.section.13': 'Hot work consist flame cutting which makes sparks to spread into a wide area.',
  'hotWorkPermit.dangerAssessment.section.14': 'The heat generated by the hot work can spread to wall, ceiling and floor structures.',
  'hotWorkPermit.dangerAssessment.section.2': 'The hot work site contains inflammable dust, powdery material or inflammable waste.',
  'hotWorkPermit.dangerAssessment.section.3': 'There are inflammable materials at hot work site.',
  'hotWorkPermit.dangerAssessment.section.4': 'Surfaces of wall, ceiling and floor or structures near the hot work site can ignite.',
  'hotWorkPermit.dangerAssessment.section.5': 'Wall, ceiling and floor structures near the hot work site contain flammable materials.',
  'hotWorkPermit.dangerAssessment.section.6': 'There are cables or cable racks at the hot work site.',
  'hotWorkPermit.dangerAssessment.section.7': 'The object of the hot work contains flammable materials.',
  'hotWorkPermit.dangerAssessment.section.8': 'The hot work site has openings, holes, cavities or ventilation channels/gaps through which flames, sparks, spatters can enter into structures or surrounding environment.',
  'hotWorkPermit.dangerAssessment.section.9': 'Hot work site contains or has a risk of generating flammable gases or vapours.',
  'hotWorkPermit.dangerAssessment.section.otherDangers': 'Other dangers:',
  'hotWorkPermit.developer': 'Developer',
  'hotWorkPermit.developersPlan': 'client\'s',
  'hotWorkPermit.duration': 'Duration',
  'hotWorkPermit.emergencyNumber': 'Emergency number',
  'hotWorkPermit.employer': 'Employer',
  'hotWorkPermit.equipmentsAndGuarding': 'Equipments and guarding',
  'hotWorkPermit.extinguishersAcquiredBy': 'Extinguishing equipments are provided by',
  'hotWorkPermit.extinguishingEquipments.equipment.1': 'Portable fire extinguisher 55A 233B C',
  'hotWorkPermit.extinguishingEquipments.equipment.10': 'Special fire fighting equipment',
  'hotWorkPermit.extinguishingEquipments.equipment.11': 'Portable fire extinguisher 43A 233B C',
  'hotWorkPermit.extinguishingEquipments.equipment.2': 'Portable fire extinguisher 43A 183B C',
  'hotWorkPermit.extinguishingEquipments.equipment.3': 'Portable fire extinguisher 27A 144B C',
  'hotWorkPermit.extinguishingEquipments.equipment.4': 'CO2 extinguisher',
  'hotWorkPermit.extinguishingEquipments.equipment.5': 'Fire hose reel',
  'hotWorkPermit.extinguishingEquipments.equipment.6': 'Pressure fire hose',
  'hotWorkPermit.extinguishingEquipments.equipment.7': 'Stirrup water pump',
  'hotWorkPermit.extinguishingEquipments.equipment.8': 'Fire blanket',
  'hotWorkPermit.extinguishingEquipments.equipment.9': 'Cleaning equipments',
  'hotWorkPermit.extinguishingEquipments': 'Extinguishing equipments',
  'hotWorkPermit.fireAlarmAndSprinklerPolicyInfo': 'If required, fire alarm must be disconnected during hot work. Only the person in charge of the equipments can may connect or disconnect the systems. The sprinkler system should not be disconnected. If required, the sprinkler nozzles can be covered during the hot work. This should be agreed with the person in charge of the equipments.',
  'hotWorkPermit.fireExtinguisherRequirements': 'Fire extinguisher requirements',
  'hotWorkPermit.guarding.duringWork': 'During work and breaks.',
  'hotWorkPermit.guarding': 'Hot work guarding',
  'hotWorkPermit.hazards': 'Identifying and assessing the hazards assosiacted with hot work',
  'hotWorkPermit.hotWork.description': 'Work, Plan, Location',
  'hotWorkPermit.hotWork.listOfSafetyRisks': 'List of safety risks',
  'hotWorkPermit.hotWorkFollowsHotWorkPlanOfPart1': 'Hotwork plan of ',
  'hotWorkPermit.hotWorkFollowsHotWorkPlanOfPart2': 'is followed ',
  'hotWorkPermit.hotWorkGuard': 'Hot work guard',
  'hotWorkPermit.hotWorkGuarding': 'Hot work guarding',
  'hotWorkPermit.hotWorkPermitApprover': 'Hot work permit issuer',
  'hotWorkPermit.hotWorkSecurityMeasures': 'Hot work safety precautions',
  'hotWorkPermit.hotWorkType.bitumenCooker': 'Use of bitumen cooker',
  'hotWorkPermit.hotWorkType.discCutting': 'Disc cutting and grinding',
  'hotWorkPermit.hotWorkType.electricWelding': 'Arc welding',
  'hotWorkPermit.hotWorkType.flameCutting': 'Flame cutting',
  'hotWorkPermit.hotWorkType.gasSolderingWelding': 'Oxy-fuel soldering and welding',
  'hotWorkPermit.hotWorkType.hotAirBlower': 'Use of heat gun',
  'hotWorkPermit.hotWorkType.lpgTorch': 'Use of LPG burner',
  'hotWorkPermit.hotWorkType.otherHotWork': 'Other, describe',
  'hotWorkPermit.hotWorkType': 'Hot work',
  'hotWorkPermit.infoAboutExtinguisherPolicy.part1': 'The issuer of a hot work permit specifies the necessary extinguishing equpiments for the hot work site based on a survey and assessment of hazards caused by the hot work. Usually at least two hand extinguishers of class 43A 183B C must be available. For hot works performed at the roof this is mandatory.',
  'hotWorkPermit.infoAboutExtinguisherPolicy.part2': 'The classes are minimum requirements. In practice there are stricter company specific requirements in the industry, for example the requirement for class 55A233BC.',
  'hotWorkPermit.infoAboutExtinguisherPolicy.part3': 'Insured sites may also have additional safety requirements according to the insurance policy.',
  'hotWorkPermit.maximumDurationExceeded': 'Permit duration exceeds the maximum allowed duration({0} days)',
  'hotWorkPermit.missingHotWorkGuards': 'No hot work guards available',
  'hotWorkPermit.missingSelectedEmployees': 'No workers having competence for hot work found for this contractor',
  'hotWorkPermit.name': 'Name',
  'hotWorkPermit.nameOfSiteBuildingDepartmentRegion': 'Company / building / department / area',
  'hotWorkPermit.nameOfSiteBuildingDepartmentRegionPlaceHolder': 'Name of company, building, department or/and location',
  'hotWorkPermit.newPermit': 'New hot work permit',
  'hotWorkPermit.nextStep': 'Next step',
  'hotWorkPermit.noActivePermits': 'No active hot work permits',
  'hotWorkPermit.noDangerOccurs': 'no danger occurs',
  'hotWorkPermit.noPermitsHaveBeenAdded': 'No hot work permits have been added, yet.',
  'hotWorkPermit.ownerSignatureAndClarification': 'Owner\'s signature and signature clarification',
  'hotWorkPermit.periodAdjustmentDisabled': 'Cannot adjust duration of hot work permit because employee and/or guard of hot work is selected.',
  'hotWorkPermit.permitApproverHasCheckedValidationPeriod': 'Permit approver has checked validation period of the hot work permit',
  'hotWorkPermit.permitCannotBeCreated': 'Hot work permit cannot be created.',
  'hotWorkPermit.phoneNumber': 'Phone number',
  'hotWorkPermit.plan': 'Hot work plan',
  'hotWorkPermit.roofPermit': 'Permit for hot work on roof',
  'hotWorkPermit.scopeId': 'Client',
  'hotWorkPermit.securityMeasures.section.1': 'The hot work site must be cleaned',
  'hotWorkPermit.securityMeasures.section.10': 'Gas concentration must be measured and, if needed the space must be ventilated.',
  'hotWorkPermit.securityMeasures.section.11': 'Surrounding areas must be guarded.',
  'hotWorkPermit.securityMeasures.section.12': 'Other required safety measures:',
  'hotWorkPermit.securityMeasures.section.2': 'Any flammable material produced during the work must be removed.',
  'hotWorkPermit.securityMeasures.section.3': 'Inflammable materials must be removed from the hot work site or covered by a fire tarpaulin.',
  'hotWorkPermit.securityMeasures.section.4': 'Cover protections must be placed in such a way that flames, sparks and spatters cannot spread.',
  'hotWorkPermit.securityMeasures.section.5': 'A separate cover structure must be built.',
  'hotWorkPermit.securityMeasures.section.6': 'Any gaps and openings on the walls, ceilings and floors must be covered.',
  'hotWorkPermit.securityMeasures.section.7': 'Cables, cable trays, machinery, devices and other flammable structures etc. must be protected.',
  'hotWorkPermit.securityMeasures.section.8': 'The hot work site must watered.',
  'hotWorkPermit.securityMeasures.section.9': 'The object of hot work must be cooled continuously.',
  'hotWorkPermit.securityMeasures': 'Safety measures',
  'hotWorkPermit.securityMeasuresNeeded': 'security measures are needed',
  'hotWorkPermit.selectCompanyFirst': 'Select a company first',
  'hotWorkPermit.showPdf': 'Open PDF',
  'hotWorkPermit.signatureClearNameAndPhoneNumber': 'Signature, printed name and phone number',
  'hotWorkPermit.signatures': 'Signatures',
  'hotWorkPermit.signedForm': 'Signed form',
  'hotWorkPermit.site': 'Site',
  'hotWorkPermit.subscriber': 'Workers, duration, client and approver',
  'hotWorkPermit.validDaily': 'Valid daily',
  'hotWorkPermit.workers': 'Workers',
  'hotWorkPermit.workersAndDuration': 'Workers, duration',
  'hotWorkPermit': 'Hot work permit',
  'hotWorkPermits': 'Hot work permits',
  'introduction.competences.addOther': 'Add other',
  'introduction.competences.addPopular': 'Add popular competence',
  'introduction.competences.existing': 'Existing',
  'introduction.competences.failedToLoadCompetences': 'Failed to load competences.',
  'introduction.competences.failedToLoadPopular': 'Failed to load popular competences.',
  'introduction.expertise': 'Expertise',
  'invoiceHourRow.reject': 'Reject',
  'invoiceHours.activeInvoiceHours': 'Active invoice hours',
  'invoiceHours.alert1': 'Alert 1',
  'invoiceHours.alert1Short': 'Al.1',
  'invoiceHours.alert2': 'Alert 2',
  'invoiceHours.alert2Short': 'Al.2',
  'invoiceHours.allPendingApproval': 'All pending rows',
  'invoiceHours.approved': 'Approved',
  'invoiceHours.approvedOn': 'Approved {0}',
  'invoiceHours.approver': 'Approver',
  'invoiceHours.badgeTooltip.addedByEmployee': 'Added by worker',
  'invoiceHours.badgeTooltip.approved': 'Approved',
  'invoiceHours.badgeTooltip.approvedSes': 'Approved SES work',
  'invoiceHours.badgeTooltip.created': 'Not yet waiting for approval',
  'invoiceHours.badgeTooltip.invoiced': 'Approved and invoiced',
  'invoiceHours.badgeTooltip.invoicedSes': 'Approved and SES invoiced',
  'invoiceHours.badgeTooltip.pending': 'Pending for approval',
  'invoiceHours.badgeTooltip.rejected': 'Rejected',
  'invoiceHours.buyersReference': 'Buyer\'s reference',
  'invoiceHours.buyersReferenceNumber': 'Buyer\'s reference number',
  'invoiceHours.changeApprover': 'Change approver',
  'invoiceHours.checkedInButNoInvoiceHours': 'Person has check-ins but no invoice hours.',
  'invoiceHours.checkInsWithoutInvoiceHours': 'Check-ins without invoice hours',
  'invoiceHours.confirmYourRowDeletion': 'Are you sure you want to delete this row?',
  'invoiceHours.contractorNameAndInvoiceHourSummaryId': 'Contractor or summary id',
  'invoiceHours.contractorNameAndInvoiceHourSummaryIdAndInvoiceNumber': 'Contractor, summary id or invoice number',
  'invoiceHours.contractors': 'Contractors',
  'invoiceHours.contractorsReference': 'Contractor\'s reference',
  'invoiceHours.contractorsSummaries': 'Contractor\'s hourly summariers',
  'invoiceHours.copy': 'Copy',
  'invoiceHours.copyDay.cannotBeCopiedToFuture': 'Cannot be copied to future',
  'invoiceHours.copyDay.copyMultipleRows': 'Copy {0} rows',
  'invoiceHours.copyDay.copyOneRow': 'Copy 1 row',
  'invoiceHours.copyDay.copyRowsFromAnotherDay': 'Copy rows from another day',
  'invoiceHours.copyDay.futureError': 'Make sure all selected rows can be copied to the future.',
  'invoiceHours.copyDay.noRowsOnSelectedDay': 'Selected day has no rows.',
  'invoiceHours.copyDay.noSourceDataAvailable': 'Person has no applicaple rows available.',
  'invoiceHours.copyDay.okCloseCopyDialog': 'Ok, close this copy dialog',
  'invoiceHours.copyDay.selectDayFirst': 'Select a day first.',
  'invoiceHours.copyDay.selectRowsToBeCopied': 'Select rows you want to copy',
  'invoiceHours.copyDay.targetDay': 'Target day',
  'invoiceHours.copyDay.targetDays': 'Target days',
  'invoiceHours.copyDay.toMultipleDays': 'Copy to multiple days',
  'invoiceHours.copyDay': 'Copy from day',
  'invoiceHours.copyJobNumber.ownVariant': 'Copy job number',
  'invoiceHours.copyJobNumber': 'Copy job number',
  'invoiceHours.copyToGroup': 'Copy hours to a group',
  'invoiceHours.created': 'Creation date',
  'invoiceHours.currentWeekIs': 'Current week is wk {0}.',
  'invoiceHours.date': 'Date',
  'invoiceHours.dates': 'Dates',
  'invoiceHours.deleteRowHeading': 'Remove row?',
  'invoiceHours.deleteSelectionsBody': 'Are you sure you want to reject the selections? Confirm by stating a reason for the rejection.',
  'invoiceHours.deleteSelectionsHeading': 'Reject selections?',
  'invoiceHours.draftOnly': '<strong>This is a draft of the summary</strong>, and not the final summary.',
  'invoiceHours.employee.summary': 'Invoice hour summary',
  'invoiceHours.enableInvoiceHours': 'Enable invoice hours',
  'invoiceHours.followingDaysHaveMoreHoursThanCheckIns': 'You\'re about to copy {0} work hours. According to check-ins the following days are below that value.',
  'invoiceHours.fullPerDiem': 'Full per diem',
  'invoiceHours.grandTotalForJobNumber.ownVariant': 'All time total for job number',
  'invoiceHours.grandTotalForJobNumber': 'All time total for job number',
  'invoiceHours.grandTotalForJobNumberContract.ownVariant': 'All time total for job number (contract)',
  'invoiceHours.grandTotalForJobNumberContract': 'All time total for job number (contract)',
  'invoiceHours.grandTotalForJobNumberDescriptionContract.ownVariant': 'Total approved invoice hours from the entire duration of the site by job number, approver and contract',
  'invoiceHours.grandTotalForJobNumberDescriptionContract': 'Total approved invoice hours from the entire duration of the site by job number, approver and contract',
  'invoiceHours.grandTotalForJobNumberDescriptionEmployee.ownVariant': 'Total approved invoice hours from the entire duration of the site by job number, approver and employee',
  'invoiceHours.grandTotalForJobNumberDescriptionEmployee': 'Total approved invoice hours from the entire duration of the site by job number, approver and employee',
  'invoiceHours.grandTotalForJobNumberEmployee.ownVariant': 'All time total for job number (employee)',
  'invoiceHours.grandTotalForJobNumberEmployee': 'All time total for job number (employee)',
  'invoiceHours.group': 'Group',
  'invoiceHours.handler': 'Handler',
  'invoiceHours.hasEmployeesInvoiceHours': 'Invoice hours added by workers waiting for completion',
  'invoiceHours.hasEmployeesInvoiceHoursShort': 'Invoice hours waiting for completion',
  'invoiceHours.hourBank.balance': 'Balance',
  'invoiceHours.hourBank': 'Hour bank',
  'invoiceHours.hours': 'Hours',
  'invoiceHours.hoursCheckedIn': 'Hours checked in',
  'invoiceHours.hoursOnAllSites': 'Hours on all sites',
  'invoiceHours.hoursOnAllSitesDescription': 'Calculated from all sites you have access to.',
  'invoiceHours.hoursOnThisRow': 'Hours on this row',
  'invoiceHours.idle': 'Idle',
  'invoiceHours.idleShort': 'Idle',
  'invoiceHours.includeSubContractors': 'Include subcontractors',
  'invoiceHours.initContractApproval': 'Approve rows...',
  'invoiceHours.invoicing': 'Invoicing',
  'invoiceHours.jobNumber.ownVariant': 'Job number',
  'invoiceHours.jobNumber': 'Job number',
  'invoiceHours.jobNumberTotals.ownVariant': 'Totals by job number',
  'invoiceHours.jobNumberTotals': 'Totals by job number',
  'invoiceHours.loadingCheckIns': 'Loading check ins...',
  'invoiceHours.lockAndCreate': 'Lock and create summary',
  'invoiceHours.lockedBecauseHoursFromOtherSummaries': 'Locking is prevented, because the summary contains invoice hours from other summaries.',
  'invoiceHours.lockedBecausePendingHours': 'Locking is prevented, because the summary contains invoice hours waiting for approval.',
  'invoiceHours.lockFailed': 'Locking failed. There has likely been new invoices or a new preview for the selected time period.',
  'invoiceHours.locking': 'Locking...',
  'invoiceHours.modifyRows': 'Modify rows...',
  'invoiceHours.moreHoursDecisions.copyHoursAccordingToCheckIns': 'Copy the amount of work shown in check-ins',
  'invoiceHours.moreHoursDecisions.enterReasonForExtraHours': 'Enter reason for hours exceeding check-ins',
  'invoiceHours.moreHoursThanCheckIns': 'You\'re trying to copy more hours than indicated by check-ins',
  'invoiceHours.new': 'New invoice hours',
  'invoiceHours.newRow': 'New row',
  'invoiceHours.newSummary': 'New summary',
  'invoiceHours.newSummaryForInvoice': 'New invoice hours summary',
  'invoiceHours.noEmployeeRows': 'No invoices yet on this day',
  'invoiceHours.noInvoiceHours': 'No invoice hours added yet.',
  'invoiceHours.noLockedInvoiceHoursFound': 'You requested hours in other summaries as well, but none were found with the given search terms.',
  'invoiceHours.nonWorkDescription': 'Description',
  'invoiceHours.noOtherGroupMembersAvailable': 'No other group members available',
  'invoiceHours.noPendingInvoiceHoursFound': 'You requested pending invoice hours, but none were found with the given filters.',
  'invoiceHours.noPendingRows': 'No pending rows.',
  'invoiceHours.noPerDiem': 'No per diem',
  'invoiceHours.noPermits': 'Your search did not match any employees',
  'invoiceHours.noSitesWithInvoiceHours': 'You don\'t have access to sites that have invoice hours enabled.',
  'invoiceHours.noSummaries': 'No summaries',
  'invoiceHours.notEnabled': 'Invoice hours not enabled',
  'invoiceHours.onlyApprovablesForDate': 'Only approvable',
  'invoiceHours.onSummary': 'On summary',
  'invoiceHours.otherGroupMembers': 'Other group members',
  'invoiceHours.otherTimeSpan': 'Other time span',
  'invoiceHours.overtimeReason': 'Reason for exceeding checked in hours',
  'invoiceHours.own': 'Own',
  'invoiceHours.partialPerDiem': 'Partial per diem',
  'invoiceHours.payGrade': 'Pay grade',
  'invoiceHours.payGradeTotals': 'Pay grade totals',
  'invoiceHours.pend': 'Remove approval',
  'invoiceHours.pending': 'Pending',
  'invoiceHours.pendQuestion': 'Remove approval?',
  'invoiceHours.perDiem': 'Per diem',
  'invoiceHours.perDiemAbbr': 'Per d.',
  'invoiceHours.permitNotActive': 'Access permit is not active, new rows cannot be added.',
  'invoiceHours.permitNotActiveInfo': 'This access permit is valid <strong>{0}</strong>. If you want to add invoice hours or travel expenses for this day, you need to change the validity period of the permit or create a new permit.',
  'invoiceHours.preview': 'Preview',
  'invoiceHours.reasonForRejection': 'Reason for rejection',
  'invoiceHours.reasonToReject': 'Reason to reject',
  'invoiceHours.receiver': 'Summary receiver',
  'invoiceHours.refreshPreview': 'Refresh preview',
  'invoiceHours.refreshWithoutOtherHours': 'Get information without other hours form other summaries',
  'invoiceHours.refreshWithoutPending': 'Refresh information without information waiting for approval',
  'invoiceHours.reject': 'Reject',
  'invoiceHours.rejected': 'Rejected',
  'invoiceHours.rejecting': 'Rejecting...',
  'invoiceHours.requestConfirmationForDeletion': 'You are deleting invoice hours from person <strong>{0}</strong>. Are you sure?',
  'invoiceHours.requestConfirmationForPending': 'You are removing the approval from the invoice hour of <strong>{0}</strong>. Are you sure that you want to remove approval?',
  'invoiceHours.requestProducedNoHours': 'No invoices found using search filters',
  'invoiceHours.rest': 'Rest',
  'invoiceHours.revision': 'Revision',
  'invoiceHours.rowsPendingApproval': 'Approval awaiting invoice hours',
  'invoiceHours.salesOrderItem': 'So row',
  'invoiceHours.salesOrderNumber': 'Sales order',
  'invoiceHours.saveAndApprove': 'Save and approve',
  'invoiceHours.searchOrSelectApproverPlaceholder': 'Approver',
  'invoiceHours.searchOrSelectCompanyPlaceholder': 'Company',
  'invoiceHours.searchOrSelectContractPlaceholder': 'Contract',
  'invoiceHours.searchOrSelectEmployeePlaceholder': 'Employee',
  'invoiceHours.searchOrSelectGroupMembers': 'Other group members',
  'invoiceHours.searchOrSelectJobNumberPlaceholder': 'Job number',
  'invoiceHours.searchOrSelectPayGradePlaceholder': 'Pay grade',
  'invoiceHours.searchOrSelectPersonPlaceholder': 'Person',
  'invoiceHours.searchOrSelectServicePlaceholder': 'Service',
  'invoiceHours.selectDay': 'Select day',
  'invoiceHours.selectReceivingCompanyPlaceholder': 'Select receiver of invoice hour summary',
  'invoiceHours.selectTimeSpan': 'Select time span',
  'invoiceHours.sendAsPending': 'Send for approval',
  'invoiceHours.service': 'Service',
  'invoiceHours.serviceNotUsableForFuture': 'Not allowed for future rows',
  'invoiceHours.serviceOrder.approved': 'Approved',
  'invoiceHours.serviceOrder.ordered': 'Ordered',
  'invoiceHours.serviceOrder.service': 'Service',
  'invoiceHours.serviceOrderTotal': 'Service order total',
  'invoiceHours.showGrandTotalForJobNumber': 'Show all time total for job number',
  'invoiceHours.showLocked': 'Also other invoice hours summaries',
  'invoiceHours.shownEmployees.all': 'All employees',
  'invoiceHours.shownEmployees.own': 'Own employees',
  'invoiceHours.shownEmployees.subcontractor': 'Subcontractors',
  'invoiceHours.showPending': 'Also invoice hours waiting for approval',
  'invoiceHours.statusType.addedByEmployee': 'Added by employee',
  'invoiceHours.statusType.addedByEmployeeSingular': 'Added by employee',
  'invoiceHours.statusType.all': 'All invoice hours',
  'invoiceHours.statusType.approved': 'Approved invoice hours',
  'invoiceHours.statusType.pending': 'Pending approval',
  'invoiceHours.summaries': 'Invoice hours summaries',
  'invoiceHours.summary': 'Summary',
  'invoiceHours.summaryIncludesLockedInvoiceHours': 'Persons summary contain hours from other summaries.',
  'invoiceHours.summaryIncludesPendingInvoiceHours': 'Persons summary includes pending invoice hours.',
  'invoiceHours.tempSave': 'Save only, do not send',
  'invoiceHours.totalHours': 'Total hours',
  'invoiceHours.travelTime': 'Travel time',
  'invoiceHours.travelTimeShort': 'Trav.',
  'invoiceHours.workDescription': 'Work description',
  'invoiceHours.workRecorded': 'Work recorded',
  'invoiceHours': 'Invoice hours',
  'jobNumber.mostUsed': 'Most used',
  'label._foremanChecked': 'Foreman',
  'label.a1CertificateExpired.info': 'Social security certificate expired. Expiration can affect gate access on this site.',
  'label.a1CertificateExpired': 'Social security certificate expired',
  'label.a1CertificateExpiring': 'Social security certificate about to expire',
  'label.a1CertificatesExpiredOrExpiring': '<strong>{0} Social security certificates</strong> have expired or are about to expire within 14 days.',
  'label.accessArea': 'Access area',
  'label.accessAreas': 'Access areas',
  'label.accessAreasSaved': 'Access areas saved!',
  'label.accessPermit.workSpaces': 'Work spaces',
  'label.accessPermit': 'Access permit',
  'label.accessPermitCreator': 'Access permit creator',
  'label.accessPermitRejected': 'Ended',
  'label.accessPermits.contracts': 'Contracts',
  'label.accessPermits': 'Access permits',
  'label.accessPermitValid': 'Access permit valid',
  'label.accessPermitValidationPeriod': 'New permit starts, ends',
  'label.accidentInsurance': 'Accident Insurance',
  'label.accidentInsuranceLegend': 'In building sector contracts, a certificate of compulsory accident insurance taken out',
  'label.accomplish': 'Accomplish',
  'label.accomplishedOnline': 'Accomplished online',
  'label.activateReferences': 'Payment references active',
  'label.activeEmployees': 'Active',
  'label.activeEmployeesDesc': 'Show employees, who have checkins in given time period',
  'label.actualValue': 'Actual value',
  'label.add': 'Add',
  'label.addAccessPermit': 'Add access permit',
  'label.addCheckInDevice': 'Add a check in device',
  'label.addComment': 'Add comment',
  'label.addCommentOrReimbursment': 'Add comment / reimbursment',
  'label.addCommentToCheckIn': 'Add comment to check in',
  'label.addCommentToCheckOut': 'Add comment to check out',
  'label.addCompany': 'Add company',
  'label.addCompetence': 'Add competence',
  'label.addContract': 'Add contract',
  'label.addContractAndAccessPermit': 'Add contract and access permit',
  'label.addContractor': 'Add contractor',
  'label.addContractorObligationDocuments': 'Add contractor obligation documents',
  'label.addDevice': 'Add device',
  'label.addDeviceToSite': 'Add device to site',
  'label.added': 'Added',
  'label.adding': 'Adding...',
  'label.addNew': 'Add new',
  'label.addNewAccessPermit': 'Add new access permit',
  'label.addNewCompany': 'Add new company',
  'label.addNewCompetence': 'Add new competence',
  'label.addNewContract': 'Add new contract',
  'label.addNewDevice': 'Add new device',
  'label.addNewPerson': 'Add new person',
  'label.addNewSite': 'Add new site',
  'label.addNewUser': 'Add new user',
  'label.addPerson': 'Add person',
  'label.address': 'Address',
  'label.addressInFinland': 'Address in Finland',
  'label.addressInHomeCountry': 'Address in home country',
  'label.addSafetyQuarter': 'Add new safety quarter',
  'label.addSite': 'Add site',
  'label.addTag': 'Add tag',
  'label.addToVendorRegister': 'Add company',
  'label.addTraining': 'Add training',
  'label.addUser': 'Add user',
  'label.addUserGroup': 'Add new user role',
  'label.addValttiCard': 'Add Valtti card',
  'label.addVehiclePermit': 'Add vehicle permit',
  'label.admittance': 'Admittance',
  'label.admittanceRequired': 'Person can enter the site',
  'label.alarmLackingWeeklyHours': 'Alarm for  weekly hours',
  'label.all': 'All',
  'label.allForemen': 'Foremen from all sites',
  'label.allHours': 'all',
  'label.allocate': 'Allocate',
  'label.allocatedToSite': 'Added to site',
  'label.allocateSelected': 'Allocate selected',
  'label.allocating': 'Allocating...',
  'label.allowed': 'Allowed',
  'label.allRequiredCoursesExist': 'All required courses exists',
  'label.allRequiredFieldsValid': 'Required fields completed',
  'label.allSites': 'All sites',
  'label.allTaxReportsSent': 'All tax reports sent',
  'label.amountShort': 'pcs',
  'label.and': ' and ',
  'label.approval.waiting': 'Waiting for approval',
  'label.approval': 'Approval',
  'label.approve': 'Approve',
  'label.approved': 'Approved',
  'label.approvedAccessPermitsFromTo': 'Approved access permits from {0} to {1}',
  'label.approving': 'Approving...',
  'label.archive': 'Archive',
  'label.archived': 'Archived',
  'label.area': 'Area',
  'label.areaSaved': 'Area saved!',
  'label.areYouSure': 'Are you sure?',
  'label.asDeveloper': 'as a developer',
  'label.askAboutSubcontractEndDateChange': 'Do you want to change the end date of subcontracts under this contract? This option will update all of the contracts, which end at {0}</strong>. New end date is {1}.',
  'label.asMainContractor': 'as a main contractor',
  'label.asSubcontractor': 'as a subcontractor',
  'label.attachment': 'Attachment',
  'label.attachments': 'Attachments',
  'label.attachmentsAdded': 'Attachments added',
  'label.atTime': 'at',
  'label.automaticallySaveContractorLiabilityDocsEveryContractAnniversary': 'Update contractors\' Tilaajavastuu status and corresponding report when site\'s contracts have anniversaries and archive old reports',
  'label.automaticTagListUpdate': 'Automatic updating of new tags to gate devices',
  'label.automaticTagListUpdateShort': 'Tags',
  'label.aviMissingTagsTooltip': 'Only employees who don\'t have tags given by main contractor or developer or a Valtti card. By selecting the extra option "Only own tags" show only employees who don\'t have tags given by main contractor or developer.',
  'label.aviReport': 'Settings for AVI-report',
  'label.aviReportShort': 'AVI',
  'label.aviTimespanTooltip': 'Report includes employees, who have an access permit for at least one day in the given time span.',
  'label.awaitingIntroduction': 'Awaiting orientation',
  'label.backToDashBoard': 'Back to dashboard',
  'label.basicInformation': 'Basic information',
  'label.begins': 'Begins',
  'label.begun': 'Begun',
  'label.betaVersion': 'Beta version',
  'label.birthdayBoyOnSite': 'A sites employee has birthday today or tomorrow',
  'label.birthdayBoysOnSite': '<strong>{0}</strong> employees on the site have birthday today or tomorrow',
  'label.birthdayToday': '<strong>{0}</strong>({1}) turns <strong>{2}</strong> today!',
  'label.birthdayTomorrow': '<strong>{0}</strong>({1}) turns <strong>{2}</strong> tomorrow!',
  'label.blocksReportsToTaxAdmin': 'Blocks reports to Tax Administration',
  'label.both': 'Both',
  'label.buyer': 'Buyer',
  'label.buyerContactPerson': 'Buyer\'s contact person',
  'label.call': 'Call',
  'label.cancel': 'Cancel',
  'label.car': 'Car',
  'label.card': 'Card',
  'label.cardId': 'Site Card',
  'label.cardIds': 'Site Cards',
  'label.carShort': 'Car',
  'label.certificates': 'certificates',
  'label.change': 'Change',
  'label.changeContactDetails': 'Change contact details',
  'label.changeContactDetailsSubmitting': 'Changing contact details...',
  'label.changeContactDetailsSuccess': 'Contact details changed!',
  'label.checkedIn': 'At site',
  'label.checkedInDesc': 'Show only employees, who are checked in now',
  'label.checkedOnDate': 'Checked on {0}',
  'label.checkedOutNextDay': 'Checked out next day',
  'label.checkIn': 'Check-in',
  'label.checkInAlreadyExists': 'You can\'t create two overlapping checkins for same minute. Please wait a minute and try again.',
  'label.checkInAlreadyExists2': 'You can\'t create two overlapping checkins for same minute.',
  'label.checkInDevice': 'Check in device',
  'label.checkInDeviceInfo': 'Information for check-in device',
  'label.checkInDevices': 'Check in devices',
  'label.checkInIsModified': 'This check in has been modified',
  'label.checkInOutsideSite': 'It could not be verified that the mobile check-in happened at the site area.',
  'label.checkIns': 'Check-ins',
  'label.checkOutForgottenHeading': 'Forgot to check out?',
  'label.checkOutIsModified': 'This check out has been modified',
  'label.chooseFile': 'Select a file',
  'label.chooseForemen': 'Choose foremen',
  'label.chooseFromRegister': 'Choose from register',
  'label.chooseLanguage': 'Choose language...',
  'label.chooseSiteForEvaluation': 'Choose the site to which the evaluation is related',
  'label.city': 'City',
  'label.clear': 'Clear',
  'label.close': 'Close',
  'label.closeWithoutSaving': 'Close without saving',
  'label.clothingSize': 'Clothing size',
  'label.collectiveLaborAgreement': 'Collective labor agreement',
  'label.columnHeadingBirthDate': 'Birth date',
  'label.columnHeadingBusinessId': 'Business id',
  'label.columnHeadingCardNumber': 'Card num.',
  'label.columnHeadingCity': 'City',
  'label.columnHeadingCompetenceCardNumber': 'Card num.',
  'label.columnHeadingContact': 'Contact person',
  'label.columnHeadingContractor': 'Contractor',
  'label.columnHeadingContractorHomeCountry': 'Employers home country',
  'label.columnHeadingCountry': 'Country',
  'label.columnHeadingDateShort': 'Date',
  'label.columnHeadingEmail': 'Email',
  'label.columnHeadingEmployer': 'Employer',
  'label.columnHeadingIdConfirmation': 'Id confirmed',
  'label.columnHeadingIntroduced': 'Oriented',
  'label.columnHeadingName': 'Name',
  'label.columnHeadingNationality': 'Nat.',
  'label.columnHeadingPaymentNote': 'Note',
  'label.columnHeadingPaymentType': 'Type',
  'label.columnHeadingPostcode': 'Post code',
  'label.columnHeadingRelation': 'Work relation',
  'label.columnHeadingRep': 'Representative',
  'label.columnHeadingRepCity': 'Representative city',
  'label.columnHeadingRepEmail': 'Representative email',
  'label.columnHeadingRepFirstName': 'Representative first name',
  'label.columnHeadingRepLastName': 'Representative last name',
  'label.columnHeadingRepPostcode': 'Representative postcode',
  'label.columnHeadingRepStreet': 'Representative address',
  'label.columnHeadingRepTelephone': 'Representative telephone',
  'label.columnHeadingResidencePermitExpires': 'Residence permit expires',
  'label.columnHeadingSsn': 'SSN',
  'label.columnHeadingStreet': 'Street',
  'label.columnHeadingTaxNumber': 'Tax number',
  'label.columnHeadingTelephone': 'Telephone',
  'label.columnHeadingValue': 'Value',
  'label.columnHeadingVehiclePermitApprover': 'Approver',
  'label.columnHeadingVehiclePermitStatus': 'Accepted',
  'label.columnHeadingLastCheckInTime': 'Check-in time',
  'label.columnHeadingAccessAreaName': 'Area',
  'label.commaSeparated': 'Comma separated',
  'label.comment': 'Comments',
  'label.commentOrReimbursment': 'Comment / reimbursment',
  'label.commitmentLink': 'Commitment {0} (PDF)',
  'label.companies': 'Companies',
  'label.companiesLackingLiabilityInsurance': '<strong>{0} companies</strong> lack valid liability insurance information on Tilaajavastuu Reliable Partner report. <b>If the liability insurance of the company is valid, a representative of the company can update the information on the Reliable Partner report <a href="https://kampanja.tilaajavastuu.fi/vastuuvakuutus" target="_blank">here.</a></b>',
  'label.companiesWithIncompleteInformation': '<strong>{0} companies</strong> are missing some information.',
  'label.companiesWithInvalidContractorLiability': '<strong>{0}</strong> companies have invalid contractor\'s obligations information.',
  'label.company': 'Company',
  'label.companyExtraInfo': 'Show company info',
  'label.companyHasntBeenEvaluated': 'The company has not been evaluated yet.',
  'label.companyHealth': 'Company Health Provider',
  'label.companyHealthProvider': 'Company health provider',
  'label.companyHealthProviderLegend': 'An account of how health services are being provided within the company (required: a health service provider in Finland)',
  'label.companyInfo': 'Company info',
  'label.companyLackingLiabilityInsurance': 'Company <strong>{0}</strong> is lacking valid liability insurance information on <a href="companies/getreport?company={1}" target="_blank">Tilaajavastuu Reliable Partner report</a>. <b>If the liability insurance of the company is valid, a representative of the company can update the information on the Reliable Partner report <a href="https://kampanja.tilaajavastuu.fi/vastuuvakuutus" target="_blank">here.</a></b>',
  'label.companyName': 'Company name',
  'label.companyRole': '{0} role',
  'label.companySearch': 'Company search',
  'label.companySpecificInfo': 'Company specific information',
  'label.companySpecificInfoHelp': 'Company specific information is only visible to the users of company {0}.',
  'label.companyUnit': 'Unit',
  'label.companyWithIncompleteInformation': 'Missing some information for company <strong>{0}</strong>.',
  'label.companyWithInvalidContractorLiability': 'Contractor\'\'s obligations information on <strong>{0}</strong> is invalid.',
  'label.competence': 'Competence',
  'label.competenceExpired': 'Competence expired',
  'label.competenceExpiring': 'Competence about to expire',
  'label.competenceLoadError': 'Error while loading competence details.',
  'label.competenceModelRemoved': 'Course type removed',
  'label.competenceModelRemovedShort': 'This course type is no more in use',
  'label.competencePendingAccomplishment': 'Pending the accomplishment',
  'label.competenceReport.active': 'Active',
  'label.competenceReport.expired': 'Expired',
  'label.competenceReport.expiring': 'Expiring',
  'label.competenceReport.noCompetence': 'No valid selected competence',
  'label.competenceReportInfo': 'Creates an Excel file that contains employees\' competences with the given criteria.',
  'label.competenceReportInfo2': 'Selecting expiring shows all competences that are expiring with 31 days. If you want to list the competences of your company\'s every employee, please create a virtual work site. You can then add your employees to that site.',
  'label.competences': 'Competences',
  'label.competencesExpiredOrExpiring': '<strong>{0} competences</strong> have expired or are about to expire within 30 days.',
  'label.competencesLoadError': 'Error while loading competences.',
  'label.completed': 'Completed',
  'label.confirm': 'Confirm',
  'label.confirmation': 'Verification',
  'label.confirmDeletion': 'Confirm deletion',
  'label.confirmEnd': 'Confirm end',
  'label.confirming': 'Confirming...',
  'label.confirmRemovalFromSite': 'Confirm removal',
  'label.confirmRemove': 'Confirm removal',
  'label.constructor': 'Constructor',
  'label.contactForTaxAdmin': 'Contact person for reports to Tax Administration',
  'label.contactForTaxAdminTooltip': 'Person responsible for sending monthly reports to Tax Administration',
  'label.contactInfo': 'Contact info',
  'label.contactInformation': 'Contact information',
  'label.contactPerson': 'Contact person',
  'label.contactPersonInCompany': 'Contact person in company {0}',
  'label.continue': 'Continue',
  'label.continueEditing': 'Continue editing',
  'label.continueToPermitAdd': 'Continue to add permit',
  'label.contract': 'Contract',
  'label.contractAndEmployeeDeclarations': 'Report on contracts and employees',
  'label.contractChangeWarning': 'Persons checkins will be moved to the new employer. In the case of merger leave this access permit unchanged and create new access permit for the new employer.',
  'label.contractContactPerson': 'Contract contact persons',
  'label.contractContacts': 'Search for contract contacts',
  'label.contractCount': 'contract',
  'label.contractDeclarations': 'Report on contract details',
  'label.contractDetails': 'Contract details',
  'label.contractForeman': 'Primary foreman',
  'label.contractInformation': 'Contract information',
  'label.contractingType': 'Contracting type',
  'label.contractingTypeConstruction': 'Construction or maintenance',
  'label.contractingTypeDevelopment': 'Property development',
  'label.contractingTypeHire': 'Hire',
  'label.contractingTypeTooltip': 'Types are given from Tax Administration. For consulting work, use "Construction or maintentance".',
  'label.contractKey': 'Contract key',
  'label.contractManagedLabel': 'Access rights to contractor and contact person',
  'label.contractManagedLabelMultipleContacts': 'Access rights to contractor and contact persons',
  'label.contractNumber': 'Contract number',
  'label.contractNumberTooltip': 'A number or other kind of identificator for this contract used within your company',
  'label.contractor': 'Contractor',
  'label.contractorEmployees': 'contractors employees',
  'label.contractorIntroduces': 'Contractors can orient employees independently',
  'label.contractorIntroductionWarning': '<strong>Note!</strong> This feature is permanent once saved.',
  'label.contractorLiability.search': 'Contractor liability search',
  'label.contractorLiability': 'Contractor\'s obligations',
  'label.contractorLiabilityErrors': 'Errors in contractor\'s obligations',
  'label.contractorLiabilityExpired': 'Contractor\'s obligation documents have expired',
  'label.contractorLiabilityMoreInfo': 'https://support.vastuugroup.fi/hc/en-gb/articles/115005569269-The-symbols-on-the-Reliable-Partner-report',
  'label.contractorLiabilityNotFound': 'Not found',
  'label.contractorLiabilityOk': 'Ok',
  'label.contractorLiabilityReport': 'Report on contractor obligations',
  'label.contractorObligationReport': 'Contractor obligation report',
  'label.contractorObligationReportShort': 'Contractor obligation',
  'label.contractorReport.contractors': 'Contractors',
  'label.contractorReport.showOnlyActive': 'Show only active contractors',
  'label.contractorReport': 'Report on contractors',
  'label.contractorReportInfo': 'Shows contractors on the given site or on all sites.',
  'label.contractorReportShort': 'Contractor',
  'label.contractors': 'Contractors',
  'label.contractorSearch': 'Contractor search',
  'label.contractorShort': 'Contr.',
  'label.contractorSubcontracts': 'Contractors can create subcontracts',
  'label.contractorsWithoutForeman': '<strong>{0}</strong> contractors have no foreman with access permit.',
  'label.contractorsWithoutForemanInfo': 'Foremen are determined through their access permit.',
  'label.contractPartner': 'Contract partner',
  'label.contracts': 'Contracts',
  'label.contractsCount': 'contracts',
  'label.contractStatus': 'Contract status',
  'label.contractsWithAwaitingDocuments': 'Contractor\'s obligation documents awaiting for approval',
  'label.contractTree': 'Contract tree',
  'label.contractType': 'Contract type',
  'label.contractWork': 'Contract',
  'label.copied': 'Copied',
  'label.copy': 'Copy',
  'label.copyAccessPermits': 'Copy access permits for all persons',
  'label.copyContractsAndPersons': 'Copy contracts and persons',
  'label.copyContractTreeAll': 'Copy the entire contract tree',
  'label.copyContractTreeDeveloperAndMC': 'Only copy the buyer and the main contractor',
  'label.copyContractTreeInfoText': 'Contractor\'s obligations documents for all contracts to be copied must be in order. This can be done through the Trusted Partner program or by adding the Contractor\'s obligations documents with the expiration date.',
  'label.copyContractTreeInvalidLiabilityStates': 'Contractor\'s obligations documents are not in order for following contracts:',
  'label.copyContractTreeLiabilityStatusCheckFailed': 'Failed to verify subscriber responsibility information. Please try again. If the problem persists, contact support (support@zeroni.fi).',
  'label.copyContractTreeLiabilityStatusCheckRunning': 'Verifying Contractor\'s obligations...',
  'label.copyContractTreeLiabilityStatusReCheck': 'Try again',
  'label.copyFromSite': 'Copy from site ',
  'label.copying': 'Copying...',
  'label.copySite': 'Copy site',
  'label.copySourceSite': 'Copying site',
  'label.count': 'Count',
  'label.country': 'Country',
  'label.createAccessPermit': 'Create access permit from this check in',
  'label.createContractAndAccessPermitFromUnresolvedCheckIn': 'Create contract and access permit from this check in',
  'label.createIntroductionForm': 'Create orientation form',
  'label.createSubContract': 'Create subcontract',
  'label.creating': 'Creating...',
  'label.creator': 'Creator',
  'label.currentCountry': 'Normal country of employment',
  'label.daily': 'Diary',
  'label.dailyReport': 'Daily Construction Report',
  'label.dashboard': 'Dashboard',
  'label.date': 'Date',
  'label.dateFormat_ddmmyyyy': 'dd.mm.yyyy',
  'label.dateFormat_mmyyyy': 'mm/yyyy',
  'label.dateOfBirth': 'Date of birth',
  'label.dateOfBirthShort': 'd.o.b.',
  'label.dateShort': 'Date',
  'label.daysAbbreviation': 'days',
  'label.ddmmyyyy': 'dd.mm.yyyy',
  'label.declarationID': 'Report id',
  'label.default': 'Default',
  'label.defaultRoles': 'default roles.',
  'label.delete': 'Delete',
  'label.deleteDeviceHeading': 'Remove <strong>{0}</strong>',
  'label.deleteUserGroup': 'Delete user role',
  'label.deleting': 'Deleting...',
  'label.department': 'Department',
  'label.departmentFilterPlaceholder': 'Filter by department',
  'label.departmentPlaceholder': 'Select the department to which the site belongs',
  'label.developer': 'Developer',
  'label.developerAddress': 'Developer address',
  'label.developerContactPhoneNumber': 'Developers contact persons phone number',
  'label.developerContractManagedLabel': 'Access rights to developer and contact person',
  'label.developerContractManagedLabelMultipleContacts': 'Access rights to developer and contact persons',
  'label.device': 'Device',
  'label.devices': 'Devices',
  'label.digiPenForm': 'Digi Pen form',
  'label.digiPenForms': 'Digi Pen forms',
  'label.digiPenHasComment': 'Inc. notes',
  'label.disallowed': 'Prohibited',
  'label.documentCopy': 'Photo of the document',
  'label.documents': 'Documents',
  'label.documentsCanBeSeenByContractors': 'The documents can be seen by contractors.',
  'label.domainContext': 'Site type and location',
  'label.doNotAllocate': 'Do not allocate',
  'label.doNotChange': 'Do not change',
  'label.doNotExtend': 'Do not extend',
  'label.dontShowAgain': 'Don\'t show this again',
  'label.download': 'Download',
  'label.downloadCsv': 'Download CSV',
  'label.downloadExcel': 'Download Excel',
  'label.downloadFile': 'Download file',
  'label.downloadNFCReader': 'Download NFCReader',
  'label.dragHere': 'Drag here',
  'label.driversLicense': 'Driver\'s license',
  'label.drugTest': 'Latest drug test',
  'label.dueDate': 'Due date',
  'label.edit': 'Edit',
  'label.edited': 'Edited',
  'label.editingRights': 'Edit',
  'label.editSiteAccessArea': 'Edit site\'s access area',
  'label.editVehiclePermit': 'Edit vehicle permit',
  'label.eePersonalCode': 'Estonian personal identification number',
  'label.electronicIntroductionError': 'Oriented online, but the document (PDF) is missing. Generate a new a new document by clicking here.',
  'label.electronicIntroductionErrorInfo': 'The PDF document could not be generated because of a network error. The application could not handle the error correctly and therefore no document was saved. You can generate a new orientation document by clicking the link below. The cause of the error has been fixed 4.7.2019.',
  'label.electronicIntroductionLink': 'Online confirmation of orientation {0} (PDF)',
  'label.email': 'Email',
  'label.emailAddress': 'Email address',
  'label.emailAddressRequired': 'Email address is required.',
  'label.emailAddressChangeRequired': 'Email address has to be changed.',
  'label.emailAddressInvalid': 'Invalid email address.',
  'label.emailNotification': 'Email notification of the report',
  'label.emailNotifications': 'Email notifications',
  'label.emailNotificationsLong': 'Notify contract contact person of added manage-right by email.',
  'label.emailRequiredHint': 'What\'s the email for?',
  'label.employed': 'Employed',
  'label.employee': 'Employee',
  'label.employeeDeclarations': 'Report on employee details',
  'label.employeeInfo': 'Person info',
  'label.employeeReport': 'Person report',
  'label.employeeReportInfo': 'Creates a report that lists all employees or visitors that match the given criteria. Settings for AVI-report -checkbox chooses automatically the right settings for the AVI-report.',
  'label.employeeReportPlain': 'Person report',
  'label.employeeReportShort': 'Person',
  'label.employees': 'Employees',
  'label.employeeSearch': 'Search employee',
  'label.employeesWithIncompleteInformation': '<strong>{0}</strong> employees are missing some information.',
  'label.employeesWithNoPermits': 'Employees without access permits',
  'label.employeeWithIncompleteInformation': '<strong>{0}</strong> is missing some information.',
  'label.employeeWithInvalidTaxNumber': 'Person <strong>{0}</strong> has an invalid tax number.',
  'label.employer': 'Employer',
  'label.employerRegister': 'Employer Register',
  'label.employerRegisterLegend': 'An account of whether the enterprise is entered in the Employer Register',
  'label.emptyOrientationFormLink': 'Empty orientation form (PDF)',
  'label.endAction': 'Finish',
  'label.endDateAbbrev': 'Ends',
  'label.ended.alt': 'Ended',
  'label.ended': 'Ended',
  'label.endedPlural': 'Ended',
  'label.endMeasurement': 'End measurement',
  'label.ends': 'Ends',
  'label.endTask': 'End <strong>{0}</strong>',
  'label.enterSickLeaveReason': 'Enter reason for sick leave',
  'label.enterUsernameFirst': 'Enter username first',
  'label.entrepreneur': 'Enterpreneur',
  'label.error': 'Error',
  'label.ersInvoiceNumber': 'ERS Invoice number',
  'label.estimatedValue': 'Estimated value',
  'label.estimatedWeeklyHours': 'Weekly hour estimate',
  'label.estimatedWorkingPeriod': 'Estimated working duration',
  'label.evaluate': 'Evaluate',
  'label.evaluateLabor': 'Labor evaluation',
  'label.evaluateMaterials': 'Material evaluation',
  'label.evaluation': 'Evaluation',
  'label.evaluationAverage': 'Evaluation average',
  'label.evaluationNotes': 'Evaluation notes',
  'label.evaluationReport': 'Evaluation report',
  'label.evaluationReportShort': 'Evaluation',
  'label.evaluations': 'Evaluations',
  'label.evaluationsReport': 'Evaluation',
  'label.excelReportSiteInfo.contactPerson': 'Contact person:',
  'label.excelReportSiteInfo.developer': 'Developer:',
  'label.excelReportSiteInfo.email': 'Email:',
  'label.excelReportSiteInfo.mainContractor': 'Main contractor:',
  'label.excelReportSiteInfo.siteAddress': 'Site address:',
  'label.excelReportSiteInfo.siteForeman': 'Zeroni:',
  'label.excelReportSiteInfo.sitePeriod': 'Begins/Ends:',
  'label.excelReportSiteInfo.telephone': 'Telephone:',
  'label.execelReportDoesNotInclude': 'Not included in the Excel report',
  'label.exempted': 'Exempted',
  'label.expat': 'Posted worker',
  'label.expatCompany': 'Employer',
  'label.expatRepresentative': 'Representative in Finland for this expatriate',
  'label.expatRepresentativeMany': 'Representative in Finland for expatriates',
  'label.expatRepresentativeTooltip': 'According to the Expatriate Law, a company without a business unit in Finland should have a representative company when sending employees to Finland',
  'label.expats': 'Posted workers',
  'label.expatSocialSecurityDoc': 'Social security certificate (e.g. A1)',
  'label.expatSocialSecurityDocExpirationDate': 'Social security certificate expires',
  'label.expatSocialSecurityDocHelp': 'Make sure that expatriates have a Certificate A1 or some other equivalent document (formerly E101).',
  'label.expatSocialSecurityDocShort': 'Social security',
  'label.expatTooltip': 'Employer official representative in Finland',
  'label.expenseReimbursment': 'Reimburse expenses',
  'label.expertise': 'Expertise',
  'label.expired': 'Expired',
  'label.expiredOn': 'Expired on {0}',
  'label.expires': 'Expires',
  'label.expiringOn': 'Will expire on {0}',
  'label.exportToExcel': 'Export to Excel',
  'label.failed': 'Failed',
  'label.failedToFetchValttiCardImage': 'Valtti Card image failed to load. Unfortunately, without the photo, the card cannot be used in place of an site card. Please try again shortly. ',
  'label.failedToFetchValttiCardImageDueToCardBeingClosed': 'Valtti Card image failed to load because card is no longer active. Unfortunately, without the photo, the card cannot be used in place of an site card. Please try again shortly. ',
  'label.failedToLoadPreviousValttiCardImage': 'Image failed to load from Zeroni. Please upload an image.',
  'label.failedVisyPermitIntegrations': 'A problem exists for <strong>{0}</strong> permits with sending permits to the VisyGate. Sending has ended with an error for <strong>{1}</strong> permits.',
  'label.failedVisyPermitIntegrationStatus.FAILED': 'Error',
  'label.failedVisyPermitIntegrationStatus.RETRYING': 'Processing',
  'label.files.removed': 'The files have been removed.',
  'label.files': 'Files',
  'label.filter.showSubContractors': 'Show also subcontractor\'s employees',
  'label.filter.visitors': 'Visitors',
  'label.findExistingPersonWithIdentifierCallFailed': 'Failed to verify identifier. Submit disabled.',
  'label.findPersonInformationFailed': 'Failed to verify identifier from the registry.',
  'label.findPersonInformationNotFound': 'Failed to find person details from the registry.',
  'label.findingNameSuggestion': 'Finding name...',
  'label.finished': 'Finished',
  'label.finnishBusinessId': 'Finn. business id',
  'label.firstName': 'First name',
  'label.firstNames': 'First names',
  'label.fix': 'Fix',
  'label.foreignBusinessId': 'Foreign reg. num.',
  'label.foreignBusinessIdLabel': 'Foreign business id',
  'label.foreignerRepresentative': 'Representative for foreigners',
  'label.foreignSsn': 'Foreign SSN',
  'label.foreman': 'Foreman',
  'label.foremanContactInfo': 'Foreman contact information',
  'label.foremen': 'Foremen',
  'label.foremenOnly': 'Show foremen only',
  'label.found': 'found',
  'label.freeEvaluation': 'Evaluation',
  'label.fridayShort': 'fr',
  'label.fromSiteStartToEnd': 'From start to end of site',
  'label.furtherInformation': 'Other information',
  'label.furtherInformation2': 'More information',
  'label.gate': 'Gate',
  'label.gateIntegration': 'Gate',
  'label.gateType': 'Gate type',
  'label.general': 'General',
  'label.generalRating': 'Rating',
  'label.generalSettings': 'General settings',
  'label.genericBusinessId': 'Business id',
  'label.geolocationFailed': 'Could not get your location. A notice will be added to your check-ins.',
  'label.getCheckInsOuts': 'Get check-ins',
  'label.getDataFromValtti': 'Read information from Valtti card',
  'label.getting': 'Loading...',
  'label.giveBusinessId': 'Give business id first',
  'label.goBack': 'Back',
  'label.gotIt': 'Ok',
  'label.goToLiabilityDocuments': 'Go to liability documents',
  'label.grade': 'Grade',
  'label.groupByContractor': 'Group by contractor',
  'label.handle': 'Process',
  'label.handled': 'Handled',
  'label.handlingFailed': 'Handling failed',
  'label.healthCard': 'Health card',
  'label.help': 'Help',
  'label.hereIsListedOnlyTrustedPartners': 'This notification comes only from companies belonging to Luotettava kumppani -program.',
  'label.hide': 'Hide',
  'label.hideAll': 'Hide all',
  'label.hideEnded': 'Hide ended',
  'label.hidePreviousCLReports': 'Hide previous contractor liability reports',
  'label.hideSiteMainContracts': 'Hide main contracts',
  'label.hire': 'Hired',
  'label.homeCountry': 'Home country',
  'label.hotWorkForm': 'Form for hot work',
  'label.hotWorkPermitApproverSignature': 'Approver\'s signature',
  'label.hourBank': 'Hour bank',
  'label.hourBankReport.heading': 'Hour Bank Report',
  'label.hourBankReport.info': 'Shows working hour bank status of each employee: stored, used, and available hours. Hours are collected from all contracts where contractor\'s role is to approve hours.',
  'label.hours': 'Hours',
  'label.hoursAbbreviation': 'h',
  'label.id': 'Id',
  'label.idCard': 'Id card',
  'label.idCardNumber': 'Id car number',
  'label.idConfirmation': 'Id verification',
  'label.identifierNotFoundFromRegistry': 'Could not find given identifier from the registry.',
  'label.identifierNotFoundFromSSG': 'Could not find given SSG Card number from the SSG registry.',
  'label.identifiers': 'Identifiers',
  'label.idNumber': 'Id number',
  'label.iKnowWhatImDoing': 'I understand and know what I\'m doing',
  'label.in': 'In',
  'label.inadequate': 'Inadequate',
  'label.includeInvoiceHoursAddedByEmployees': 'Show also incomplete invoice hours added by employees.',
  'label.includeRemovedPermits': 'Show removed',
  'label.inEnglish': 'In English',
  'label.inFinnish': 'In Finnish',
  'label.inOut': 'In+out',
  'label.instructorSignature': 'Supervisor\'s signature',
  'label.interruptContract': 'Interrupt',
  'label.interruptContractHeading': 'Interrupt contract {0}',
  'label.interrupted': 'Interrupted',
  'label.introduce': 'Confirm orientation',
  'label.introduction': 'Confirmation of orientation',
  'label.introductionDate': 'Confirmation date',
  'label.introductionFormLabel': 'Orientation form',
  'label.introductionFormLink': 'Orientation form (PDF)',
  'label.introductionInstructor': 'Supervisor',
  'label.introductionMaterial': 'Orientation material',
  'label.introductionResponsibility': 'As a adder of the site, you assure that the persons have confirmed the orientation.',
  'label.introductionTopics': 'Topics covered during orientation',
  'label.introductionTopicsAvailable': 'Default orientation topics',
  'label.introductionWorker': 'Worker',
  'label.introSettings': 'Orientation settings',
  'label.inUse': 'In use',
  'label.invalid': 'Invalid',
  'label.invalidAddress': 'Site address is invalid. Valid address is required for tax reports to be sent.',
  'label.invalidate': 'Invalidate',
  'label.invalidateContractsWithExpiredDocs': 'Contracts with expired contractor obligation documents are marked as inadequate',
  'label.invalidateUser': 'Invalidate user',
  'label.invalidating': 'Invalidating...',
  'label.invalidContractorLiabilityReport': 'Report',
  'label.invalidContracts': 'All contracts are not valid.',
  'label.invalidOrientedPermit': 'Employee <strong>{0}</strong> missing required competences',
  'label.invalidOrientedPermits': '<strong>{0}</strong> persons are missing required competences',
  'label.invalidPermit': 'Employee\'s <strong>{0}</strong> permit missing required information',
  'label.invalidPermits': '<strong>{0} access permits</strong> are missing required information',
  'label.invalidTaxNumber': 'Invalid tax number',
  'label.invoiceHours': 'Invoice hours',
  'label.invoiceHoursNotUsed': 'Invoice hours not used',
  'label.invoicehoursReport.heading': 'Invoice hours report',
  'label.invoicehoursReport.info': 'Shows invoice hours for every day in the given time span for the given site.',
  'label.invoiceHoursReport.PersonFilter': 'Search person (type min 3 characters)',
  'label.invoiceHoursReport.roleFilter': 'Employee role',
  'label.invoiceHoursReport': 'Invoice hours',
  'label.invoiceHoursWaitingForApproval': 'Invoice hours waiting for approval.',
  'label.invoiceHoursWaitingForApprovalManySites': 'Invoice hours waiting for approval in <strong>{0}</strong> sites',
  'label.invoiceHoursWaitingForApprovalOneSite': 'Site <strong>{0}</strong> has invoice hours waiting for approval.',
  'label.isForeman': 'Person works as a foreman on the site',
  'label.issued': 'Issued',
  'label.jobName': 'Job title',
  'label.jobNumber': 'Job number',
  'label.jobNumberList': 'Job number list',
  'label.jobNumbers': 'Job numbers',
  'label.jobNumberSearch': 'Search job number',
  'label.jobTitleAddOption': 'Job titles',
  'label.jobTitleList': 'Job title list',
  'label.jobTitles': 'Job titles',
  'label.known': 'Known',
  'label.labor': 'Labor',
  'label.laborAgreement': 'Labor agreement mandatory',
  'label.langAndNationality': 'Language and nationality',
  'label.languages': 'Languages',
  'label.lastCheckIn': 'Latest check in',
  'label.lastCheckOut': 'Lastest check out',
  'label.lastContractEnded': '(last contract ended {0})',
  'label.lastMonth': 'Last month',
  'label.lastName': 'Last name',
  'label.lastWeek': 'Last week',
  'label.latest': 'Latest',
  'label.liabilityDocs': 'Documents for contractor\'s obligations',
  'label.liabilityDocsAwaitingApproval': 'Documents to report on contractor\'s obligations awaiting approval.',
  'label.liabilityDocsRejected': 'Documents to report on contractor\'s obligations rejected.',
  'label.liabilityInsuranceEnded': 'insurance ended',
  'label.listPersonsWihtoutCompetence': 'List persons who are <em>missing</em> the selected competence',
  'label.listToday': 'Today:',
  'label.listTomorrow': 'Tomorrow:',
  'label.loading': 'Loading...',
  'label.loadingMoreResults': 'Loading more results...',
  'label.loadMoreResults': 'Load more results',
  'label.location': 'Location',
  'label.locationOnSite': 'Location on site',
  'label.locationShort': 'Loc.',
  'label.loginToggleEmail': 'Email address',
  'label.logout': 'Logout',
  'label.mainContract': 'Main contract',
  'label.mainContractor': 'Main contractor',
  'label.mainContractorAndDeveloperShort': 'Dev. & maincont.',
  'label.mainOrSideContract': 'Main or side contract',
  'label.manage': 'Management',
  'label.management': 'Management',
  'label.manpowerArea': 'Manpower area',
  'label.markOrientationCompleted': 'All people have completed the orientation',
  'label.materials': 'Materials',
  'label.materialsAddOption': 'Materials',
  'label.maxFileSize': 'Maximum upload file size: {0}MB',
  'label.message': 'Message',
  'label.messaging': 'Messaging',
  'label.missing': 'Missing',
  'label.missingCardId': 'Card id missing',
  'label.missingData': 'Missing data',
  'label.missingEmployeeInfo': 'Contact information',
  'label.missingTagOnlyUserCompany': 'Only employees without {0} tag or Valtti card',
  'label.mobile.home': 'Home',
  'label.modifiedFromOriginal': 'Modified from original',
  'label.mondayShort': 'mo',
  'label.month': 'Month',
  'label.move': 'Move',
  'label.moveContract': 'Move contract',
  'label.moveContractHeading': 'Move contract?',
  'label.moving': 'Moving...',
  'label.multipleEmployeesWithInvalidTaxNumber': 'Invalid tax number for <strong>{0}</strong> persons.',
  'label.name': 'Name',
  'label.nameInEnglish': 'Name in English',
  'label.nameInFinnish': 'Name in Finnish',
  'label.nameNotFound': 'Not found',
  'label.narrowPrintView': 'Narrow print view',
  'label.nationality': 'Nationality',
  'label.nEvaluations': '{0} evaluations',
  'label.new': 'New',
  'label.newAccessArea': 'New access area',
  'label.newAccessPermit': 'New access permit',
  'label.newcomerTraining': 'Newcomer training',
  'label.newcomerTrainingDone': 'Newcomer training completed',
  'label.newCompany': 'New company',
  'label.newCompetence': 'New competence',
  'label.newContract': 'New contract',
  'label.newContractor': 'New contractor',
  'label.newCriterion': 'New criterion',
  'label.newDevice': 'New device',
  'label.newExpertise': 'New expertise',
  'label.newMainOrSideContract': 'New main or side contract',
  'label.newMainOrSideContractStrong': 'New <strong>main</strong> or <strong>side</strong> contract',
  'label.newPayment': 'New payment',
  'label.newPerson': 'New person',
  'label.newSideContract': 'New side contract',
  'label.newSideContractStrong': 'New <strong>side</strong> contract',
  'label.newSite': 'New site',
  'label.newSiteAccessArea': 'New site\'s access area',
  'label.newSubContract': 'New subcontract',
  'label.newSubContractStrong': 'New <strong>subcontract</strong>',
  'label.newUser': 'New user',
  'label.newUserGroup': 'New user role',
  'label.nextDay': 'Next day',
  'label.nickName': 'Nick name',
  'label.no': 'No',
  'label.noAccessAreas': 'No access areas have been added.',
  'label.noActiveContracts': 'Contractor doesn\'t have any active contracts.',
  'label.noAffectToTaxReports': 'Estimated value doesn\'t affect to sending of Tax administration reports',
  'label.noCheckInsOuts': 'No check ins or outs',
  'label.noContractForNewPerson': 'Contractor doesn\'t have any contracts to which people could be added.',
  'label.noFiles': 'No files',
  'label.noNameSuggestion': 'Name not found - please give the name',
  'label.noPhoto': 'No photo',
  'label.noReportFoundForContractor': 'No report found for contractor',
  'label.noSafetyQuarters': 'There aren\'t any safety quarters yet',
  'label.noTagsAtAll': 'No tags at all',
  'label.notAvailable': 'Not available',
  'label.notDefined': 'Not defined',
  'label.notes': 'Notes',
  'label.notHandled': 'Not handled',
  'label.noThanks': 'No, thank you',
  'label.notification': 'Notifications',
  'label.notificationInfo': 'The notifications are located on the dashboard and are visible to all contractors on the site.',
  'label.notificationSingular': 'Notification',
  'label.notKnown': 'Unknown',
  'label.notUsed': 'Not used',
  'label.noValttiTags': 'Don\'t include Valtti cards',
  'label.noVehiclePermits': 'Ei ajoneuvolupia.',
  'label.noVehiclePermitTypes': 'No vehicle permit types have been added.',
  'label.numberOfActiveEmployees': 'Active employees',
  'label.numberOfCompanies': 'Number of companies',
  'label.numberOfEmployees': 'Number of employees',
  'label.numberOfEmployeesDescription': 'Employees with an active access permit.',
  'label.ok': 'Ok',
  'label.okThanks': 'Got it, thanks!',
  'label.oldestFirst': 'Oldest first',
  'label.oneEvaluation': '1 evaluation',
  'label.online': 'Online',
  'label.onlyAllocatedToSiteTags': 'Only tags that are added by site',
  'label.onlyEmployeesPresentAtSite': 'Only present employees',
  'label.onlyExpats': 'Only posted workers',
  'label.onlyForeignEmployees': 'Only foreign employees',
  'label.onlyForeMen': 'Foremen only',
  'label.onlyOwnEmployees': 'Only own employees',
  'label.onlyOwnTags': 'Don\'t consider Valtti cards',
  'label.onlyOwnTagsInfo': 'Valtti cards are not considered as tags in the search',
  'label.onPaper': 'On paper',
  'label.onSites': 'On {0} sites',
  'label.open': 'Open',
  'label.openPersonSummary': 'Open person summary',
  'label.openReport': 'Open report',
  'label.operationFailed': 'Operation failed.',
  'label.operationRunning': 'Executing operation...',
  'label.optional': 'Optional',
  'label.or': 'or',
  'label.orCreateSideContract': 'or create a side contract',
  'label.orientation': 'Orientation',
  'label.orientationForm': 'Orientation form',
  'label.orientationFormLabel': 'Orientation form',
  'label.orientationFormLink': 'Orientation form (PDF)',
  'label.orientations': 'Orientations',
  'label.orSelectFromThesePreviousRepresentatives': 'Or select from these previous representatives',
  'label.orUseOtherIdCardType': 'or use another type of id card',
  'label.other': 'Other',
  'label.otherCompetences': 'Other competences',
  'label.otherDevice': 'Other device',
  'label.otherDocuments': 'Other documents',
  'label.otherInfo': 'Other information',
  'label.otherLanguages': 'Other languages',
  'label.otherPersonIdentifierGroup': 'Other identifying personal identifier',
  'label.otherRequiredInfo': 'Other required information',
  'label.out': 'Out',
  'label.outsideSite': 'You are outside the site area. A notice will be added to your check-in.',
  'label.ownEmployees': 'own employees',
  'label.page': 'Page',
  'label.participants': 'Participants',
  'label.passport': 'Passport',
  'label.passportNumber': 'Passport number',
  'label.password': 'Password',
  'label.paymentNote': 'Note',
  'label.paymentReference': 'Payment reference',
  'label.paymentReverseVat': 'reverse VAT',
  'label.payments': 'Payments',
  'label.paymentTypeInvoiced': 'Invoiced',
  'label.paymentTypePaid': 'Paid',
  'label.paymentTypeScheduled': 'Scheduled',
  'label.paymentVat': 'VAT',
  'label.pending': 'Pending',
  'label.pendingApproval': 'Pending approval',
  'label.pendingTagListUpdates': '<strong>{0}</strong> tag ids waiting to be updated to gate devices.',
  'label.pensionInsurance': 'Pension Insurance',
  'label.pensionInsuranceLegend': 'Certificates of pension insurances taken out and of pension insurance premiums paid, or an account that a payment agreement on outstanding pension insurance premiums has been made',
  'label.peopleCounts': 'Strength',
  'label.permission.notAllowedToAddExpertise': 'You\'re not permitted to add this training. Contact main contractor for more info.',
  'label.permitActiveDaily': 'Permit is valid daily at',
  'label.permitAwaitingIntroduction': '<strong>{0}</strong>\'s access permit is awaiting confirmation of orientation.',
  'label.permitForContract': 'The created permit will be attached to contract <strong>{0}</strong>',
  'label.permitlessEmployees': 'Show only employees, who don\'t have access permits',
  'label.permitlessEmployeesShort': 'Missing permits',
  'label.permits': 'Access permits',
  'label.permitsAwaitingIntroduction': '<strong>{0} access permits</strong> awaiting confirmation of orientation.',
  'label.permitsDesc': 'Show only last access permit for employee',
  'label.permitsShort': 'Permits',
  'label.person.missingIdentifier': 'No identifier set. Add an identifier for person in order to add them as an employee.',
  'label.person': 'Person',
  'label.personDetailsDoesntMatchWithRegistry': 'The provided details do not match the details in the registry where ',
  'label.personDetailsDoesntMatchWithSSG': 'The provided details do not match the details on the person’s SSG Access Card where ',
  'label.personDetailsNameDoesntMatch':
    '<span><strong>last name</strong> is <strong>{0}</strong> </span>' +
    '<span>and <strong>first name</strong> is <strong>{1}</strong></span>',
  'label.personDetailsBirthDateDoesntMatch': '<strong>date of birth</strong> is <strong>{0}</strong>',
  'label.personIdentifiers': 'Person identifiers',
  'label.personIdentifiersHelp': 'Minimum of one identifier is required. Usually tax number is sufficient. Requirements may change per site.',
  'label.personnelGate': 'Personnel gate',
  'label.persons': 'Persons',
  'label.personSearch': 'Search a person',
  'label.personSearchExample': 'E.g. name, tax number, email, telephone number.',
  'label.personsRole': 'Person\'s role',
  'label.phoneNumberRequiredHint': 'What\'s the number for?',
  'label.photo': 'Photo',
  'label.photos': 'Photos',
  'label.photoShort': 'Photo',
  'label.portDirection': 'Gate direction',
  'label.postcode': 'Post code',
  'label.postingDate': 'Posting date',
  'label.postOffice': 'City',
  'label.prefilledForm': 'Prefilled form',
  'label.prepayment': 'Prepayment Register',
  'label.prepaymentLegend': 'An account of whether the enterprise is entered in the Prepayment Register',
  'label.preventsOrientation': 'Orientation is not possible before requirements on courses and competences are met.',
  'label.previousCLReports': 'Other contractor liability reports',
  'label.previousDay': 'Previous day',
  'label.primaryLanguage': 'Language',
  'label.primaryLanguageInfo': 'Zeroni works with the selected language.',
  'label.print': 'Print',
  'label.printCard': 'Print card',
  'label.printCardA4': 'Print card(A4)',
  'label.printVehiclePermit': 'Tulosta ajoneuvolupa',
  'label.processDate': 'Process date',
  'label.processed': 'Processed',
  'label.processStatus': 'Status',
  'label.profession': 'Profession',
  'label.projectNumber': 'Project number',
  'label.quickOptions': 'Shortcuts',
  'label.quickTimeSpans': 'Instant dates',
  'label.readingRights': 'Read',
  'label.readMore': 'Read more',
  'label.ready': 'Ready',
  'label.reasonForRejection': 'Reason for rejection',
  'label.receptionCommitment': 'Reception commitment',
  'label.references': 'References',
  'label.region': 'Location',
  'label.regionExamples': 'E.g. Uusimaa, Pohjois-Savo',
  'label.regions': 'Locations',
  'label.reject': 'Reject',
  'label.rejectAccessPermitHeading': 'Invalidate the access permit {0}',
  'label.rejected': 'Rejected',
  'label.rejecting': 'Rejecting...',
  'label.remove': 'Remove',
  'label.removeContract': 'Remove contract',
  'label.removeContractHeading': 'Remove contract?',
  'label.removed': 'Removed',
  'label.removedTrainingAndCourses': 'Removed training and courses',
  'label.removeMeasurement': 'Remove measurement',
  'label.removeRow': 'Remove row',
  'label.removeSelected': 'Remove selected',
  'label.removeSelections': 'Remove selections',
  'label.removeSiteAccessArea': 'Remove sites access area',
  'label.removeVehiclePermitType': 'Remove site\'s vehicle permit',
  'label.removing': 'Removing...',
  'label.repairBy': 'For repair',
  'label.repaired': 'Repaired',
  'label.replayToApproval': 'Approve/Reject',
  'label.report': 'Report',
  'label.reportHazards': 'Report hazards',
  'label.reportInjuries': 'Report injuries',
  'label.reportNearMisses': 'Report near misses',
  'label.reports': 'Reports',
  'label.reportsToTaxAdmin': 'Reports to Tax Administration',
  'label.reportTax': 'Report to Tax Administration',
  'label.ReportWorkHourBalance.alert': 'No tracking work hours on sites. If you are interested in tracking working hours, please contact support@zeroni.fi',
  'label.ReportWorkHourBalanceSite.alert': 'No tracking work hours for selected site. If you are interested in tracking working hours, please contact support@zeroni.fi',
  'label.required': 'Required',
  'label.requiredReportsToTaxAdmin': 'Required reports to Tax Administration',
  'label.residencePermit.info': 'A worker outside of EU needs a residence permit that allows working. <a href="https://migri.fi/en/working-in-finland" rel="noopener" target="_blank">Read more <span class="far fa-external-link"></span></a> (migri.fi)',
  'label.residencePermit': 'Employee\'s residence permit',
  'label.residencePermitContinuationDueDate': 'Continuation due date',
  'label.residencePermitContinuationDueDatePassed': '<strong>Residence permit</strong> continuation needs to be checked.',
  'label.residencePermitContinuationHelp': 'Choose a date on which the residence permit continuation should be granted. There will be a notification on the dashboard about the residence permit after the chosen date.',
  'label.residencePermitContinuationReminder': 'Update the status of the new residence permit or change the continuation due date. Don\'t forget to add the new documents.',
  'label.residencePermitContinuationRequested': 'Continuation requested',
  'label.residencePermitExpired': 'Residence permit expired',
  'label.residencePermitExpires': 'Residence permit expires',
  'label.residencePermitExpiring': 'Residence permit expired about to expire',
  'label.residencePermitNumber': 'Residence permit number',
  'label.residencePermitsExpiredOrExpiring': '<strong>{0} Residence permits</strong> have expired or are about to expire within 14 days.',
  'label.residencePermitType': 'Residence permit type',
  'label.restArea': 'Rest area',
  'label.restAreaNeeded': 'Rest area needed',
  'label.restore': 'Restore',
  'label.restoring': 'Restoring...',
  'label.result': 'result',
  'label.result2': 'results',
  'label.resultAccessPermit': 'access permit',
  'label.resultAccessPermits': 'access permits',
  'label.resultCompanies': 'companies',
  'label.resultCompany': 'company',
  'label.resultContract': 'contract',
  'label.resultContractor': 'contractor',
  'label.resultContractors': 'contractors',
  'label.resultContracts': 'contracts',
  'label.resultDevice': 'device',
  'label.resultDevices': 'devices',
  'label.resultEmployee': 'employee',
  'label.resultEmployees': 'employees',
  'label.resultOwn': 'own',
  'label.resultPerson': 'person',
  'label.resultPersons': 'persons',
  'label.resultsByContractor': 'contractor\'s',
  'label.resultSite': 'site',
  'label.resultSites': 'sites',
  'label.resultUser': 'user',
  'label.resultUserGroup': 'user group',
  'label.resultUserGroups': 'user groups',
  'label.resultUsers': 'users',
  'label.resultVisitor': 'visitor',
  'label.resultVisitors': 'visitors',
  'label.retryFailedAccessControlIntegrations': 'Restart transfer',
  'label.return': 'Return',
  'label.returnId': 'Return id',
  'label.returning': 'Returning...',
  'label.reverseVat': 'Reversed VAT',
  'label.role': 'Role',
  'label.roleConsultant': 'Consultant',
  'label.roleContractor': 'Contractor',
  'label.roleSecurity': 'Security',
  'label.roofHotWorkForm': 'Form for hot work on roof',
  'label.rotatePhoto': 'Rotate',
  'label.safety': 'Safety',
  'label.safetyContactAt': 'Safety contact at {0}',
  'label.safetyIndex': 'Index',
  'label.safetyMeasurementInfo': 'Site\'s safety measurement types are defined from site settings. Once safety measurement type is selected, one can select from measurement section list the relevant measurement section for the observation.',
  'label.safetyMeasurer': 'Measurer',
  'label.safetyReportContactsInfo1': 'Report safety deviations to these additional email addresses.',
  'label.safetyReportContactsInfo2': 'The site foreman and the safety contact person of the responsible contractor are notified automatically. In case of injuries and near miss situations the safety contact persons of the main contractor and the developer are also notified.',
  'label.safetyReportEmails': 'Email recipients',
  'label.safetyReports': 'TR/MVR-reports',
  'label.safetyReportShort': 'Safety',
  'label.sameAsCompanyContact': 'Same as the company contact person',
  'label.saturdayShort': 'sa',
  'label.save': 'Save',
  'label.saveAgain': 'Save again',
  'label.saveContractorObligationsDocuments': 'Save contractor obligation documents',
  'label.saved': 'Saved',
  'label.saveEvaluation': 'Save evaluation',
  'label.saveFailed': 'Save failed!',
  'label.saveIntroductionForm': 'Save orientation form',
  'label.saveModifications': 'Save modifications',
  'label.saving': 'Saving...',
  'label.scannedCard': 'Scanned card',
  'label.scannedCompetence': 'Competence verification (scanned card etc.)',
  'label.scannedIdCard': 'Photo of id card',
  'label.scannedIntroductionForm': 'Photo of the orientation form',
  'label.scannedInvoice': 'Scanned invoice',
  'label.scannedInvoices': 'Scanned invoices',
  'label.scannedSiteIdCard': 'Photo of company\'s site id card',
  'label.scannedVisa': 'Photo of visa',
  'label.searchByName': 'Search by name',
  'label.searchCollectiveLaborAgreements': 'Search collective labor agreements',
  'label.searchCompanies': 'Search companies',
  'label.searchCompetences': 'Search competences',
  'label.searchContracts': 'Search contracts',
  'label.searchCountries': 'Search countries',
  'label.searchDevices': 'Search devices',
  'label.searchEmployeeBycompetence.noCompetence': 'Persons, without selected competence',
  'label.searchEmployeesBySite': 'Search employees by site',
  'label.searchLanguages': 'Search languages',
  'label.searchNationalities': 'Search nationalities',
  'label.searchOnlyActiveEmployees': 'Active access permit',
  'label.searchOrSelectBuyer': 'Buyer',
  'label.searchOrSelectRole': 'Role',
  'label.searchPersons': 'Search persons',
  'label.searchSites': 'Search sites',
  'label.securityClearance': 'Security clearance',
  'label.securityClearanceDate': 'Date of security clearance',
  'label.securityClearanceDateSentToPolice': 'Date of security clearance sent to police',
  'label.securityClearanceFormEN': 'Fill-in form: Security clearance ENG (PDF)',
  'label.securityClearanceFormFI': 'Fill-in form: Security clearance FIN (PDF)',
  'label.securityClearanceTooltip': 'Entered by Fennovoima security officer when security clearance has been admitted. Clearance is required for attaining an access permit to the site.',
  'label.securityContact': 'Security contact person in company {0}',
  'label.securityContactPerson': 'Contact person for company security',
  'label.seeSiteSettings': 'See site\'s settings',
  'label.select': 'Select',
  'label.selectAccessArea': 'Select access area',
  'label.selectAll': 'Select all',
  'label.selectAllActive': 'Select all active',
  'label.selectBuyer': 'Select buyer',
  'label.selectCollectiveLaborAgreement': 'Select a collective labor agreement',
  'label.selectCompany': 'Select a company',
  'label.selectContract': 'Select contract',
  'label.selectContractingType': 'Select a contracting type',
  'label.selectContractPartner': 'Select a partner for the contract',
  'label.selectCountry': 'Select a country',
  'label.selectCurrentCountry': 'Select normal country of employement',
  'label.selectCurrentSite': 'Select a site to work on',
  'label.selectDevice': 'Select a device',
  'label.selected': 'Selected',
  'label.selectEmployee': 'Select employee',
  'label.selectEndDate': 'Select end date',
  'label.selectFiles': 'Select files',
  'label.selectHomeCountry': 'Select a home country',
  'label.selectIdType': 'Select a type',
  'label.selectManpowerArea': 'Select manpower area',
  'label.selectNationality': 'Select nationality',
  'label.selectParentContract': 'Please select a parent contract',
  'label.selectPerson': 'Select a person',
  'label.selectPhoto': 'Select a photo',
  'label.selectPrimaryLanguage': 'Select language',
  'label.selectProfession': 'Select profession',
  'label.selectRole': 'Select a role',
  'label.selectSite': 'Select a site',
  'label.selectSiteFirst': 'Select a site first',
  'label.selectSiteForeman': 'Select a site foreman',
  'label.selectSiteOrForeman': 'Select a site or search for all foremen',
  'label.selectTaxingCountry': 'Select a taxing country',
  'label.send': 'Send',
  'label.sendAsPending': 'Send as pending',
  'label.sendCopyToSender': 'Send copy to your email',
  'label.sendEmail': 'Send email',
  'label.sendEmailToForemen': 'Also send an email to all the foremen in the company',
  'label.sendFile': 'Send file',
  'label.sending': 'Sending...',
  'label.sendingFailed': 'Sending failed!',
  'label.sendPasswordInSMS': 'Send password',
  'label.sent': 'Sent',
  'label.serviceCalls': 'Service Calls',
  'label.serviceCallsReportInfo': 'Lists all contractors\' service calls at the site to which the logged in company has visibility.',
  'label.seSsn': 'Swedish personal identification number',
  'label.setPermitPeriodOfValidityAutomatically': 'Default start and end dates are taken from the contract',
  'label.settings': 'Settings',
  'label.shoeSize': 'Shoe size',
  'label.show': 'Show',
  'label.showAll': 'Show all',
  'label.showAllIdentifiers': 'Show all person identifiers',
  'label.showAllSiteCards': 'Show all site cards',
  'label.showAllSites': 'Show details for all sites',
  'label.showAllTags': 'Show all tags',
  'label.showAlsoCompleted': 'Show also completed',
  'label.showAlsoValttiCards': 'Show also Valtti cards',
  'label.showCheckIns': 'Show check ins',
  'label.showCompleteDetails': 'Show complete details',
  'label.showContractEvaluation': 'Chow contract evaluation',
  'label.showContracts': 'Show contracts',
  'label.showEmptyDays': 'Show empty days',
  'label.showEvaluation': 'Show evaluation',
  'label.showEvaluations': 'Show evaluations',
  'label.showForActiveSites': 'Show data for active sites',
  'label.showLess': 'Show less',
  'label.showMore': 'Show more',
  'label.showOnlyActive': 'Show only active',
  'label.showOnlyActiveEmployees': 'Show competences of active employees only',
  'label.showOnlyActiveInTimePeriod': 'Show only active',
  'label.showOnlyActiveLogbooks': 'Hide expired',
  'label.showOnlyAllocatedToSite': 'Show only tags added to site',
  'label.showOnlyApprovedInvoiceHours': 'Show only approved',
  'label.showOnlyFirstAndLastCheckInOfTheDay': 'Show only first check-in and last check-out of the day',
  'label.showOnlyForeMen': 'Only foremen',
  'label.showOnlyGates': 'Show only gates',
  'label.showOnlyUnUsedSiteCards': 'Show only site cards that are not in use',
  'label.showOnlyUnUsedTags': 'Show only tags that are not in use',
  'label.showOnlyUsedSiteCards': 'Show only site cards that are in use',
  'label.showOnlyUsedTags': 'Show only tags that are in use',
  'label.showOnlyVacant': 'Show only vacant',
  'label.showPayments': 'Show payments',
  'label.showPreviousCLReports': 'Show previous contractor liability reports',
  'label.showReport': 'Show report',
  'label.showSiteMainContracts': 'Show main contracts',
  'label.showTargetCompAndOtherCompetencesInfo': 'Show {0} and other competences',
  'label.showWeeklyHours': 'Show weekly hours',
  'label.sideContract': 'Side contract',
  'label.sideContractor': 'Side contractor',
  'label.sideContractors': 'Side contractors',
  'label.sign': 'Sign',
  'label.signature': 'Signature',
  'label.signatureClarification': 'Signature clarification',
  'label.signedForm': 'Signed form',
  'label.site': 'Site',
  'label.siteAccessAreaIdInfo': 'The id can be used to identify the accessa area in external access control systems.',
  'label.siteAccessAreaRemovalConfirmation': 'Are you sure you want to remove sites access area <b>{0}</b>?',
  'label.siteAccessAreas': 'Site\'s access areas',
  'label.siteAccessAreasUpdated': 'Access areas saved!',
  'label.siteAdditionalSettings': 'Additional settings',
  'label.siteAddress': 'Site address',
  'label.siteCardReport.all': 'all',
  'label.siteCardReport.allTagsByCurrentCompany': 'Show all tags given by {0}',
  'label.siteCardReport.businessId': 'Business ID',
  'label.siteCardReport.chooseContractorPlaceholder': 'Choose contractor (optional)',
  'label.siteCardReport.closed': 'Closed',
  'label.siteCardReport.contactPerson': 'Contact person',
  'label.siteCardReport.contract': 'Contract:',
  'label.siteCardReport.contractor': 'Contractor',
  'label.siteCardReport.email': 'Email',
  'label.siteCardReport.employer': 'Employer',
  'label.siteCardReport.endDate': 'End date',
  'label.siteCardReport.hasEnded': 'Ended',
  'label.siteCardReport.info': 'Lists all site cards by the given criteria.',
  'label.siteCardReport.missing': 'missing',
  'label.siteCardReport.onlyClosed': 'only site cards that are not in use',
  'label.siteCardReport.onlyNotClosed': 'only site cards that are in use',
  'label.siteCardReport.person': 'Person',
  'label.siteCardReport.pickUpDate': 'Pick-up date',
  'label.siteCardReport.printed': 'Printed',
  'label.siteCardReport.site': 'Site:',
  'label.siteCardReport.startDate': 'Start date',
  'label.siteCardReport.state': 'State',
  'label.siteCardReport.tag': 'ID',
  'label.siteCardReport.tags.info': '<b>Show all tags</b> shows all tags whether they are in use or not. <b>Show only tags that are not in use</b> shows all tags that are returned, damaged or gone missing.',
  'label.siteCardReport.tags': 'Site cards:',
  'label.siteCardReport.taxNumber': 'Tax number',
  'label.siteCardReport.telephone': 'Telephone',
  'label.siteCardReport.title': 'Sitecard report',
  'label.siteCardReport.waitingForPhotoShooting': 'Waiting for photoshooting',
  'label.siteCardReport.waitingForPrinting': 'Waiting for printing',
  'label.siteCards': 'Site cards',
  'label.siteCardsDesc': 'Show only site cards that are not in use',
  'label.siteCreated': 'Site created',
  'label.siteDeveloper': 'Buyer',
  'label.siteDevices': 'Devices on site <strong>{0}</strong>',
  'label.siteEnds': 'Ends',
  'label.siteForeman.missingEmail': 'Email address is missing',
  'label.siteForeman.missingPhoneNumber': 'Phone number is missing',
  'label.siteForeman.missingPhoneNumberAndEmail': 'Phone number and email address are missing',
  'label.siteForeman': 'Site foreman',
  'label.siteForemanContactInfo': 'Site forman contact information',
  'label.siteInformation': 'Site information',
  'label.siteInvalidAddress': 'Address for site <strong>{0}</strong> is invalid. Valid address is required for tax reports to be sent.',
  'label.siteKey': 'Site key',
  'label.siteMainContracts': 'Main contracts for the site',
  'label.siteName': 'Site name',
  'label.siteNumber': 'Site number',
  'label.siteOrContract': 'Site / contract',
  'label.siteReport': 'Site report',
  'label.siteReportHeading': 'Site report',
  'label.siteReportInfo': 'Shows information about sites and their settings',
  'label.siteReportInfo2': 'The report includes only sites where the company is a developer and/or main contractor. Sites need to be active at least one day in the given time period to be included in the report.',
  'label.siteReportsAvailableWithinSite': 'Site specific reports available in sites information.',
  'label.siteReportShort': 'Sites',
  'label.siteRequirementSettings': 'Requirements',
  'label.sites': 'Sites',
  'label.siteSearch': 'Site search',
  'label.sitesWithAnonymousReports': '<strong>{0}</strong> sites have anonymous safety reports.',
  'label.sitesWithIncompleteEmployees': '<strong>{0}</strong> sites have employees with missing information.',
  'label.sitesWithInvalidContracts': 'Invalid contracts on <strong>{0}</strong> sites.',
  'label.sitesWithoutAddress': '<strong>{0}</strong> sites have invalid address. Valid address is required for tax reports to be sent.',
  'label.sitesWithoutLocation': 'Location is not defined on <strong>{0}</strong> sites.',
  'label.sitesWithPendingTagListUpdates': '<strong>{0}</strong> sites have tag ids waiting to be updated to gate devices.',
  'label.sitesWithUnresolvedCheckIns': 'Unknown check ins on <strong>{0}</strong> sites.',
  'label.siteWithAnonymousReports': 'Site <strong>{0}</strong> has anonymous safety reports.',
  'label.siteWithInvalidContract': 'Site <strong>{0}</strong> has invalid contracts.',
  'label.siteWithoutLocation': 'Site\'\'s <strong>{0}</strong> location is not defined.',
  'label.siteWithPendingTagListUpdates': 'Site <strong>{0}</strong> has <strong>{1}</strong> tag ids waiting to be updated to gate devices.',
  'label.siteWithUnresolvedCheckIns': 'Site <strong>{0}</strong> has unknown check ins.',
  'label.sms': 'Text message (SMS)',
  'label.smsCheckIn': 'SMS check in',
  'label.socialRoomsAndVehicles': 'Rest areas and vehicles',
  'label.specialNeeds': 'Special requirements',
  'label.splitFromOriginal': 'Split from original',
  'label.ssgAccess': 'SSG Access',
  'label.ssn': 'Finnish personal identification number',
  'label.ssoUsername': 'SSO Username',
  'label.start_ends': 'Start - End',
  'label.startDateAbbrev': 'Starts',
  'label.starting': 'Starting',
  'label.startingFrom': 'Starting from {0}',
  'label.starts': 'Starts',
  'label.startTask': 'Start <strong>{0}</strong>',
  'label.status': 'Status',
  'label.streetAddress': 'Street address',
  'label.subcontract': 'Subcontract',
  'label.subcontractFromParent': 'Subcontract from contract {0}',
  'label.subcontracting': 'Subcontracting',
  'label.subcontractor': 'Subcontractor',
  'label.subcontractors': 'Subcontractors',
  'label.subcontracts.activeSubcontracts': 'Active subcontracts',
  'label.subcontracts': 'Subcontracts',
  'label.suggest': 'Suggest',
  'label.summary': 'Summary',
  'label.sundayShort': 'su',
  'label.superUserEyesOnly': 'Visible only for super users: ',
  'label.tagBelongsTo': 'Tag belongs to <strong>{0}</strong>.',
  'label.tagBelongsToWithCompany': 'Tag belongs to <strong>{0} / {1}</strong>.',
  'label.tagReport': 'Tag report',
  'label.tagReportShort': 'Tags',
  'label.tags.numberOfNew': '{0} new tags',
  'label.tags.oneNew': '1 new tag',
  'label.tags': 'Tags',
  'label.tagsDesc': 'Show only tags that are not in use',
  'label.targetContract': 'Kohdesopimus',
  'label.taxAdmin': 'Tax Administration',
  'label.taxCard': 'Tax card',
  'label.taxingCountry': 'Taxing country',
  'label.taxNumber': 'Tax number',
  'label.taxNumberShort': 'Tax num.',
  'label.taxPayments': 'Tax Payment',
  'label.taxPaymentsLegend': 'A certificate of tax payment or of tax debt, or an account that a payment plan has been made regarding tax debt',
  'label.taxReportsSentAutomatically': 'All required  tax administration reports are sent automatically.',
  'label.telephone': 'Telephone',
  'label.telephoneNumber': 'Telephone number',
  'label.telephoneNumberIsRequired': 'Telephone number is required.',
  'label.telephoneNumberInvalid': 'Telephone number is invalid.',
  'label.telephoneShort': 'Tel.',
  'label.telephoneShortNoDot': 'Tel',
  'label.tempSave': 'Save, don\'t send',
  'label.tes': 'Collective Agreement',
  'label.tesLegend': 'An account of the collective agreement or the principal terms of employment applicable to the work',
  'label.thisMonth': 'This month',
  'label.thisWeek': 'This week',
  'label.thursdayShort': 'th',
  'label.time': 'Time',
  'label.timeSpan': 'Time span',
  'label.timeTracking': 'Time tracking',
  'label.timeTrackingActive': 'Time tracking',
  'label.timezone': 'Time zone',
  'label.timezoneOfDevices': 'Time zone of checkindevices',
  'label.tinNumber': 'TIN number',
  'label.today': 'Today',
  'label.todayAndYesterday': 'Today and yesterday',
  'label.tomorrow': 'Tomorrow',
  'label.topic': 'Topic',
  'label.topics': 'Topics',
  'label.tot': 'Tot.',
  'label.total': 'Total',
  'label.totalHoursOfMainContractor': 'main contractor',
  'label.totalHoursOfSubContractors': 'sub contractors',
  'label.totalPeopleCount': 'Total strength',
  'label.totalShort': 'Sum',
  'label.totalSmall': 'total',
  'label.totalValue': 'Total value',
  'label.tradeRegister': 'Trade Register',
  'label.tradeRegisterLegend': 'An extract from the Trade Register',
  'label.trailer': 'Trailer',
  'label.trailerPermit': 'Trailer permit',
  'label.trainee': 'Trainee',
  'label.training': 'Training',
  'label.trainingAndCourses': 'Training and courses',
  'label.trainingAndCoursesName': 'Training/courses name',
  'label.trainingDescription': 'Training and courses available as competences',
  'label.trainingExpired': 'Training expired',
  'label.trainingExpiring': 'Training about to expire',
  'label.trainingSettings': 'Training settings',
  'label.trash.show': 'Show trash bin',
  'label.travelDocumentNumber': 'Travel document number',
  'label.travelExpenseClaims': 'Travel expense claims',
  'label.travelExpenseReport.heading': 'Travel expense report',
  'label.travelExpenseReport.info': 'Shows all travel expenses for the given site and time span.',
  'label.travelExpenseReportShort': 'Travel expense',
  'label.truck': 'Truck',
  'label.trustedPartner': 'Trusted partner',
  'label.trustedPartnerReport': 'Report on contractor obligations',
  'label.tryAgain': 'Try again',
  'label.tryAgainOrContactSupport': 'Please try again. If the problem persists, contact support (support@zeroni.fi).',
  'label.tts': 'Work Safety Plans',
  'label.tuesdayShort': 'tu',
  'label.työaika': 'Invoice hours',
  'label.type': 'Type',
  'label.unallocatedPayments': 'Unallocated payments',
  'label.unclear': 'Unclear',
  'label.unconfirmed': 'Unconfirmed',
  'label.undefinedLocation': 'Location is not defined',
  'label.underagePersonOnSite': '<strong>Underage</strong> person on site',
  'label.underagePersonsOnSite': '<strong>{0} underage</strong> persons on site.',
  'label.undoing': 'Undoing...',
  'label.universalSearchPlaceholder': 'Search',
  'label.unknown': 'Unknown',
  'label.unorientedCheckedInEmployee': 'Unoriented employee <strong>{0}</strong> have checked in to the site',
  'label.unorientedCheckedInEmployees': '<strong>{0}</strong> unoriented employees have checked in to the site',
  'label.unpaid': 'Unpaid',
  'label.unresolvedCheckInImei': 'Device IMEI: <strong>{0}</strong> <span class="text-muted">(IMEI: {1})</span>',
  'label.unresolvedCheckIns': 'Unrecognized check in for id <strong>{0}</strong> <strong>{1}</strong>',
  'label.unresolvedCheckinsShort': 'Unknown check ins.',
  'label.unresolvedContractPayments': 'Unallocated payments',
  'label.unresolvedFENNOCheckIns': 'Unrecognized check in by <strong>{0}</strong>(card: {1}) <strong>{2}</strong>',
  'label.unresolvedTaxNumberCheckIns': 'Unrecognized check in for taxnumber <strong>{0}</strong> <strong>{1}</strong>',
  'label.unresolvedVALTTICheckIns': 'Unrecognized check in for VALTTI card with id <strong>{0}</strong> <strong>{1}</strong>',
  'label.update': 'Update',
  'label.updateAccessPermit': 'Update access permit',
  'label.updateCompany': 'Update company',
  'label.updateCompetence': 'Update competence',
  'label.updateContacts': 'Update contacts',
  'label.updateContract': 'Update contract',
  'label.updated': 'Updated',
  'label.updatedDeclaration': 'Corrective report',
  'label.updateDevice': 'Update device',
  'label.updatePerson': 'Update person',
  'label.updateSafetyReport': 'Update safety report',
  'label.updateSite': 'Update site',
  'label.updateSiteAdditionalSettings': 'Update additional settings',
  'label.updateSiteRequirementSettings': 'Update requirements',
  'label.updateSiteWorkTimes': 'Update work times',
  'label.updateUser': 'Update user',
  'label.updateUserGroup': 'Update user role',
  'label.updating': 'Updating...',
  'label.uploadCsv': 'Upload CSV',
  'label.useCompanyAddress': 'Same as company\'s',
  'label.useCompanyFinnishAddress': 'Same as company\'s Finnish address',
  'label.useCompanyForeignAddress': 'Same as company\'s foreign address',
  'label.usedNotRequired': 'Used, not required',
  'label.usedRequired': 'Used, required',
  'label.user': 'user',
  'label.userGroup': 'User role',
  'label.userGroups': 'User roles',
  'label.username': 'Username',
  'label.usernameShort': 'Account',
  'label.userReport': 'User report',
  'label.userReportInfo': 'Generates an Excel file that lists all user accounts of the company.',
  'label.userReportShort': 'Users',
  'label.users': 'Users',
  'label.userSearch': 'User search',
  'label.useThis': 'Use this',
  'label.valid': 'Valid',
  'label.validIndefinately': 'Valid indefinately',
  'label.validShort': 'Valid',
  'label.validTaxNumber': 'Valid tax number',
  'label.validUntil': 'Valid until <strong>{0}</strong>',
  'label.valttiCard': 'Valtti card',
  'label.valttiCardAdded': 'Valtti card succesfully added!',
  'label.valttiCardExpired': 'Valtti card expired',
  'label.valttiCardExpiredOrExpiring': '<strong>{0} valtti cards</strong> expired or expiring within a month',
  'label.valttiCardExpiring': 'Valtti card expiring',
  'label.valttiCardNumber': 'Valtti card number',
  'label.valttiCards': 'Valtti cards',
  'label.valttiChecking': 'Checking the validity of the Valtti card...',
  'label.valttiContractorHasManyContractsAtSite': 'Contractor has several contracts at the site. Select the contract for which the access will be created.',
  'label.valttiFoundWithNumber': 'Valtti card with number {0} found for person:',
  'label.valttiSelectedButMissing': 'Valtti card has been selected to substitute site card photo, but currently employee doesn\'t have any Valtti cards that match the contractor.',
  'label.value': 'Value',
  'label.van': 'Van',
  'label.vanShort': 'Van',
  'label.vatNumber': 'VAT number',
  'label.vatObligation': 'VAT Register',
  'label.vatObligationLegend': 'An account of whether the enterprise is entered in the Value Added Tax Register',
  'label.vehicle': 'Vehicle',
  'label.vehicleConfirmRemove': 'Are you sure you want to remove vehicle <b>{0}</b> from permit?',
  'label.vehicleConfirmRemoveHeading': 'Confirm removal',
  'label.vehicleDetails': 'Vehicle details',
  'label.vehicleGate': 'Vehicle gate',
  'label.vehiclePermit': 'Vehicle permit',
  'label.vehiclePermit2': 'Vehicle permit',
  'label.vehiclePermitCardId': 'Id for vehicle permit',
  'label.vehiclePermitId': 'Vehicle permit number',
  'label.vehiclePermitNeeded': 'Vehicle permit needed',
  'label.vehiclePermitReasons': 'Reasons to apply for a vehicle permit',
  'label.vehiclePermits': 'Vehicle permits',
  'label.vehiclePermitsShort': 'Vehicles',
  'label.vehiclePermitTypeRemovalConfirmation': 'Are you sure you want to remove vehicle permit type <b>{0}</b>?',
  'label.vehicleRegistrationNumber': 'Registration number',
  'label.vehicleRegistrationNumberAndType': 'Registration number and vehicle type',
  'label.vehicleRemove': 'Remove vehicle',
  'label.vehicleReportHeading': 'Vehicle permits',
  'label.vehicleReportInfo': 'Creates a report that lists all vehicle permits that are valid at least on a single day in the given time span.',
  'label.vehicleReportShowOnlyAccepted': 'Show only accepted permits',
  'label.vehicles': 'Vehicles',
  'label.vehiclesFromTo': 'Vehicle permits from {0} to {1}',
  'label.vehicleTimespanTooltip': 'Shows vehicle permits that are active for at least one day in the given time span.',
  'label.vehicleType': 'Vehicle type',
  'label.verify': 'Verify right to work',
  'label.verifySummary': 'Show right to work',
  'label.visa': 'Visa',
  'label.visaNumber': 'Visa number',
  'label.visitor': 'Visitor',
  'label.visitorPermits': 'Product safety permits',
  'label.visitors': 'visitors',
  'label.volume.timespanTooltip': 'Includes into report measurements and defects during the selected timespan.',
  'label.volumeReport': 'Volume report',
  'label.volumeReportHeading': 'Volume report',
  'label.volumeReportInfo': 'Shows information about contractors, employess, work hours and contracts for all sites.',
  'label.volumeReportInfo2': 'The report includes all contracts that are active at least on a single day in the given time span. The report also includes all employees that have a valid access permit at leas on a signle day in the given time span. All work hours done in the given time span are also included.',
  'label.volumeReportShort': 'Volume',
  'label.voluntary': 'Voluntary',
  'label.wage': 'Hourly wage',
  'label.waitingApproval': 'Waiting approval',
  'label.waitingForAccessPermitApproval': 'Permit cannot be approved. Person doesn\'t have an approved access permit for the entire duration of the vehicle permit.',
  'label.waitingForOrientation': 'Permit cannot be approved. Person has to complete orientation first.',
  'label.waitingForPersonsOrientationToGainVehicleGateAccess': 'Vehicle gate access denied. Access requires person to be oriented.',
  'label.waitingForSignature': 'Awaiting signature',
  'label.waitingRequiredCompetences': 'Waiting required competences',
  'label.wednesdayShort': 'we',
  'label.week': 'Week',
  'label.weeklyHours': 'Weekly Hours',
  'label.weeklyHoursAlarm': 'Weekly hours alarm',
  'label.weekShort': 'wk',
  'label.weeksShort': 'w',
  'label.when': 'When guarding happens',
  'label.whoIsExpat': 'Who is considered as posted worker?',
  'label.whyCantIEdit': 'Why can\'t I edit this?',
  'label.withdrawApproval': 'Withdraw approval',
  'label.withdrawing': 'Withdrawing...',
  'label.withoutTagDesc': 'Show only employees, who don\'t have tags',
  'label.workDayTooLong': 'Warning: over {0} hour work day',
  'label.workerSignature': 'Worker signature',
  'label.workExperience': 'Work experience in years',
  'label.workHourBalance': 'Work hour balance',
  'label.workHourBalanceReport': 'Work hour balance report',
  'label.workingHoursCombined': 'Working hours for all sites',
  'label.workingTime': 'Working time',
  'label.workPermitExpires': 'Work permit expires',
  'label.workRelation': 'Work relation',
  'label.workSafetyCard': 'Work safety card',
  'label.workTime': 'Work time',
  'label.workTimeHeadingFromTo': 'Work Time Report from {0} to {1}',
  'label.workTimeReportHeading': 'Work time report',
  'label.worktimeReportInfo': 'Shows all work hours on the given time span.',
  'label.yes': 'Yes',
  'label.yesterday': 'Yesterday',
  'layout.edit': 'Edit floor plan',
  'layout.hasRaports': 'Floor plan already has saved locations, so it cannot be removed. If you want to change the floor plan, mark floor plan as passive and create a new one.',
  'layout.searchOrSelectLayout': 'Floor plan',
  'layouts.addFirst': 'Add first floor plan',
  'layouts.layout': 'Floor plan',
  'layouts.layouts': 'Floor plans',
  'layouts.newLayout': 'New floor plan',
  'layouts.noLayouts': 'No floor plans',
  'layouts.noReadRights': 'You do not have rights to read or modify layouts.',
  'layouts.offline': 'Layouts are not available here when offline.',
  'layouts.searchResults.many': '{0} floor plans',
  'layouts.searchResults.one': 'One floor plan',
  'locale.de.nameInThisLanguage': 'Deutsch',
  'locale.en.nameInThisLanguage': 'English',
  'locale.et.nameInThisLanguage': 'Eesti',
  'locale.fi.nameInThisLanguage': 'Suomi',
  'locale.pl.nameInThisLanguage': 'Polski',
  'locale.ru.nameInThisLanguage': 'Русский',
  'locale.sk.nameInThisLanguage': 'Slovenčina',
  'locale.sv.nameInThisLanguage': 'Svenska',
  'locale': 'en',
  'locationAddedToLayout': 'Location added to floor plan ',
  'login.accountNotFound': 'Account not found.',
  'login.didNotReceiveTemporaryPasswordViaSms': 'Did you not receive your password via SMS?',
  'login.enterAccountAndNumber': 'Enter your account.',
  'login.failedToSendTemporaryPassword': 'Failed to send password!',
  'login.incorrectRequest': 'Incorrect request!',
  'login.infoMessage': 'New password will be sent to your telephone number.',
  'login.login': 'Login',
  'login.newPasswordSentToEmail': 'New password sent to email address',
  'login.newPasswordSentToTelephone': 'New password sent to a telephone number',
  'login.ohNoIe': '<p>Your internet browser is <strong>Internet Explorer</strong> which is not supported by Zeroni. The supported browsers are <a href=\'https://www.google.com/chrome/\'>Chrome</a>, <a href=\'https://www.getfirefox.com/\'>Firefox</a>, <a href=\'https://www.opera.com/\'>Opera</a>, <a href=\'https://www.microsoft.com/fi-fi/windows/microsoft-edge/\'>Edge</a> and <a href=\'https://www.apple.com/safari/\'>Safari</a>.</p>',
  'login.passwordForgotten': 'Forgot your password?',
  'login.requestNewPassword': 'Request new password',
  'login.returnToLogin': 'Return to login page',
  'login.sendTemporaryPasswordToEmail': 'Send password to email',
  'login.showPassword': 'Show password',
  'login.telephone': 'Telephone number',
  'login.telephoneAndAccountDontMatch': 'Telephone number does not match account',
  'login.temporaryPasswordSentToEmail': 'Password sent to email!',
  'login.timeout': 'Login timeout: it took too much time!',
  'login.userHasSsoUsername': '<p>Your company uses a single sign-on service, <strong>Azure AD</strong>.</p><p>Please login using your Azure AD service.</p>',
  'login.wrongPassword': 'Wrong email or password.',
  'login.wrongVerificationCode': 'The verification code is wrong!',
  'mainContractorSignature': 'Main contractor\'s signature',
  'mainHelp.call': 'Call',
  'mainHelp.email': 'Email',
  'mainHelp.help.desc': 'Troubleshooting, tips, FAQ.',
  'mainHelp.help.heading': 'Help',
  'mainHelp.notifications': 'Bulletin Board',
  'mainHelp.privacyPolicy': '<a target=\'_blank\' rel=\'noopener\' href=\'https://www.zeroni.fi/privacy-policy/\'>Privacy policy</a>',
  'mainHelp.resources': 'Resources',
  'mainHelp.support': 'Support',
  'mainHelp.telephone.hours': 'Mon-Fri 8-16',
  'mainHelp.telephone.price': '0,69 € / min',
  'manage.info.accessAreas': 'Manage Site\'s access areas.',
  'manage.info.devices': 'Add, edit or remove devices.',
  'manage.info.docs': 'Add or remove documents for site.',
  'manage.info.downloadNFCReader': 'Download NFC readers install file',
  'manage.info.edit': 'E.g. site name, address and duration.',
  'manage.info.generalSettings': 'Contains, for example, default settings for new sites, safety deviation reporting and information needed for access permit.',
  'manage.info.introduction': 'Define and manage orientations for site.',
  'manage.info.jobtitles': 'Update and fetch current job title list.',
  'manage.info.layouts': 'Manage floor plans of the site. Safety and quality deviations can be located on the floor plans.',
  'manage.info.location': 'Define sites area. The area is used in eq. mobile checkIns, site selection, teehavainto.fi-service.',
  'manage.info.massMessaging': 'Send messages to contractors or sites employees.',
  'manage.info.orientation': 'Manage sites orientation topics.',
  'manage.info.professions': 'Manage allowed profession titles.',
  'manage.info.reportsToTaxAdmin': 'Archive for contract and employee reports that have been sent to Tax Admin.',
  'manage.info.siteAdditionalSettings': 'E.g. how competences are followed, default values for hot work permits, and information asked for access permit.',
  'manage.info.siteRequirementSettings': 'Required competences and trainings.',
  'manage.info.userGroups': 'Define and manage user roles. With user roles you can manage what information different users see.',
  'manage.info.users': 'Manage work pemits of employees.',
  'manage.info.workTime': 'Settings related to work time.',
  'map.area': 'Area',
  'map.startOver': 'Remove area',
  'markLocation': 'Mark location',
  'markToLayout': 'Mark to floor plan',
  'message.acceptanceRecordsOfLiabilityDocumentsStored': 'Certificates for contractor\'s obligations and liability for the contract <a href="#!/sites/{0}/contracts/{1}">{2}</a> approved.',
  'message.accessPermitAddedAlert': 'Access permit added.',
  'message.accessPermitCreatedForMultipleContracts': '<strong>Note!</strong> For each chosen contract will be created its own access permit.',
  'message.accessPermitIntroduced': 'Orientation to completed.',
  'message.accessPermitRequirements': '<strong>Note!</strong> Valid work safety and health cards required for an access permit.',
  'message.accessPermitUpdatedAlert': 'Access permit updated.',
  'message.accessPermitUpdateFailedAlert': 'Access permit update failed.',
  'message.accessPermitValidationPeriodInfo': 'Access permit\'s start and end dates may not exceed those of the contract.',
  'message.addOtherNotesViaCheckInList': 'Add other notes and reimbursments via <a href="{0}">check in list for this site</a>.',
  'message.askFinnishShortcut': 'You selected Finland as nationality. Should we also set Finnish as the normal country of employement and language?',
  'message.automaticTagListUpdateDescription': 'Automatic gate device updates sends new tag ids to all gate devices every 5 min. Automatic updates should be disabled only if lots of new workers are added to the site daily during site operations (more common in annual maintenance shutdown sites) because sending tag updates too often may slow down device operation. When disabled, you have to send updates to devices manually. Dashboard also shows the amount of tags waiting for update to devices.',
  'message.avoidOverlapWithAccessPermit': 'The employee already has the following access permit:',
  'message.avoidOverlapWithMultipleAccessPermits': 'The employee already has the following access permits:',
  'message.beforeAnythingElseCompleteCompanyInfo': '<strong>Incomplete company information:</strong> Before continuing, please complete the details for your company.',
  'message.businessIdAlreadyInUse': 'Business ID is already in use!',
  'message.canCreateAccessPermits': 'You can create incomplete access permits and finish them later',
  'message.canCreateAccessPermitsDespiteErrors': 'You may add access permits while waiting for missing information.',
  'message.changesSavedToPerson': '<strong>Saved to personal details:</strong> Changes are saved to person\'s contact details.',
  'message.changesSavedToPersonSpecific': '<strong>Saved to personal details:</strong> Changes are saved to person\'s contact details when access permits are added.',
  'message.changesSavedToPersonX': '<strong>Saved to personal details:</strong> Changes are saved to  <em>{0}\'s</em> contact details.',
  'message.checkForemanContactInfo': '<strong>Person serves as a foreman and, as such, needs a telephone and an email.</strong> Please make sure this person\'s contact information is up to date.',
  'message.checkInsNotLoaded': 'Check ins have not been loaded, yet. Select desired filters and click "Get check-ins".',
  'message.checkInsOnMultipleSites': 'Check ins and outs are saved to all the sites the device is connected to, and the person checking has an access permit to.',
  'message.checkoutForgottenBody': '<p>You checked in to <strong>{1}</strong> at <strong>{0}</strong>, but did not check out.</p><p>Please enter the time you left the site.</p>',
  'message.checkSiteForemanContactInfo': '<strong>Site foreman needs a telephone and an email.</strong> Please make sure this person\'s contact information is up to date.',
  'message.companyFilterInPersonSearchHelp': 'Show employees for the selected company. Only employees with an active access permit to an active contract are shown.',
  'message.companyHasNoEvaluations': 'Company contracts have not yet been evaluated.',
  'message.companyNotTrustedPartner': 'Company is not a Tilaajavastuu Reliable Partner.',
  'message.companyRegisterIsEmpty': 'Company register is empty.',
  'message.companyUpdated': 'Company <a href="{0}">{1}</a> updated.',
  'message.companyWorksAs': '<strong>{0}</strong> acts on site <strong>{1}</strong>',
  'message.competenceAddedAlert': 'Competence added.',
  'message.competenceAlreadyExists': 'Person already has this competence.',
  'message.competenceFilterInPersonSearchHelp': 'Only employees with an access permit and necessary contact information are shown.',
  'message.competenceUpdatedAlert': 'Competence updated.',
  'message.confirmAccessPermitRejection': 'Are you sure you want to invalidate this access permit?',
  'message.confirmDeviceDeletion': 'Are you sure you wish to delete device <strong>{0}</strong>?',
  'message.confirmEnd': 'Confirm that measurement is ready to be ended.',
  'message.confirmInterruption': 'This contract and all of its subcontracts will be interrupted. Do you wish to proceed?',
  'message.confirmRemoval': 'Remove contract <strong>{0}</strong>? This action will remove this contract, every contract beneath, access permits and all check ins.',
  'message.contractAdded': 'The contract <a href="#!/sites/{0}/contracts/{1}">{2}</a> has been added.',
  'message.contractCompanyInvalid': 'Employees can be added to the contract only after the company\'s information has been filled.',
  'message.contractDeclarationsWaitingForSending': '<strong>Report on contract details</strong> has not been sent!',
  'message.contractStatusChangesByReport': 'Contract\'s status will be updated according to status stated in the report. Also the current report and status will be archived to contract\'s documents.',
  'message.contractStatusInvalid': 'Contract will be valid when either information on contractor\'s obligations has been saved and approved, or valid information is attained via Trusted Partner program.',
  'message.contractStatusWarning': 'Contractor\'s Obligations Act documents are not valid anymore',
  'message.contractStatusWarningMoreInfo': 'FYI: After the contract has been added with valid COA documents, some documents are no longer valid according to the Reliable partner report (<a href="{0}" target="_blank"><span class="far fa-file"></span> PDF</a>). This does not automatically introduce any changes and everything works as before.',
  'message.contractUpdated': 'The contract <a href="#!/sites/{0}/contracts/{1}">{2}</a> has been updated.',
  'message.csvInstructions': 'Uploaded file needs to be in a .csv format. Fields are separated by a semicolon.',
  'message.dashboardSelectionHelp': 'Dashboard presents a summary for all sites or a specific site.',
  'message.deleteUserGroupConfirmation': 'Are you sure you want to remove the user role <strong>{0}</strong>?',
  'message.deleteUserGroupHeading': 'Remove User Role {0}',
  'message.deviceAddedAlert': 'Device added.',
  'message.deviceConnectedAlert': 'Device <strong>{0}</strong> added to the site',
  'message.deviceDeletedAlert': 'Delete deleted.',
  'message.deviceInUseOnMultipleSites': '<strong>Device in use!</strong> This device is already in use at following sites:',
  'message.deviceInUseOnOneSite': '<strong>Device in use!</strong> This device already in use at the site <em>{0} {1}</em>.',
  'message.deviceOpenedRemotely': 'Gate <strong>{0}</strong> opened remotely',
  'message.deviceRemoteOpeningFailed': 'Device remote opening failed!',
  'message.deviceRemovedAlert': 'Device <strong>{0}</strong> removed from the site',
  'message.emailRequiredInfo': 'The email address is the employee\'s username for Zeroni. Online training invitations are delivered to the employee\'s email. For this reason, Zeroni cannot have multiple users with the same email address.',
  'message.employeeDeclarationsWaitingForSending': '<strong>Report on employee details</strong> has not been sent!',
  'message.employeeMissingNewcomerTraining': 'Person <strong>{0}</strong> is missing a training for newcomers.',
  'message.employeesMissingNewcomerTraining': '<strong>{0} persons</strong> are missing a newcomer\'s training.',
  'message.emptyDeviceRegister': 'Device register is empty.',
  'message.emptyEmployeeRegister': 'Person register is empty.',
  'message.emptySiteRegister': 'The site register ei empty.',
  'message.emptyUserGroupRegister': 'The user role register is empty.',
  'message.emptyUserRegister': 'The user register is empty.',
  'message.faultMeasurementRemovalConfirmation': '<strong>Are you sure you want to remove the measurement?</strong> All observations will be lost. Action cannot be revoked.',
  'message.fetchNewLiabilityReportAndUpdateContractStatus': 'Fetch new liability document from Luotettava kumppani and update contract status.',
  'message.firstAccessPermitAddedAlert': 'Access permit added. <a href="{0}">Next, add competences <span class="far fa-angle-right"></span></a>',
  'message.firstLoginNoPassword': '<p><strong>Hello, {0}!</strong></p><p>You haven\'t yet got a password for Zeroni. Send it to your mobile phone.</p>',
  'message.formHasInvalidFields': '<strong>Invalid fields:</strong> One or more input fields is missing a value or the value is invalid.',
  'message.formHasInvalidFieldsTxt': 'One or more input fields is missing a value or the value is invalid.',
  'message.giveMinimum': 'Search needs at least {0} characters.',
  'message.goToUpdateDocumentsManually': 'Go to update liability documents manually from contract\'s documents settings.',
  'message.helpManaged': 'Contact person will get privileges to handle the company\'s part of the contract, e.g. creating access permits.',
  'message.identifierAndBirthDateDontMatch': 'Birth date and identifier doesn\'t match.',
  'message.identifierIsInUse': 'Identifier is already in use by other employee. For further help, contact support.',
  'message.identityDocumentRequirement': 'The law requires checking the identity document of everyone working on the construction site. Documents must be retained for two years after the work has ended.',
  'message.idInUse': '<strong>Id is in use</strong> on this site during selected timespan. Please select another id.',
  'message.idUsedByPerson': '<strong>Id is in use</strong> on this site during selected timespan. Id is assigned to <strong>{0}</strong>.',
  'message.imeiExists': '<strong>IMEI already exists.</strong> Is the IMEI correct? Is it being used on another device in the registry? If the problem persists, please contact support (support@zeroni.fi).',
  'message.incorrectDate': '<strong>Check formatting:</strong> Date needs to typed in as <strong>dd.mm.yyyy</strong>.',
  'message.incorrectExpirationDate': '<strong>Check expiration:</strong> Expiration date should be formatted as <strong>mm/yyyy</strong>.',
  'message.incorrectTime': '<strong>Check time:</strong> Time should be formatted as <strong>hh:mm</strong>.',
  'message.instructorStatement': 'I have read and confirm the orientation form. Orientator possesses requied safety knowledge and personal protective equipment. Orientator is familiar with the safety regulations on this site.',
  'message.instructorStatementWithPerson': 'I have oriented <strong>{0}</strong>. They have the necessary safety knowledge and personal safety gear specified by site regulations. The are familiar with the safety regulations and commit to following them.',
  'message.instructorStatementWithWorker': 'I have completed orientation with <strong>{0}</strong>. Orientator possesses requied safety knowledge and personal protective equipment. Orientator is familiar with safety regulations on this site.',
  'message.introductionToContractInformationInSiteEditor': 'First contract on the site is created by using contract information. Contracts are required for tax declarations and management of access permitions.',
  'message.invalidationUserConfirmation': 'Are you sure you want to revoke access right to company from <strong>{1}</strong> user <strong>{0}</strong>?',
  'message.invalidChecksumForFinnishBusinessId': '<strong>Are you quite sure?</strong> This id doesn\'t seem to be one for a real company. Please check the numbers.',
  'message.invalidChecksumForVatNumber': 'Enter the VAT number, its country code + identifier without spaces and hyphens, e.g. SE123456',
  'message.invalidContractTimePeriod': 'Please give start and end dates for the contract. They are needed to avoid overlapping main contracts.',
  'message.jobTitleHelp': 'Search for company by job titles specified in contracts.',
  'message.largeSearchResult': '{0} other results.',
  'message.liabilityDocsCheckBy': 'Documents have been checked and approved by <strong>{0}</strong>.',
  'message.liabilityDocsElsewhere': 'Documents saved to an external system.',
  'message.linkToManualTaxNumberCheck': 'Check tax number manually (www.vero.fi)',
  'message.lowWage': '<strong>Less than minimum wage:</strong> An employee is entitled to a minimum wage of {0} € per month (i.e. {1} € per hour).',
  'message.message.regionHelp': 'Region where the company has been Active.',
  'message.missingCardIdHelp': 'Show only persons with a missing card id',
  'message.newcomerTrainingRequirements': '<strong>Newcomer training</strong> is available only after the following items have been completed:',
  'message.newPersonFromGlobalDb': 'Infomration for person <strong>{0}</strong> copied from common person register. Fill up the rest of the required fields and add this new person.',
  'message.newPersonFromValtti': 'Information for person <strong>{0}</strong> copied from the Valtti card. Fill up the rest of the required fields and add this new person.',
  'message.noAccessPermits': 'No access permits have been added.',
  'message.noAccessPermitsFound': 'No access permits found.',
  'message.noActiveAccessPermits': 'No <em>active</em> access permits.',
  'message.noCompaniesFoundWithGivenFilters': 'No companies found with given search parameters.',
  'message.noCompetences': 'No competences have been added.',
  'message.noCompetencesFound': 'No competences found.',
  'message.noContracts': 'No contracts have been added.',
  'message.noContractsForAccessPermit': 'A site permit needs a contract but the chosen site doesn\'t have any active contracts\'.',
  'message.noPayments': 'No payments have been added to the contract.',
  'message.noQualityMeasurementsFound': 'Site doesn\'t have any quality measurements.',
  'message.noSafetyMeasurementsFound': 'Site doesn\'t have any safety measurements.',
  'message.noSafetyMeasurementsFoundWithFilters': 'No safety measurements found with given filters.',
  'message.noSafetyToursFound': 'There have been no TR- or MRV- measurements on this site.',
  'message.noSearchResults': 'No search results.',
  'message.noTaxNumberAlert': '<strong>No tax number!</strong> This person is still missing a tax number. Tax number is required in most cases, e.g. reports to Tax Administration.',
  'message.notUniqueCompetence': '<strong>Check competence and expiration:</strong> Person already has a <strong>{0}</strong> which is valid until <strong>{1}</strong>.',
  'message.noUnallocatedPayments': 'No unallocated payments.',
  'message.noValttiTagsInfo': 'Valtti cards are not included as tags.',
  'message.numberOfUnallocatedPayments': 'Site has <strong>{0} unallocated payments</strong>.',
  'message.overlapsMainContract': 'A main contract already exists for given timespan.',
  'message.paperIntroductionHowTo': '<a href="{0}" target="_blank">Open the orientation form</a>, print it add photo of signed form below.',
  'message.passwordSent': '<strong>Password has been sent to your mobile phone.</strong> You should now be able to log in.',
  'message.personAddedAlert': 'Person added.',
  'message.personAlreadyExists': 'Person <strong>{0}</strong> already exists!',
  'message.personalRequirementsErrorNoSiteCard': '<strong>Insufficient personal information.</strong> Site card can be created only after these shortages have been fixed:',
  'message.personalRequirementsErrorNoTag': '<strong>Insufficient personal information.</strong> Site card can be created only after these shortages have been fixed:',
  'message.personDeleted': 'Person <strong>{0}</strong> deleted.',
  'message.personUpdatedAlert': 'Person updated.',
  'message.phoneNumberRequiredInfo': 'The employee\'s phone number is used for two-factor authentication to deliver the password and to send critical work safety messages. Multiple users cannot be added to Zeroni with the same phone number.',
  'message.pleaseAddCompetenceFirst': 'Please add a competence first.',
  'message.pleaseSpecifySearch': 'Please refine your search.',
  'message.quarterDeleted': 'Safety quarter <strong>{0}</strong> deleted.',
  'message.ratingHelp': 'Company evaluation average atleast the amount of stars. You can remove rating search criteria by clicking star again.',
  'message.regionHelp': 'Province, where company has done work before.',
  'message.returnIdHelp': '<strong>Note!</strong> Returning the id ends every access permit where it exists. This applies to current permits as well as future ones.',
  'message.scanBothSidesOfResidencePermitDocument': 'Remember to scan both sides of residence permit document.',
  'message.selectContractFirst': 'Select a contract first',
  'message.selectEmployerFirst': 'Select an employer first.',
  'message.selectEndDate': '<strong>{{vm.task.text}}</strong> can take several days. Select end date.',
  'message.selectPaymentsForAllocation': '<strong>Select</strong> payments to be allocated.',
  'message.selectSiteFirst:': 'Please select a site first: ',
  'message.selectSiteFirst': 'Please select a site first.',
  'message.separateReferencesWithCommas': 'Separate payment references with commas.',
  'message.sessionExpired': '<strong>You were inactive for a long time</strong> so you were signed out. Please log in again.',
  'message.settingsUpdated': 'Settings updated.',
  'message.showGeneralEvaluationForCompany': 'Show the average evaluation for company <strong>{0}</strong>',
  'message.sickLeaveReason': 'Please enter the reason for taking sick leave.',
  'message.siteAdded': 'The site <a href="#!/sites/{0}/">{1} {2}</a> added.',
  'message.siteFilterInPersonSearchHelp': 'If site is selected, then only employees with an active access permit are shown.',
  'message.siteNumberTaken': '<strong>Number taken!</strong> The site number you provided is already in use. Each site needs a unique number.',
  'message.siteUpdated': 'The site<a href="#!/sites/{0}/">{1} {2}</a> has been updated.',
  'message.tagIdLongToShort': 'That id ({0})looked like the internal number for a key ring tag. I switched the id to the tag\'s short code.',
  'message.taxNumber.goToInvalidFields': 'Move to invalid fields',
  'message.taxNumber.invalidTaxNumFields': '<strong>The personal information is incorrect according to the tax administration.</strong> Please correct the information before update.',
  'message.taxNumberFormat': '12 numbers, beginning with "1000".',
  'message.taxNumberInvalid': 'Tax number and birth date do NOT match in Tax Administration\'s public register of tax numbers.',
  'message.taxNumberPendingCheck': 'There are problems in tax number verification at the moment. The validity of personal information can\'t be verified. The verification will be tried again every 2 hours.',
  'message.taxNumberValid': 'Tax number, birth date and name match in Tax Administration\'s record.',
  'message.trustedPartnerConnectionError': 'Cannot check for Reliable Partners. (Connection error: Vastuugroup.fi does not respond.)',
  'message.tryAgainOrContactSupport': 'Please try again or contact support.',
  'message.unknownUsername': 'Zeroni does not recognize the entered username. Please check your username.',
  'message.userAdded': 'User <strong>{0}</strong> added.',
  'message.userGroupAdded': 'User role <strong>{0}</strong> added.',
  'message.userUpdated': 'User <strong>{0}</strong> updated.',
  'message.valttiCardNotFound': 'No person could be found with the given Valtti card number. If the card is new, it probably hasn\'t been updated into Suomen Vastuu Group Oy\'s database yet.',
  'message.ValttiCardPersonExists': 'Person <strong>{1}</strong> already exists. <a href="{0}">Show person <span class="far fa-angle-right"></span></a>',
  'message.valttiIdToBarCode': 'That id ({0}) looked like the internal number for a Valtti card. I switched the id to the card\'s bar code.',
  'message.workerStatement': 'I have read and confirm the orientation form. I possess requied safety knowledge and personal protective equipment. I am familiar with and commit to the safety regulations on this site.',
  'message.wrongFormatForFinnishBusinessId': '<strong>Wrong format!</strong> Finnish business id\'s have a format of <strong>1234567-8</strong>.',
  'messaging.activity.all': 'All',
  'messaging.activity.contracts': 'Activity of the contact\'s contracts',
  'messaging.activity.onlyActive': 'Active',
  'messaging.activity.onlyActiveOrPending': 'Active and pending',
  'messaging.activity.onlyPending': 'Pending',
  'messaging.activity.permits': 'Permit activity',
  'messaging.bulletinBoard.noEnglish.desc': 'The missing text will be filled from the Finnish field.',
  'messaging.bulletinBoard.noEnglish.title': 'The title or content has no English translation.',
  'messaging.bulletinBoard.noFinnish.desc': 'The missing text will be filled from the English field.',
  'messaging.bulletinBoard.noFinnish.title': 'The title or content has no Finnish translation.',
  'messaging.category': 'Category',
  'messaging.companyRelation.all': 'All',
  'messaging.companyRelation.onlyOther': 'Only other',
  'messaging.companyRelation.onlyOwn': 'Only own',
  'messaging.companyRelation.ownOrOtherEmployees': 'All / own employees',
  'messaging.companyRelation.ownOrOtherForemen': 'All / own foremen',
  'messaging.content': 'Content',
  'messaging.defaultSubject': 'Message from Zeroni',
  'messaging.emailAttachments': 'Attachments',
  'messaging.foremen.findAll': 'Find foremen and deputy foremen from all sites',
  'messaging.foremen.only': 'Only foremen and deputy foremen',
  'messaging.messageSendFailedAlert': 'Message could not be sent: {0}',
  'messaging.messageSentAlert': 'Message sent successfully.',
  'messaging.newMessage': 'New message',
  'messaging.receiverName': 'Receiver name',
  'messaging.recipient': 'recipient',
  'messaging.recipients': 'recipients',
  'messaging.recipientsLabel': 'Recipients',
  'messaging.recipientsWithMissingEmail': 'Some recipients have no e-mail address',
  'messaging.recipientsWithMissingPhone': 'Some recipients have no phone number',
  'messaging.searchFilters': 'Search filters',
  'messaging.selectedContractNotActive': 'The selected contract is not active anymore.',
  'messaging.send': 'Send',
  'messaging.sender': 'Sender',
  'messaging.sending': 'Sending...',
  'messaging.sendMessage': 'Send message',
  'messaging.sent': 'Sent!',
  'messaging.smsPrice': 'Price: {0}cents/message',
  'messaging.subject': 'Subject',
  'messge.deviceUpdatedAlert': 'Device updated.',
  'monitoring.allReports': 'All reports',
  'monitoring.allSources': 'All sources',
  'monitoring.anonymous': 'Anonymous',
  'monitoring.deviation': 'Deviation',
  'monitoring.estimatedRepairDate': 'Estimated repair date',
  'monitoring.fromMeasurement': 'From measurement',
  'monitoring.layouts': 'Floor plans',
  'monitoring.measurementType': 'Measurement type',
  'monitoring.negativeReport': 'Negative report',
  'monitoring.newPositiveReport': 'New positive report',
  'monitoring.noOpenReports': 'No open reports',
  'monitoring.openReports': 'Open reports',
  'monitoring.positiveEnvironmentReport': 'Positive environment reports',
  'monitoring.positiveEnvironmentReports': 'Positive environment reports',
  'monitoring.positiveHelp': '<strong>Positive report</strong> can be reported when safety or environmental concerns have been met exceptionally well.',
  'monitoring.positiveReport': 'Positive report',
  'monitoring.positiveReports': 'Positive reports',
  'monitoring.positives': 'Positives',
  'monitoring.positiveSafetyReport': 'Positive safety report',
  'monitoring.positiveSafetyReports': 'Positive safety reports',
  'monitoring.process': 'Process',
  'monitoring.repaired': 'Repaired',
  'monitoring.repairedReports': 'Repaired reports',
  'monitoring.reportInfo': 'Report info',
  'monitoring.selectArea': 'Select area',
  'monitoring.selectReporter': 'Select reporter',
  'monitoring.separatelyAddedPlural': 'Separately added',
  'monitoring.separatelyAddedSingular': 'Separately added',
  'monitoring.teehavainto': 'Teehavainto.fi',
  'months.short': 'mo',
  'months': 'months',
  'more': 'More',
  'moreInformation.rootDomain': 'More information ({0})',
  'moved': 'Moved',
  'new': 'New',
  'newLine': 'New Line',
  'next': 'Next',
  'nextSubmitting': 'Next...',
  'noContractorObligationRaport': 'No report found',
  'noLayouts': 'Site does not have any floor plans. Add floor plans from the Manage tab.',
  'notification.maintenance': 'Zeroni is having a maintentance break at {0}. The maintenance break takes around {1} minutes.',
  'observation.abbr': 'Obs.',
  'observation': 'Observation',
  'ordererSignature': 'Developers signature',
  'orientation.a1Certificate': 'Social security certificate:',
  'orientation.a1CertificateExpires': 'Social security certificate expiration date:',
  'orientation.a1Certificates': 'Social security certificates',
  'orientation.additionalInfo': 'Additional info',
  'orientation.additionalInfoForm': 'Additional info',
  'orientation.addOnlineSignatures': 'Add online signatures',
  'orientation.approxWorkingPeriod': 'Estimated working period:',
  'orientation.backToEditMode': 'Edit mode',
  'orientation.cantPerformOrientationBody': 'The employee\'s orientation can\'t be confirmed because the required expertise is missing.',
  'orientation.cantPerformOrientationHeading': 'Orientation can\'t be confirmed',
  'orientation.city': 'City:',
  'orientation.competence': 'Competence',
  'orientation.competences': 'Competences:',
  'orientation.completed': 'Completed',
  'orientation.completeOnlineOrientation': 'Complete online orientation',
  'orientation.confirmOrientation': 'Confirm orientation',
  'orientation.date': 'Date:',
  'orientation.developer': 'Developer:',
  'orientation.documents': 'Documents',
  'orientation.driverlicense': 'Driver\'s licence',
  'orientation.emailAddress': 'Email address:',
  'orientation.employed': 'Employed',
  'orientation.employee': 'Employee',
  'orientation.employeeBirthDate': 'Birthdate:',
  'orientation.employeeEmail': 'Email address:',
  'orientation.employeeExperience': 'Employee expirience:',
  'orientation.employeeFirstName': 'First name:',
  'orientation.employeeHealthCardExpirationDate': 'Health card expiration date:',
  'orientation.employeeInfo': 'Personal details:',
  'orientation.employeeLastName': 'Last name:',
  'orientation.employeePhone': 'Phonenumber:',
  'orientation.employeeTaxNumber': 'Tax number:',
  'orientation.employer': 'Employer:',
  'orientation.employmentRelation': 'Employment relationship:',
  'orientation.entrepreneur': 'Entrepreneur',
  'orientation.errorMsg.orientAnyway': 'Orient anyway',
  'orientation.errorMsg': 'The creation of the orientation document failed. The problem is probably caused by a weak internet connection. You can try to create the document again by clicking on the above button or you can skip the creation of the document and orient anyway. The document can be created later in the access permit editor. If you are using a WiFi network, please consider moving closer to the WiFi router. You can also try to use your phone\'s mobile network, if you are currently using WiFi.',
  'orientation.errorMsgHeader': 'Error in document generation',
  'orientation.expatRepresentative': 'Representative in Finland for the expatriate',
  'orientation.expiration': 'Expiration date',
  'orientation.filename': 'orientaitonform.pdf',
  'orientation.finnishAddress': 'Finnish address',
  'orientation.foreignAddress': 'foreign address',
  'orientation.foreignWorkerInfo': 'Foreign worker information',
  'orientation.homeCountry': 'Home country:',
  'orientation.idcard': 'Identity card',
  'orientation.idConfirmationType': 'ID confirmation type:',
  'orientation.instructor': 'Instructor',
  'orientation.known': 'Known',
  'orientation.mainContractor': 'Main contractor:',
  'orientation.mentor': 'Mentor',
  'orientation.nationality': 'Nationality:',
  'orientation.notNeeded': 'Not needed',
  'orientation.notUsed': 'Not used',
  'orientation.orient': 'Orient',
  'orientation.orientationReport': 'Orientation report',
  'orientation.orientOnPaper': 'Orient on paper',
  'orientation.passport': 'Passport',
  'orientation.permanent': 'Permanent',
  'orientation.postcode': 'Postcode:',
  'orientation.primaryLanguage': 'Language:',
  'orientation.printAvailableAtNextStep': 'Orientation form is available for printing after this step.',
  'orientation.printOrientationForm': 'Print orientation form',
  'orientation.profession': 'Profession:',
  'orientation.representative': 'Representative:',
  'orientation.residencePermit': 'Residence permit:',
  'orientation.residencePermitExpires': 'residence permit expires:',
  'orientation.saveAndProceedToOrientation': 'Save and proceed to orientation',
  'orientation.showOrientationForm': 'Show orientation topics',
  'orientation.signatureClarification': 'Signature clarification',
  'orientation.signatures': 'Signatures',
  'orientation.signatureText': 'The employee being oriented and the instructor affirm with these signatures that the employee has the necessary information about work safety and the personal equipment required by the regulations. The employee knows the work safety instructions of the site and agrees to follow them.',
  'orientation.siteAddress': 'Site address:',
  'orientation.siteForeman': 'Site foreman:',
  'orientation.siteForemanPhone': 'Site foreman phonenumber:',
  'orientation.siteInfo': 'Site info',
  'orientation.siteName': 'Site name:',
  'orientation.siteNumber': 'Site number:',
  'orientation.streetAddress': 'Address:',
  'orientation.tasks': 'Tasks:',
  'orientation.telephone': 'Phonenumber:',
  'orientation.temporary': 'Temporary',
  'orientation.topic': 'Orientation topic',
  'orientation.topics': 'Orientation topics',
  'orientation.topicsForSite': 'Topics for site',
  'orientation.toSite': 'Confirmation of orientation',
  'orientation.underageMentor': 'Underage person\'s mentor',
  'orientation.unpaid': 'Unpaid',
  'orientation.valttiCard': 'Valtti card:',
  'otherLocation': 'Other location',
  'otherServices': 'Other services',
  'param.languageSortKey': 'nameEnglish',
  'passiveLayouts': 'Passive floor plans',
  'password.changedMessage': 'Password changed',
  'password.changePassword': 'Change password',
  'password.changePasswordFailed': 'Failed to update password.',
  'password.changing': 'Changing...',
  'password.errorASYNC_VALIDATION_RUNNING': ' ',
  'password.errorERROR': 'Error while validating password strength',
  'password.errorKNOWN': 'Given password is recognized as too commonly used and is not allowed.',
  'password.errorTOO_LONG': 'Password must not be over 256 characters',
  'password.errorTOO_SHORT': 'Password must be at least 12 characters',
  'password.failedToSubmit': 'Failed to update password. Please try again. If the problem persists, contact support (support@zeroni.fi).',
  'password.invalidPasswordMessage': '<strong>Wrong password</strong>: Check your old password and try again',
  'password.newPassword': 'New password',
  'password.oldPassword': 'Old password',
  'password.oldPasswordRequired': 'Old password is required',
  'password.showPassword': 'Show/hide password',
  'password.strength.fair': 'So-so',
  'password.strength.good': 'Good',
  'password.strength.great': 'Great',
  'password.strength.weak': 'Weak',
  'password.validationMinLength': 'Password must be at least 12 characters',
  'payment.addNewPayment': 'Add new payment',
  'payment.addPayment': 'Add payment',
  'payment.allPayments': 'All payments',
  'payment.confirmRemoval': 'Remove payment <strong>{0}</strong>?',
  'payment.confirmRemovals': 'You are removing <strong>{0}</strong> payments, total <strong>{1}</strong>. Do you want to continue?',
  'payment.editPayment': 'Edit payment',
  'payment.estimatedPriceLowerThatn15k': 'Estimated price under 15 t€.',
  'payment.estimatedSum': 'Estimated',
  'payment.importedFromExternalSystem': 'Created by other system',
  'payment.latestPayment': 'Latest payment',
  'payment.noEstimatedSum': 'No estimation',
  'payment.noPayments': 'No payments',
  'payment.noTaxReportsAreSent': 'No tax reports are sent.',
  'payment.noTaxReportsFromThisContract': 'No tax reports are sent from this contract.',
  'payment.noTaxReportsFromThisSite': 'No tax reports from the payments of this site\'s contracts are sent. See site\'s settings.',
  'payment.removePayment': 'Remove payment',
  'payment.removePaymentHeading': 'Remove payment?',
  'payment.removePayments': 'Remove payments',
  'payment.removePaymentsHeading': 'Remove payments?',
  'payment.searchUnallocatedByCompanyName': 'Search by company name',
  'person.addTag': 'Add tag',
  'person.addTagToPerson': 'Add tag to <strong>{0}</strong>',
  'person.cardExpired': 'Card expired!',
  'person.chooseRepresentative': 'Choose representative',
  'person.deactivatedNotification': '<strong>{0}</strong> has been suspended. If you want to unsuspend this person, please contact support (support@zeroni.fi).',
  'person.doesNotLookEmployeeEmail': 'Email does not seem to correspond first and last names. Do you still want to use this address?',
  'person.drugTest.approvalDate': 'Approved on {0}.',
  'person.drugTest.expirationDate': 'Valid until {0}.',
  'person.formerExpatRepresentatives': 'Previously used representatives',
  'person.hotWorkPermitViaManagement': 'Hot work permits are added via management section.',
  'person.identifierFound': 'Person found with given identifier. Please check data in the form.',
  'person.incompleteInfo': 'Incomplete details',
  'person.incompleteInfoForOrientation': 'Person <strong>{0}</strong> is missing some details that are needed in order to confirm orientation.',
  'person.invoiceHourDefaultApprover': 'Default approver for invoice hours',
  'person.isNameCorrect': '<strong>The last name ("{0}") resebmles a first name.</strong> Should we switch first and last names?',
  'person.nationalityFailedToAdd': 'Failed to update nationality.',
  'person.nationalityMissingAlert': 'Person has no nationality in their records.',
  'person.nationalityMissingLink': 'Add nationality',
  'person.nationalityRequiredBeforeAccessPermits': 'Nationality is required before creating access permits.',
  'person.noAccessPermits': 'No access permits.',
  'person.noCompetences': 'No competences.',
  'person.noNameIsCorrent': 'No, the name is correct',
  'person.noTags': 'No tags.',
  'person.noTagsWarning': '<strong>No tags.</strong> This person, however, has a permit to a site with a check-in device.',
  'person.oldTaxNumberHelp': '<p>If the person is not reported to Tax Administration in two years, their tax number is removed from the public register of tax numbers.</p><p><strong>A tax number is registered</strong> in <a href="https://www.vero.fi/en/e-file/mytax/" target="_blank" rel="noopener">MyTax <span class="fas fa-external-link-alt"></span></a> (vero.fi), by calling the service line for tax numbers <a href="tel:+358 29 497 070">029 497 070</a> or visiting a Tax Administration office.</p><p>More information: <a target="_blank" rel="noopener" href="https://www.vero.fi/en/individuals/tax-cards-and-tax-returns/arriving_in_finland/work_in_finland/specific-instructions-for-different-occupations/coming-to-a-construction-site-or-a-shipyard/Tax_number/">Tax numbers are required at construction sites <span class="fas fa-external-link-alt"></span></a> (vero.fi)</p>',
  'person.onlyPersonCanChange': 'Only person themself can modify.',
  'person.pendingSecurityClearance': 'Waiting for security clearance. Applied on {0}.',
  'person.personNumber': 'Person id',
  'person.personRole': 'Role',
  'person.previousWorkOverTaxAdminLimit': 'Did the person work last time over 2 years ago?',
  'person.safetyProcedures': 'Safety procedures',
  'person.search.noneOnThisSite': '<p>No persons found on the selected site ({0}).</p>',
  'person.search.pendingVerification': 'Pending verification of right to work',
  'person.searchOrSelectPerson': 'Person',
  'person.securityClearance.created': 'Created on {0}.',
  'person.securityClearance.validity': 'Valid {0}.',
  'person.securityClearance.waitingForReply': 'Waiting for a reply.',
  'person.submitMissingDetailsBeforeOrientation': 'Submit missing details in order to proceed to orientation.',
  'person.tags': 'Tags',
  'person.taxNumber.nameSuggestion': 'According to tax number registry the correct name for the person is <strong>{0}, {1}</strong>. Do you want to use this name?',
  'person.taxNumber.verificationRequirements': 'In addition to taxnumber, enter first and last name below.',
  'person.taxNumber.verifying': 'Verifying...',
  'person.taxNumber.verifyingTaxNumber': 'Verifying the tax number...',
  'person.taxNumberChecked': 'I checked the number and it\'s all good',
  'person.valttiOrAddBelow': 'Please insert a Valtti card number, or alternatively enter person\'s information below.',
  'person.yesSwitchNames': 'Yes, switch names',
  'presence.help': 'Mark employees who worked on the site. Information is used in employee report that is sent to tax admin.',
  'presence.personHasCheckIns': 'Person has check ins on selected month',
  'presence.taxNumberMissing': 'Tax number missing',
  'presence.workersPresentByMonth': 'Worker presence by month',
  'presence': 'Presence',
  'previous': 'Previous',
  'quality.addFirstDefect': 'Add first quality defect',
  'quality.defect': 'Quality defect',
  'quality.defectInfo': '<strong>Quality defect</strong> is reported when there are problems with the quality of the work.',
  'quality.defects.searchResults.many': '{0} deviations',
  'quality.defects.searchResults.one': '1 deviation',
  'quality.defects.stepsToPreventInFuture': 'Steps to prevent in the future',
  'quality.defects.underlyingReason': 'Underlying reason for the defect',
  'quality.defects': 'Quality defects',
  'quality.faultList': 'Fault list',
  'quality.faultLists': 'Fault lists',
  'quality.inspections': 'Inspections',
  'quality.measurement': 'Quality measurement',
  'quality.measurements': 'Quality measurements',
  'quality.newDefect': 'New quality defect',
  'quality.noDefects': 'No defects',
  'quality.noOpenDefects': 'No open defects',
  'quality.noRepairedDefects': 'No repaired defects',
  'quality.openFaultLists': 'Open fault lists',
  'quality.openFaultListsShort': 'Open lists',
  'quality.openReports': 'Open defects',
  'quality.quality': 'Quality',
  'raport.layoutInfo': 'Site does not have floor plans. <a ui-sref="main.sites.site.manage.layouts">Add floor plan from here.</a>',
  'ratingTitles': '["One", "Two", "Three", "Four", "Five"]',
  'removeMarker': 'Remove marker',
  'report.accessPermitsWithCheckInsFromTo': 'Access permits with check ins from {0} to {1}',
  'report.contractContact.businessId': 'Business ID',
  'report.contractContact.company': 'Company',
  'report.contractContact.contractContact': 'Contract contacts',
  'report.contractContact.contractContactFileName': 'Contractcontacts',
  'report.contractContact.contractContactReport': 'Contract contacts-report',
  'report.contractContact.email': 'Email',
  'report.contractContact.filterContract': 'Contract:',
  'report.contractContact.filterManaged': 'Show only contacts with manage-rights.',
  'report.contractContact.filterManagedTitle': 'Managed',
  'report.contractContact.filterSite': 'Site:',
  'report.contractContact.filterSubcontractors': '( sub contractors included ).',
  'report.contractContact.firstName': 'First name',
  'report.contractContact.info': 'Creates report on contract contacts',
  'report.contractContact.lastName': 'Last name',
  'report.contractContact.telephone': 'Phone',
  'report.contractorObligation.info': 'Get contractor obligation report',
  'report.device.info': 'Shows information about all the devices of your company.',
  'report.device': 'Device report',
  'report.deviceReportShort': 'Devices',
  'report.employeeSafetySummary.info': 'Safety summary',
  'report.employeeSafetySummary': 'Safety summary',
  'report.evaluation.evaluationAllSites': 'All evaluations',
  'report.evaluation.evaluationAllSitesTooltip': 'Creates a report that contains all evaluations from all sites',
  'report.evaluation.info': 'Create evaluation report',
  'report.evaluationsReport.info': 'Create evaluation report',
  'report.expats': 'Posted workers',
  'report.expatsOnlyDesc': 'Show only posted workers',
  'report.manpower.info': 'Report lists departments all clocked in employees by area',
  'report.manpower': 'Manpower area report',
  'report.manpowerReportShort': 'Manpower areas',
  'report.monitoring.timeSpanInfo': 'Includes the measurements done on the given interval or deviations that happened on the interval.',
  'report.presence.info': 'Report shows all persons who are currently clocked in to some site.',
  'report.presence': 'Person report',
  'report.quality.info': 'Creates an Excel file that lists all quality measurements or quality deviations.',
  'report.quality.quality': 'Quality',
  'report.quality.report': 'Quality report',
  'report.safety.bySite': 'One site',
  'report.safety.confirmTime': 'Confirm time',
  'report.safety.createBySiteTooltip': 'Creates a report for the given site',
  'report.safety.custom': 'Custom measurement',
  'report.safety.hazards': 'Hazards',
  'report.safety.hazardsbysite': 'Hazards from all sites',
  'report.safety.info.more': 'Luo Excel-taulukon tehtyjen turvallisuusmittausten tuloksista, erikoiskohteiden mittauksen tuloksista, positiivisista turvallisuushavainnoista tai turvallisuuspoikkeamista.<br/><br/>Turvallisuusmittausraportti ei sisällä erikoiskohteita. Erikoiskohteiden tulokset löytyvät erikoiskohderaportilta.',
  'report.safety.info': 'Creates an Excel file that lists all safety measurements, custom measurements, safety deviations or positive safety reports.',
  'report.safety.report': 'Report',
  'report.safety.safety': 'Safety',
  'report.safety.safetyreport': 'Safety report',
  'report.safety.site': 'Site',
  'report.safety.timespan': 'Time span',
  'report.safety.timespanTooltip': 'Includes measurements or hazards created in the given time span.',
  'report.safety.trmvrbyallsites': 'Measurements from all sites',
  'report.safety.trmvrbysite': 'Aggregation of measurements from all sites',
  'report.safety.trmvrbysiteTooltip': 'Aggregation of measurements creates a report with all sites, where the measurements of each site are summarized into one line',
  'report.serviceCalls': 'Service calls',
  'report.taxAdmin.info': 'Browse employee and contract reports sent to tax admin',
  'report.unresolvedContractPayments.info': 'Creates excel report from contractor payments which could not be automatically allocated to contractor',
  'report.unresolvedContractPayments.onlyFromLast13Monthsinfo': 'Only payments from the last 13 months can affect tax reporting.',
  'report.unresolvedContractPayments.report': 'Unallocated payments report',
  'report.unresolvedContractPayments.showOnlyFromLast13Months': 'Show unallocated payments only from the last 13 months',
  'report.unresolvedContractPayments.summaryinfo': 'List all unallocated payments, summary by site identifier/contractor or summary by site identifier.',
  'report.unresolvedContractPayments.summaryselection.all': 'All',
  'report.unresolvedContractPayments.summaryselection.contractor': 'Contractor summary',
  'report.unresolvedContractPayments.summaryselection.site': 'Site summary',
  'report.workHourBalance.info': 'Creates work hour balance report.',
  'report.workTime.deductLunch': 'Deduct 30min lunch break when total hours of the day are 6 or more',
  'report.workTime.officialHours': 'Show hours only from 7:00 to 15:30',
  'report.workTime.onlyOneCheckInPairPerDay': 'Calculate hours only from the first check-in of the day to the last check-out',
  'report.workTime.workTime': 'Work time',
  'reporterName': 'Reporter name',
  'reportersName': 'Reporters name',
  'reports.contractorNotification': 'Notification for contractor',
  'reports.noRights': 'You do not have rights for this report.',
  'reports.notifyContractor': 'Notify contractor',
  'reports.returnToReports': 'Return to reports',
  'reports.searchResults.many': '{0} reports',
  'reports.searchResults.one': '1 report',
  'reports.selectedReports': 'Selected reports',
  'reports.selectOneOrMore': 'Select one or more reports',
  'reports.sendingManyReports': 'Notifying about <strong>{0}</strong> reports',
  'reports.sendingOneReport': 'Notifying about 1 report',
  'residencePermit.continuousResidencePermit': 'Continuous residence permit',
  'residencePermit.longTermBrexitResidencePermit': 'Right of residence under the withdrawal agreement',
  'residencePermit.longTermEuResidencePermit': 'EU residence permit for third-country nationals with long-term EU resident status',
  'residencePermit.permanentBrexitResidencePermit': 'Right of permanent residence under the withdrawal agreement',
  'residencePermit.permanentResidencePermit': 'Permanent residence permit',
  'residencePermit.residencePermitNotNeeded': 'Residence permit not needed',
  'residencePermit.temporaryResidencePermit': 'Temporary residence permit',
  'safety.addDeviation': 'Add safety deviation',
  'safety.addingDeviation': 'Adding safety deviation',
  'safety.additionalDetails': 'Additional details',
  'safety.additionalDetailsAdded': 'Additional information is added',
  'safety.addNumberOfObservations': 'Add {0} observations',
  'safety.addPhoto': 'Add photo',
  'safety.addReport': 'Add report',
  'safety.allSites': 'All sites',
  'safety.allSitesGraphHelp': 'Average for all safety measurements on all sites',
  'safety.andMoreMeasurements': 'And {0} other measurements. Show all',
  'safety.anonymousReport': 'Anonymous report',
  'safety.anonymousReports': 'Anonymous reports',
  'safety.askForMore': 'Add many?',
  'safety.average': 'Average',
  'safety.averageLevel': 'Average level',
  'safety.class': 'Class',
  'safety.classes': 'Classes',
  'safety.clear': 'Clear',
  'safety.closedHazards': 'Closed hazards',
  'safety.confirm': 'Confirm',
  'safety.confirmBody': 'Mark the positive report as confirmed. You can also add a comment if you like.',
  'safety.confirmed': 'Confirmed',
  'safety.confirmer': 'Confirmer',
  'safety.confirmerComment': 'Confirmer comment',
  'safety.confirmHazard': 'Kuittaa havainto',
  'safety.confirming': 'Confirming...',
  'safety.confirmRepaired': 'Confirm reparation',
  'safety.confirmRepairedBody': 'Please confirm that this safety deviation has been rectified.',
  'safety.continueMeasurement': 'Continue <strong>{0} measurement</strong>',
  'safety.continueMeasurementNoArg': 'Continue measurement',
  'safety.contractorHasNoPersons': 'Contractor has no persons added.',
  'safety.contractorInCharge': 'Contractor in charge',
  'safety.customMeasurement': 'Custom measurement',
  'safety.customSectionDetails': 'Custom section details',
  'safety.date': 'Date',
  'safety.daysSickLeave': 'Sick leave days',
  'safety.daysSinceLatestInjury': 'days without an injury',
  'safety.description': 'Description',
  'safety.deviation': 'Safety deviation',
  'safety.deviationHelp': '<p><span class=\'far fa-info-circle\'></span><strong>An observation</strong> is a safety issue that haven\'t yet been realized.</p><p><strong>A near miss</strong> is an event that has or could have caused damage to other than people.</p><p>If a person has been injured, please file <strong>an injury</strong>.</p>',
  'safety.deviations': 'Safety deviations',
  'safety.deviationsForContractorsAndEmployees': 'Contractors and employees area able to report safety deviations via <a target="_blank" href="http://teehavainto.fi">teehavainto.fi</a>.',
  'safety.deviationsForContractorsAndEmployeesRequirements': '<p>Requirements:</p><ul><li>GPS</li><li>reporting on site</li></ul>',
  'safety.elmeriMeasurement': 'Elmeri+ measurement',
  'safety.elmeriMeasurements': 'Elmeri+ measurements',
  'safety.employeeRepresentativeSignature': 'Employee representative signature',
  'safety.employerRepresentativeSignature': 'Employer representative signature',
  'safety.entries': 'Entries',
  'safety.entriesTotal': 'Entries total',
  'safety.environmentalHazard': 'Environmental hazard',
  'safety.environmentalHazards': 'Environmental hazards',
  'safety.events': 'Safety events',
  'safety.followUpMeasures': 'Follow up measures',
  'safety.generalSettings': 'General settings',
  'safety.hazard': 'Safety hazard',
  'safety.hazardAlreadyRepairedMessage': 'The safety hazard has been marked as repaired already earlier.',
  'safety.hazardHelp': '<strong>A safety hazard</strong> is a safety issue that hasn\'t yet been realized.',
  'safety.hazardReason': 'Reason',
  'safety.hazardReasonShort': 'Attn.',
  'safety.hazards': 'Safety hazards',
  'safety.hazardTeehavainto': 'Teehavainto.fi',
  'safety.help.markingAsRepaired': 'Mark safety reports as repaired',
  'safety.help.subContractorPermissions': 'Subcontractor permission to safety reports',
  'safety.hideDetails': 'Hide details',
  'safety.hideFixedReports': 'Hide fixed entries',
  'safety.howToPreventInFuture': 'How to prevent in future',
  'safety.hse': 'Near misses and injuries',
  'safety.indexedDBunavailable': 'Your browser does not support the measurement. If you are using Safari, make sure that private mode is turned off.',
  'safety.injuredBodyPart': 'Injured body part',
  'safety.injuries': 'Injuries',
  'safety.injury': 'Injury',
  'safety.injuryHelp': 'If a person has been injured, please file <strong>an injury</strong>.',
  'safety.injuryInfo': 'Tapaturman tiedot',
  'safety.lastSync': 'Measurement synced to server at',
  'safety.level': 'Level',
  'safety.location': 'Location',
  'safety.manyUnsyncedReportsDanger': '<strong>There are many unsaved danger-reports.</strong> Please visit an area with better internet connection. Measurement can continue once reports have been saved.',
  'safety.manyUnsyncedReportsWarning': '<strong>There are quire a few unsynced entries.</strong> Saving wouldn\'t hurt. Perhaps you can walk somewhere with a better internet connection, for just a moment?',
  'safety.markAsConfirmed': 'Confirm',
  'safety.markAsRepaired': 'Mark as repaired',
  'safety.measure': 'Measure',
  'safety.measurement.area.contractors.help': 'You can choose one or more contractors',
  'safety.measurement.area.contractors': 'Contractors operating on the area',
  'safety.measurement.area.help': 'The measurement begins after the area has been selected.',
  'safety.measurement.area': 'Area',
  'safety.measurement.areas': 'Measurement areas',
  'safety.measurement.contractor': 'Contractor',
  'safety.measurement.hazardsWaitingForRepair': 'open hazards',
  'safety.measurement.hazardWaitingForRepair': 'open hazard',
  'safety.measurement.showCustomReport': 'Show custom report',
  'safety.measurement.showReport': 'Show report',
  'safety.measurement.start': 'Start measurement',
  'safety.measurement.type': 'Measurement type',
  'safety.measurement': 'Safety measurement',
  'safety.measurementAlreadyFinished': 'Measurement has already been finished',
  'safety.measurementAlreadyFinishedBy': '<strong>{0}</strong> has already finished this measurement <strong>{1}</strong>',
  'safety.measurementFromPast': 'Formerly completed measurement',
  'safety.measurementItems': 'Notes',
  'safety.measurementLoadingFailed': 'Loading measurement failed. Go back to Safety section and try to start measurement again.',
  'safety.measurements.asphaltFactory.new': 'New <strong>Asphalt measurement(Factory)</strong>',
  'safety.measurements.asphaltFactory': 'Asphalt measurement(Factory)',
  'safety.measurements.asphaltPaving.new': 'New <strong>Asphalt measurement(Paving sites)</strong>',
  'safety.measurements.asphaltPaving': 'Asphalt measurement(Paving sites)',
  'safety.measurements.boring.new': 'New <strong>boring measurement</strong>',
  'safety.measurements.boring': 'Boring measurement',
  'safety.measurements.cleanlinessindex.new': 'New <strong>cleanliness index</strong>',
  'safety.measurements.cleanlinessindex': 'Cleanliness index',
  'safety.measurements.crushing.new': 'New <strong>crushing measurement</strong>',
  'safety.measurements.crushing': 'Crushing measurement',
  'safety.measurements.general.new': 'New <strong>general safety measurement</strong>',
  'safety.measurements.general': 'General safety measurement',
  'safety.measurements.mining.new': 'New <strong>mining measurement</strong>',
  'safety.measurements.mining': 'Mining measurement',
  'safety.measurements.repairs.new': 'New <strong>repairs measurement</strong>',
  'safety.measurements.repairs': 'Repairs measurement',
  'safety.measurements.sac.new': 'New <strong>safety- and cleanliness measurement</strong>',
  'safety.measurements.sac': 'Safety- and cleanliness',
  'safety.measurements.scaffolding.new': 'New <strong>scaffolding measurement</strong>',
  'safety.measurements.scaffolding': 'Scaffolding',
  'safety.measurements': 'Work safety measurements',
  'safety.measurementsShort': 'Measurements',
  'safety.measurementType': '{0}-measurement',
  'safety.measurer': 'Measurer',
  'safety.mostUsed': 'Most used',
  'safety.moveToFinishedMeasurementSummary': 'Go to measurement summary',
  'safety.mvrMeasurement': 'MVR measurement',
  'safety.mvrMeasurements': 'MVR measurements',
  'safety.nearMiss': 'Near miss',
  'safety.nearMisses': 'Near misses',
  'safety.nearMissesShort': 'Near misses',
  'safety.nearMissHelp': '<strong>A near miss</strong> is an event that has or could have caused damage to other than people.',
  'safety.nearMissInfo': 'Tilanteen tiedot',
  'safety.newEnvironmentalHazard': 'New environmental hazard',
  'safety.newHazard': 'New hazard',
  'safety.newHazardWithStrong': 'New <strong>hazard</strong>',
  'safety.newInjury': 'New injury',
  'safety.newInjuryWithStrong': 'New <strong>injury</strong>',
  'safety.newMeasurement': 'New <strong>{0} measurement</strong>',
  'safety.newMeasurement1': 'New measurement',
  'safety.newNearMiss': 'New near miss',
  'safety.newNearMissWithStrong': 'New <strong>near miss</strong>',
  'safety.newSACMeasurement': 'New <strong>safety and cleanliness measurement</strong>',
  'safety.noAnonymousReports': 'No anonymous reports',
  'safety.noHazards': 'No hazards',
  'safety.noHazardsWithSelections': 'No safety hazards with current selections.',
  'safety.noInjuries': 'No injuries',
  'safety.noLocationNoDeviationHelp': 'Main contractor has not defined an area for this site. New safety deviations can be added after the area is defined.',
  'safety.noNearMisses': 'No near misses',
  'safety.noOpenEnvironmentalHazards': 'No open environmental hazards',
  'safety.noOpenHazards': 'No open hazards',
  'safety.noRepairedEnvironmentalHazards': 'No repaired environmental hazards',
  'safety.noRepairedHazards': 'No repaired hazards',
  'safety.numberOfObservations': 'Number to add',
  'safety.offlineMeasurement': '<strong>No internet.</strong> Measurement can be continued, but an internet connection is required for the measurement to end.',
  'safety.openDeviationsShort': 'Open',
  'safety.openHazards': 'Open hazards',
  'safety.openHazardsShort': 'Open hazards',
  'safety.otherCompany': 'Other company',
  'safety.pendingReparation': 'Pending reparation',
  'safety.peopleInjured': 'People injured',
  'safety.peopleInvolved': 'People involved',
  'safety.performMeasurement': 'Perform measurement',
  'safety.photo': 'Photo',
  'safety.photos': 'Photos',
  'safety.positiveHelp': '<strong>Positive report</strong> can be made when there has been exemplary safety or work environment actions.',
  'safety.previousLocation': 'Previous location',
  'safety.quarterPermissions': 'Safety quarter permissions',
  'safety.quarterPermissionsLegend': 'Companies that have the right to host safety quarters',
  'safety.quarters.add': 'Add safety quarter',
  'safety.quarters.delete': 'Delete safety quarter',
  'safety.quarters.documents.info': 'For example the participant list or own material',
  'safety.quarters.new': 'New safety quarter',
  'safety.quarters.noLinks': 'No links have been attached.',
  'safety.quarters.noMaterial': 'No material has been attached.',
  'safety.quarters.noPredefined': 'There are no predefined subjects on the site.',
  'safety.quarters.participantCount': 'Participant count',
  'safety.quarters.participants.info': 'The list of participants, the name of the group, contracts, etc.',
  'safety.quarters.participants': 'Participants',
  'safety.quarters.presenterName': 'Presenter',
  'safety.quarters.report.info': 'The list of safety quarters that have been held.',
  'safety.quarters.subject.newOnes': 'New subjects',
  'safety.quarters.subject.oldOnes': 'Handled subjects',
  'safety.quarters.subject.own': 'Own subject',
  'safety.quarters.subject': 'Subject',
  'safety.quarters': 'Safety quarters',
  'safety.reason': 'Reason',
  'safety.removeReport.confirm.body': 'Are you sure you want to remove the report <b>{0}</b>?',
  'safety.removeReport.confirm': 'Remove report?',
  'safety.removeReport': 'Remove report',
  'safety.removeSelection': 'Remove selection',
  'safety.repairBy': 'Repair by',
  'safety.repaired': 'Repaired',
  'safety.repairedOnTheSpot': 'Repaired on the spot',
  'safety.repairer': 'Repairer',
  'safety.repairerComment': 'Repairer comments',
  'safety.repairs': 'Repairs',
  'safety.report.email.info.label1': 'An email concerning the report is sent to the responsible person and',
  'safety.report.email.info.label2': 'people responsible for the safety of the site',
  'safety.report.email.info.p1': 'Additional safety contacts of the main contractor and the developer',
  'safety.report.email.info.p2': 'Contractor\'s security contact (defined on the contract)',
  'safety.report.email.info.p3': 'The site foreman',
  'safety.report.email.info.p4': 'The developer\'s and the main contractor\'s security contacts (defined on the contracts)',
  'safety.report.severityDeath': 'Death',
  'safety.report.severityMild': '1-30 days sick leave',
  'safety.report.severityNone': '0 days sick leave',
  'safety.report.severitySevere': '31+ days sick leave',
  'safety.reporter': 'Reporter',
  'safety.reporterName': 'Reporter name',
  'safety.reportNotSynced': '<b>1</b> deviation not synced.',
  'safety.reports': 'Safety reports waiting for repair',
  'safety.reportsNotSynced': '<b>{0}</b> deviations not synced.',
  'safety.reportsSyncedAt': 'reports synced to server at',
  'safety.responsiblePerson': 'Responsible person',
  'safety.restoreReport.confirm.body': 'Are you sure you want to restore the report <b>{0}</b>?',
  'safety.restoreReport.confirm': 'Restore report?',
  'safety.restoreReport': 'Restore report',
  'safety.right': 'Right',
  'safety.rightTotal': 'Right total',
  'safety.safetyByMonth': 'Safety by month',
  'safety.safetyBySite': 'Safety by site',
  'safety.safetyMeasurement': 'Safety measurement',
  'safety.safetyMeasurements': 'Safety measurements',
  'safety.safetyReports': 'TR/MVR-measurements',
  'safety.searchOrAddOtherCompany': 'Search term or other company',
  'safety.searchOrAddOtherLocation': 'Search term or other location',
  'safety.searchOrAddOtherReason': 'Search term or other reason',
  'safety.searchOrOther': 'Search/other',
  'safety.searchOrOtherCompany': 'Search/other',
  'safety.section': 'Section',
  'safety.sectionDetails': 'Section details',
  'safety.sections': 'Sections',
  'safety.selectYearAndMonth': 'Select year and month',
  'safety.showClosedHazards': 'Show closed hazards',
  'safety.showDetails': 'Show details',
  'safety.showSummary': 'Show summary',
  'safety.sign': 'Sign',
  'safety.signatures': 'Signatures',
  'safety.specialCategories': 'Special categories',
  'safety.syncedJustNow': 'Synced just now.',
  'safety.syncing': 'Syncing...',
  'safety.target': 'Target',
  'safety.targetLevel': 'Target level',
  'safety.trMeasurement': 'TR measurement',
  'safety.type': 'Type',
  'safety.waitingForSyncFinish': 'Waiting for sync to finish...',
  'safety.weekShort': 'Week',
  'safety.whatHappened': 'Description',
  'safety.whatIsMyDeviation': 'What kind of note should I add?',
  'safety.wrong': 'Wrong',
  'safety.wrongEntries': 'Entries marked as wrong',
  'safety.wrongTotal': 'Wrong total',
  'safetyMeasurement.reasonDescription': 'Detailed description',
  'safetyQuarter': 'Safety quarter',
  'safetyQuarters.contractorPresenter': 'Contractor / Presenter',
  'safetyQuarters.participantsOnAverage': 'Participant on avg.',
  'safetyQuarters.safetyQuartersQty': 'Safety quarters (Qty)',
  'safetyQuarters': 'Safety quarters',
  'sales.areYouInterestedInThisFeature': 'Interested in this feature?',
  'sales.leaveContactRequest': 'Leave a contact request and our sales will contact your company.',
  'sales.premiumService': 'PREMIUM<br>feature',
  'sales.requestSentModal.body': 'Thanks, we\'ll get back to you as soon as possible! <br/> - The Zeroni team',
  'sales.requestSentModal.heading': 'Contact request sent',
  'sales.sendContactRequest': 'Send contact request',
  'sales.sending': 'Sending...',
  'sales.siteCopy.isPremiumFeature': '<b>Copying sites</b> is a paid feature.',
  'sales.siteCopy.readMore': 'Read more about making copies of sites',
  'search.activeOrEnded': 'Access permit active / ended',
  'search.andNOthers': 'and {0} others',
  'search.declarationSearch': 'Report id search',
  'search.personNotFoundWithQRCode': 'No persons found with QR code.',
  'search.results': 'Results',
  'search.scanAgain': 'Scan again',
  'search.scanQRCode': 'Scan QR code',
  'search.searchCustomLocation': 'Select location',
  'search.searchOrSelectOrAddPlaceholder': 'Search, select or add',
  'search.searchOrSelectPlaceholder': 'Search or select',
  'search.showingOnlyPartOfResults': 'Showing only a part of results. Please refine your search.',
  'search.showingOnlyTenFirst': 'Showing only 10 first results',
  'search.terms': 'Search',
  'search.totalResults': '{0} results',
  'search': 'Search',
  'serverOffline': 'Connection lost.',
  'serverOfflineReconnecting': 'Connection lost. Reconnecting...',
  'settings.accessControlTransfer.modal.error': 'Failed to initiate transfer. Either same transfer has already been started or there is congestion in Zeroni. Please try again later.',
  'settings.accessControlTransfer.modal.execute': 'Start transfer',
  'settings.accessControlTransfer.modal.executing': 'Starting...',
  'settings.accessControlTransfer.modal.info': 'The transfer updates the access- and vehicle permits to the access control system.<br/><br/> Updating the permits may be necessary in exceptional circumstances after a communication problem or setting change.<br/><br/> The transfer takes several minutes and is executed in the background. The duration depends on the number of permits to be updated.',
  'settings.accessControlTransfer.modal.infoForRetry': 'The transfer updates the site and vehicle permits from all construction sites owned by the environment to the access control system for those permits whose transfer failed the previous time.<br/><br/> Depending on the number of permits, the transfer may take several minutes to complete. The transfer is performed in the background.',
  'settings.accessControlTransfer.modal.ready': 'Transfer started',
  'settings.accessControlTransfer.modal.success.title': 'Transfer started',
  'settings.accessControlTransfer.modal.success': 'The transfer complets at {0}',
  'settings.accessControlTransfer.modal.title': 'Start transfer',
  'settings.addressOfForeignEmployee': 'Address of foreign employee',
  'settings.admittance': 'Admittance',
  'settings.admittanceAsked': 'Admittance asked in access permit',
  'settings.allowJobTitleOutsideTheList': 'Allow to add job titles outside the list',
  'settings.allowMaterialsOutsideTheList': 'Allow to add materials outside the list',
  'settings.askForeignerRepresentative': 'Always ask for a Finnish representative for non-Finnish employees and employees living abroad',
  'settings.automaticDataSharingAgreementDesc': 'Create data sharing agreement document automatically when access permit is granted',
  'settings.automaticPermitCreation': 'Automatic permit creation',
  'settings.automaticPermitCreationDescription': 'If person does not already have active access permit, one will be automatically created for him when he clocks in with VALTTI card. Card must be active and the company from the card must have active contract on the site. If "Person verification" is enabled on the site, the new access permit will await for verification. Clock in from gate devices will not create new access permit.',
  'settings.automaticPermitCreationFromValttiCheckInEnabled': 'Create permit from valtti card check ins ',
  'settings.automaticVisitorPermitDesc': 'Create visitor permit automatically when access permit is granted',
  'settings.birthdayNotifications': 'Notification of birthdays',
  'settings.buyerInAccessPermit': 'Buyer\'s contact person',
  'settings.buyersReferenceId': 'Buyer\'s reference id required',
  'settings.buyersReferenceIdRequired': 'Buyer\'s reference id in invoice hours',
  'settings.commonDocs.info': 'Public documents are available to all contractors on the sites your company has created.',
  'settings.commonDocs': 'Public documents',
  'settings.companySettingsForPermit': 'Information asked for access permit',
  'settings.companySettingsForPermitInfo': 'You can define whether these fields need to be filled in order to create an access permit. These are default options that can be changed for every site.',
  'settings.course': 'Training or course',
  'settings.courseDuration': 'Duration',
  'settings.courseInfoText': 'Trainings and courses selectable for person\'s expertise',
  'settings.courseRemovalConfirmation': 'Are you sure you want to remove training or course <b>{0}</b>?',
  'settings.courses.durationHelp': '<strong>Duration</strong> may also be empty. In this case the course never expires.',
  'settings.courses': 'Trainings and courses',
  'settings.coursesAndCompetences': 'Courses and competences',
  'settings.coursesEmpty': 'No trainings or courses have been added.',
  'settings.createCustomSection': 'Create section',
  'settings.criterionChangeInfo': 'Changes the criterion title. Earlier evaluations remain, only criterion name is changed.',
  'settings.customMeasurement': 'Safety sections',
  'settings.customSafetyEventLocations.info': 'Event logging only to certain locations, meaning the location cannot be freely entered. Changes to these locations are made through support.',
  'settings.customSafetyEventLocations': 'Use customized safety event locations',
  'settings.customSafetySectionName': 'Name',
  'settings.customSafetySections': 'Safety measurement custom sections',
  'settings.customSection': 'Custom section',
  'settings.customSectionActive': 'Active section',
  'settings.customSectionDescription': 'Description',
  'settings.customSectionHelp.more': 'Safety measurement custom sections are sections that do not belong to standard measurement. They are measured simultaneously with the normal measurement.<br/><br/>You can edit the custom sections and create new ones on this pages. New measurements will include the custom sections that are marked as active. Custom sections do not change the result of the safety measurement. Note that you can not remove the custom section after it has been created. <br/><br/> The custom sections can be marked active inside a chosen site from "Manage" &rarr; "Safety sections".',
  'settings.customSectionHelp': 'Safety measurement custom sections are sections that do not belong to measurement.',
  'settings.customSectionName': 'Name',
  'settings.customSections': 'Custom sections',
  'settings.customSectionsEmpty': 'No custom sections have been added.',
  'settings.dailyReportSectionsEmpty': 'No sections of daily report template have been added.',
  'settings.dailyReportTemplate': 'Daily report template',
  'settings.dailyReportTemplateInfo': 'You can define the default daily report template on this page. It will be set automatically for the site upon the site\'s creation. You can make changes to the site template afterwards.',
  'settings.dailyReportTemplateSectionRemovalConfirmation': 'Are you sure you want to remove the section of the daily report template <b>{0}</b>?',
  'settings.dailyReportTemplateSections': 'Sections of daily report template',
  'settings.dailyReportTemplateSectionsEmpty': 'No sections of daily report template have been added.',
  'settings.dataSharingAgreement': 'Data sharing agreement',
  'settings.departmentRemovalConfirmation': 'Are you sure you want to remove department <b>{0}</b>?',
  'settings.departments': 'Departments',
  'settings.departmentsEmpty': 'No departments have been added.',
  'settings.departmentsInfo': 'You can use this page to define departments if your company has several departments and you want to group sites by department. Departments for which sites have already been defined can not be removed.',
  'settings.description': 'Description',
  'settings.descriptionOfVehiclePermitType': 'More detailed description of the vehicle permit.',
  'settings.durationInHours': 'hours',
  'settings.editCourse': 'Edit training or course',
  'settings.editDailyReportTemplateSection': 'Edit section of daily report template',
  'settings.editDepartment': 'Edit department',
  'settings.editOrientationTopic': 'Edit orientation topic',
  'settings.editProfession': 'Edit profession',
  'settings.editSection': 'Edit section',
  'settings.emailRequired': 'Email address required in contact details',
  'settings.emailRequiredShort': 'Email address required',
  'settings.expertise.companyFollowed.infoShort': 'Competencies selected for tracking at the company level are followed on every site.',
  'settings.expertise.followed.info': 'Competences defined on the company level are followed on all sites. They can\'t be removed from a site but you can define addition site specific competences in the site settings editor.',
  'settings.expertise.followed': 'Competences followed on sites',
  'settings.expertise.info': 'Default expertise requirements for new sites. You can change requirements for existing sites in the site\'s settings.',
  'settings.expertise.requiredCompetences': 'Required competences',
  'settings.expertise.requiredCourses': 'Required courses',
  'settings.expertise.requiredExpertise': 'Required expertise',
  'settings.finnishAddressRequiredForForeignEmployee': 'Finnish address required for foreign employees',
  'settings.gateAllowedTags': 'Gate access',
  'settings.general.clStatusAndTaxReport.info': 'The selected receivers will receive email about contractor liability and tax reports. An email is sent every time a contractor\'s contractor liability status becomes invalid. In addition, an email is sent once a month concerning tax report errors.',
  'settings.general.clStatusAndTaxReport': 'Contractor liability and tax report email receivers',
  'settings.general.clStatusNotficationContacts': 'Contracor liability notifications',
  'settings.general.clStatusNotficationContactsInfo': 'When contractors liability information becomes invalid, an e-mail is sent to the addresses given below. Notifications are sent daily only from changes happened within the last 24h.',
  'settings.general.gate.desc': 'Orientation required before a worker may enter through gates',
  'settings.general.gate': 'Gate',
  'settings.generateMissingCheckIns.info': 'Check-in is considered to be missing when no check-ins are found 13 hours after person clocked in or when no check-ins are found 13 hourse before person clocked out. Missing check-in will be generated 7 hours from check-in. Missing check-ins are generated twice a day at 5:00 and 12:00.',
  'settings.generateMissingCheckIns': 'Check-in hour generation',
  'settings.goEdit': 'Go to settings',
  'settings.hotWorkPermit.allowedFireExtinguishers.43A': 'Enable using fire extinguisher 43A',
  'settings.hotWorkPermit.allowedFireExtinguishers.enabled': 'Enabled',
  'settings.hotWorkPermit.allowedFireExtinguishers': 'Allowed extinguishers',
  'settings.hotWorkPermit.hotWorkCardRequiredFromHotWorkPerformersAndGuards': 'Hot work card required from hot work performers and guards',
  'settings.hotWorkPermit.hotWorkCardRequirement': 'Hot work card requirement',
  'settings.hotWorkPermitMaximumDuration': 'Hotwork permit maximum duration',
  'settings.hotWorkPermitMaximumDurationUnit': 'days',
  'settings.hotWorkPermitSettings': 'Default settings for hot work permits',
  'settings.hotWorkPermitSettingsInfo': 'These are default settings that are copied to a new site when it is created. You can change the site\'s settings from the site settings page.',
  'settings.hourBank': 'Hour bank',
  'settings.hourBankDesc': 'Hour bank available on invoice hours',
  'settings.idConfirmationPhotoInPermit': 'Photo of ID confirmation required',
  'settings.idConfirmationPhotoInPermitShort': 'ID confirmation photo',
  'settings.insurer': 'Hot work permit insurer',
  'settings.integrations.astora.includeAlreadyProcessed.info': 'Also search processed',
  'settings.integrations.astora.includeAlreadyProcessed': 'Processed',
  'settings.integrations.astora.invoiceHourTransfer.confirm': 'Are you sure that you want to move all approved invoice hours within time period to csv file. If no time period is given, all invoice horus are moved. This action can not be cancelled after confirmation.',
  'settings.integrations.astora.invoiceHourTransfer.info': 'You can use this function to search for approved invoice hours. You can limit the output to a given time period. The search marks the timesheet lines as processed and they will not appear again in the next search. By selecting "Also search processed", you can also search for previously processed invoice hours.',
  'settings.integrations.invoiceHourTransfer.confirm': 'Are you sure that you want to send all approved invoice hours within time period <b>{0} - {1}</b> to salary calculation? The action can not be cancelled.',
  'settings.integrations.invoiceHourTransfer.info': 'Here you can transfer approved invoice hours to V10 salary calculation system. Only invoice hours within specified time period will be included.',
  'settings.integrations.invoiceHourTransfer': 'Invoice hour transfer',
  'settings.integrations.mepco.confirm': 'Are you sure that you want to create the salary data for time period <b>{0} - {1}</b> and role <b>{2}</b> from approved invoice hour rows? The action can not be undone.',
  'settings.integrations.mepco.fetchPendingRows': 'Fetch pending rows',
  'settings.integrations.mepco.getExcel': 'Preview salary data',
  'settings.integrations.mepco.heading': 'Salary data creation',
  'settings.integrations.mepco.info': 'Here you can create an invoice hour Excel report for Mepco payroll computing. The hours are marked as transfered and they can\'t be included in another report. You can also preview the hours before creating the actual report.',
  'settings.integrations.mepco.noPendingRows': 'There are no pending rows on the period.',
  'settings.integrations.mepco.onlyApproved': 'Only approved',
  'settings.integrations.mepco.onlyPending': 'Only pending',
  'settings.integrations.mepco.pendingRows': 'The period still has pending rows!',
  'settings.integrations.mepco.rowStatus': 'Row status',
  'settings.integrations.mepco.showPendingHours': 'Include pending hours',
  'settings.integrations.mepco.showTransferedHours': 'Include transfered hours',
  'settings.integrations.mepco.transferHours.success.info': 'Salary data created.',
  'settings.integrations.mepco.transferHours.success.link': 'Open the file.',
  'settings.integrations.mepco.transferHours': 'Create salary data',
  'settings.integrations.mepco': 'Mepco',
  'settings.invoiceHours.checkedInHoursShown.info': 'Checked in hours are shown in employee\'s invoice hour view',
  'settings.invoiceHours.checkedInHoursShown': 'Check-in hour visibility',
  'settings.invoiceHours.copyApprover': 'Pre-select approver',
  'settings.invoiceHours.copyApproverFromPreviousEntry': 'Pre-select approver to match previous entry',
  'settings.jobtitles.fileFormatInfo': 'Excel (xlsx or xls) is recommended file type. If you wish to use csv file, content must be UTF-8 encoded.',
  'settings.jobTitlesUpdated': 'File <strong>{0}</strong> processed and the list of work labels updated.',
  'settings.laborAgreement': 'Labor agreement required',
  'settings.laborAgreementRequired': 'Collective labor agreement is required',
  'settings.minimumGuardingTimeAfterHotWork': 'Minimum guarding time after hotwork',
  'settings.modifyVehiclePermitType': 'Modify vehicle permit',
  'settings.newCourse': 'New training or course',
  'settings.newDailyReportTemplateSection': 'New section of daily report template',
  'settings.newDepartment': 'New department',
  'settings.newOrientationTopic': 'New orientation topic',
  'settings.newProfession': 'New profession',
  'settings.newSection': 'New section',
  'settings.newSiteVehiclePermitType': 'New vehicle permit type',
  'settings.noRights': 'You do not have rights for these settings.',
  'settings.onlySiteTagsToGates': 'Gate access only allowed for tags added to the site',
  'settings.orientationTopic': 'Orientation topic',
  'settings.orientationTopicRemovalConfirmation': 'Are you sure you want to remove orientation topic <b>{0}</b>?',
  'settings.orientationTopics': 'Orientation topics',
  'settings.orientationTopicsEmpty': 'No orientation topics have been added.',
  'settings.otherInsurer': 'Hot work permit not used',
  'settings.overTimeReasonRequired': 'Reason for exceeding checked in hours required',
  'settings.paperOrientation': 'Orientation on paper',
  'settings.paperOrientationAllowed': 'Confirmation of orientation in paper form is allowed alongside electronic confirmation',
  'settings.personsVerification.automatic.info': 'No separate verification. The access permit is valid as soon as the required competences and orientation have been added.',
  'settings.personsVerification.automatic': 'Automatic verification',
  'settings.personsVerification.manual.info': 'Every person\'s right to work is verified separately. The persons pending verification are shown on the dashboard notifications.',
  'settings.personsVerification.manual': 'Right to work needs to be verified',
  'settings.personsVerification.newVehiclePermit.convert.no': 'Delete all old vehicle permits. (Notice! This selection requires that all approved vehicle permits are to be created and approved again)',
  'settings.personsVerification.newVehiclePermit.convert.yes': 'Edit old vehicle permits to conform with the new vehicle permit module(default)',
  'settings.personsVerification.newVehiclePermitInfo': 'Has the old vehicle permit module been in use on this site? Notice that enabling verification of right to work also enables the new vehicle permit module. Verify how you want to handle old vehicle permits. Notice that this selection is permanent.',
  'settings.personsVerification': 'Verification of right to work',
  'settings.phoneNumberRequired': 'Phone number is required in contact details',
  'settings.phoneNumberRequiredShort': 'Phone number is required',
  'settings.postcodeMandatoryForPerson': 'Postcode is a required field for people living in Finland',
  'settings.profession': 'Profession',
  'settings.professionRemovalConfirmation': 'Are you sure you want to remove profession <b>{0}</b>?',
  'settings.professionRequired': 'Profession required in access permit',
  'settings.professions': 'Professions',
  'settings.professionsEmpty': 'No professions have been added.',
  'settings.removeCourse': 'Remove training or course',
  'settings.removeDailyReportTemplateSection': 'Remove section of daily report template',
  'settings.removeDepartment': 'Remove department',
  'settings.removeOrientationTopic': 'Remove orientation topic',
  'settings.removeProfession': 'Remove profession',
  'settings.representative': 'Representative asked for employees living abroad',
  'settings.residencePermitDocumentRequired': 'Residence permit documents have to be supplied',
  'settings.restAreaAsked': 'Rest area is required',
  'settings.restRoom': 'Rest room',
  'settings.safety.quarters.newSubject': 'New subject',
  'settings.safety.quarters.removeSubject.confirm': 'Are you sure you want to delete the subject <b>{0}</b>?',
  'settings.safety.quarters.removeSubject': 'Delete subject',
  'settings.safety.quarters.subject.material': 'Material',
  'settings.safety.quarters.subject': 'Subject',
  'settings.safety.quarters.subjectlistEmpty': 'No subjects have been added.',
  'settings.safety.quarters.subjects.edit': 'Edit subject',
  'settings.safety.quarters.subjects': 'Safety quarter subjects',
  'settings.safety.reports': 'Safety reports',
  'settings.safety.reportSettings': 'Safety report settings',
  'settings.safety.reportTimeRequired': 'Safety reports require timestamp',
  'settings.safety': 'Safety',
  'settings.safetyLocations': 'Safety Locations',
  'settings.safetySection': 'Safety section',
  'settings.safetySectionActive': 'Active',
  'settings.showBirthdayNotifications': 'Show notification on the front page when site\'s workers have birthdays',
  'settings.siteBasicDetails': 'Site\'s basic information',
  'settings.siteCardPhotoInPermit.info': 'Card needs to include person\'s name, photo and tax number, as well as company name, the type of business entity, and the business id.',
  'settings.siteCardPhotoInPermit.notRequired': 'Not required',
  'settings.siteCardPhotoInPermit.requiredForAll': 'Required for everyone',
  'settings.siteCardPhotoInPermit.requiredForForeigners': 'Required for foreigners',
  'settings.siteCardPhotoInPermit': 'Site card photo',
  'settings.siteSettings': 'Default site settings',
  'settings.siteSettingsInfo': 'These are default settings that are copied to a new site when it is created. You can change the site\'s settings from the site settings page.',
  'settings.siteSettingsNonConstructionInfo': 'A site doesn\'t necessarily have to be a traditional construction project, the definition can also cover other tasks such as maintenance. When changing this setting, check whether your site meets either criteria of the shared construction site: <ul class="mt-1"><li>The site lasts for more than 30 days, and there are more than 10 workers (including independent contractors) on site<br><em>or</em></li><li>The total work volume on the site exceeds 500 person-work days</li></ul>If these conditions are not met, then it\'s not considered a construction site and is not subject to the obligations defined by the <a href="https://www.finlex.fi/fi/laki/alkup/2009/20090205#Pidm46494958209712/" rel="noopener" target="_blank">Government Decree on the Safety of Construction Work <span class="far fa-external-link"></span></a> (finlex.fi).',
  'settings.siteSpecificJobTitles': 'Job titles',
  'settings.siteSpecificJobTitlesDesc': 'Use site specific job title list instead of a global list',
  'settings.siteVehiclePermitTypes': 'Site\'s vehicle permit types',
  'settings.specifyBuyerInAccessPermit': 'Use "buyer\'s contact person" field in access permit',
  'settings.supplierRegisterCriteria': 'Criteria for evaluating companies',
  'settings.updateCustomSection': 'Update section',
  'settings.updateSafetySections': 'Update safety sections',
  'settings.vehiclePermit': 'Vehicle permit',
  'settings.vehiclePermitAsked': 'Vehicle permit asked in access permit',
  'settings.vehiclePermitType': 'Vehicle permit type',
  'settings.vehiclePermitTypes': 'Vehicle permit types',
  'settings.visitor': 'Visitor',
  'settings.visitorAsked': 'Main contractor and developer can create visitor permits',
  'settings.visitorPermit': 'Visitor permit',
  'settings.visyTransfer': 'Visygate transfer',
  'settings.workPermitMaximumDuration': 'Work permit maximum duration',
  'site.additionalSelection': 'Additional selection',
  'site.additionalSettings': 'Additional settings',
  'site.anonReportInfo': 'Send message to recipients when new report is sent via Teehavainto.fi. Also send reminder message at night before Monday and Thursday',
  'site.anonReportReceivers': 'Receivers of Teehavainto.fi reports',
  'site.AsafetyMeasurementType': 'Selection',
  'site.askAboutContractEndDateChange': 'Do you want to change the end date of the site\'s contracts? This option updates all contracts <strong>whose end date is {0}</strong>. The new end date will be {1}.',
  'site.askAboutContractStartDateChange': 'Do you want to change the start date of the site\'s contracts? This option updates all contracts <strong>whose start date is {0}</strong>. The new start date will be {1}.',
  'site.askAboutPermitEndDateChange': 'Do you also want to change the end date of access permits? This option updates all access permits <strong>whose end date is {0}. The new end date is {1}.',
  'site.askAboutPermitStartDateChange': 'Do you also want to change the start date of access permits? This option updates all access permits <strong>whose start date is {0} and which are not orieted. The new start date is {1}.',
  'site.automaticPeriodChange': 'The start date and end date of the contracts and the permits will be changed automatically so that they are within the period {0} - {1}.',
  'site.automaticPeriodChangeContractsOnly': 'The start date and end date of the contracts will be changed automatically so that they are within the period {0} - {1}.',
  'site.bdayNotificationInfo': 'Sent on the morning of the day before birthday at 7',
  'site.bdayNotificationReceivers': 'Notification on birthdays of workers',
  'site.changeSite': 'Change site',
  'site.changingSite': 'Changing site...',
  'site.checkIns': 'Checkins',
  'site.chooseReceivers': 'Choose receivers',
  'site.continuousReportsToTaxAdmin': 'Reporting time is not set. Reports will be sent between site\'s start and end dates.',
  'site.contractors': 'Contractors',
  'site.copy': 'Copy',
  'site.dailyReport.template': 'Daily Construction Report Template',
  'site.dailyReport': 'Daily Construction Report',
  'site.dailyReports': 'Diary',
  'site.defineEmailReceivers': 'Recipients for email notifications for the site. The recipients are chosen from the users of the main contractor and the developer of the site.',
  'site.chosenDeveloperIsCustomer': 'The selected developer is a Zeroni customer. The developer will also be granted access to the site',
  'site.developerMustCreateSite': 'The selected developer is a Zeroni customer. Please contact the developer and ask them to create the site.',
  'site.editSiteInfo': 'Edit site',
  'site.emails.blackMarket.info': 'Will be sent when someone makes a report about black market on teehavainto.fi.',
  'site.emails.blackMarket': 'Black market',
  'site.emails.feedback.info': 'Will be sent when someone gives feedback on teehavainto.fi.',
  'site.emails.feedback': 'Feedback',
  'site.emails.HSE.info': 'Will be sent when someone makes a report about near misses or injuries.',
  'site.emails.HSE': 'Receivers of Near misses and Injuries reports',
  'site.emails.personsPendingVerification.info': 'Sent every night',
  'site.emails.personsPendingVerification': 'Notification about persons pending verification of right to work',
  'site.emails.vehiclePermitsPendingApproval': 'Notification about vehicle permits pending approval',
  'site.emails': 'Emails',
  'site.expertise.noRightsToModifyCustomExpertise': 'Training requirements are defined by the owner of the site.',
  'site.expertise.photoOfCompetenceCertificate': 'Competence verification',
  'site.expertise.photoOfCompetenceCertificateRequired': 'Photo of certificate required when creating new competence',
  'site.expertise.requiredCustomCompetences.inherited': 'Required by the developer',
  'site.expertise.requiredCustomExpertise': 'Required trainings on the site',
  'site.extendContractsAndPermits': 'You are extending the site duration. Also extend the duration of the site\'s contracts and access permits?',
  'site.extendingEndDate': 'You are changing the site\'s end date to a later date.',
  'site.extendingStartDate': 'You are changing the site\'s start date to an earlier date.',
  'site.followedCompetences.fromCompanyLevel': 'Followed competences on company level',
  'site.followedCompetences': 'Followed competences',
  'site.followedCompetencesAll': 'All competences',
  'site.followedCompetencesDescription': 'The dashboard notifications show expired and expiring competences. The notifications show only the selected competences.',
  'site.followedCompetencesRequired.description': 'See site settings, "Requirements"',
  'site.followedCompetencesRequired': 'This site\'s required competences',
  'site.followedCompetencesSelected': 'Custom selection',
  'site.gate.desc': 'Only oriented workers have access through gates',
  'site.gate': 'Gate',
  'site.generateMissingCheckins': 'Generate check-ins automatically if missing',
  'site.hasNoSafetyMeasurementType': 'Site has no safety measurement type selected',
  'site.hotWorkPermits': 'Hot work permits',
  'site.integration.visygateId': 'Visygate identifier',
  'site.introduction': 'Orientation',
  'site.link.description': 'Description (e.g. Google)',
  'site.link.example': 'Address (e.g. http://www.google.com)',
  'site.link': 'Link',
  'site.links': 'Links',
  'site.manage': 'Manage',
  'site.noContractors': 'No contractors',
  'site.noneSelected': 'Site is not selected',
  'site.noRequirementsAvailable': 'No compentences available',
  'site.noTrainingsAvailable': 'No trainings available',
  'site.number.acceptNumberSuggestion': 'Do you want to use {0}?',
  'site.number.autoGenerate': 'Find number',
  'site.number.edit': 'Edit number',
  'site.number.generationInfo': 'Zeroni can create the number that consists of a number part and text part, for example SITE00001. The application makes sure that there are no duplicate ids. Please input the text part below.',
  'site.number.placeholder': 'Please input the text part (min. 3 characters)',
  'site.optionalExpertise.inherited': 'Required by the developer',
  'site.optionalExpertise': 'Optional expertise',
  'site.ownerDocs': 'Public documents of the site\'s owner',
  'site.permitsNeedContracts': 'Extending permits requires extending contracts.',
  'site.persons': 'Persons',
  'site.quality': 'Quality',
  'site.reports': 'Reports',
  'site.requiredCompetences.inherited': 'Required by the developer',
  'site.requiredCompetences': 'Required competences on the site',
  'site.requiredExpertiseSettings': 'Required expertise',
  'site.safety': 'Safety',
  'site.safetyMeasurement.targetLevel': 'Target level',
  'site.safetyMeasurementType': 'Work safety measurement type',
  'site.safetyMeasurementTypes': 'Work safety measurement types',
  'site.safetySettings.contractorRights': 'Subcontractor measurement rights',
  'site.safetySettings.noTypesSelected': 'No safety measurement types selected',
  'site.safetySettings': 'Measurement settings',
  'site.searchOrSelect': 'Site',
  'site.searchOrSelectCompetenceModel': 'Competence',
  'site.searchOrSelectCustomCompetenceModel': 'Course',
  'site.selectSafetyMeasurementTypes': 'Select safety measurement types',
  'site.senderOfEmployeeReports': 'Sender of employee reports',
  'site.settings.contractorCheckins.modify': 'Can see and modify',
  'site.settings.contractorCheckins.noRights': 'Can\'t see or modify',
  'site.settings.contractorCheckins.read': 'Can see but not modify',
  'site.settings.contractorCheckins': 'Contractor\'s check in rights',
  'site.settings.customSectionHelp.more': 'Safety measurement custom sections are sections that do not belong to standard TR/MVR/Elmeri+ measurement. They are measured simultaneously with the normal measurement.<br/><br/> New measurements will include the custom sections that are marked as active. Custom sections do not change the result of the safety measurement.',
  'site.settings.customSectionHelp': 'Safety measurement custom sections are sections that do not belong to standard TR/MVR/Elmeri+ measurement.',
  'site.settings.safetyHelp': 'Settings for safety deviations and measurements, and topics for safety quarters.',
  'site.settings': 'Settings',
  'site.settingsForPermit': 'Information asked for access permit',
  'site.settingsForPermitInfo': 'You can define whether these fields need to be filled in order to create an access permit.',
  'site.showSiteInfo': 'Show sites info',
  'site.siteEmails': 'Site emails',
  'site.siteForeman.notSet': 'Not set',
  'site.siteForeman.set': 'Set foreman',
  'site.sixmonths': '6 months',
  'site.subcontractorSignedReports.label': 'Contract logbook signatures',
  'site.subcontractorSignedReports': 'Contract logbooks require signatures by subcontractors',
  'site.summary': 'Site summary',
  'site.threemonths': '3 months',
  'site.timePeriodForNoReportsToTaxAdmin': 'No reporting between',
  'site.timePeriodForReportsToTaxAdmin': 'Reporting between',
  'site.timePeriodForReportsToTaxAdminEnd': 'Reporting ends',
  'site.timePeriodForReportsToTaxAdminStart': 'Reporting starts',
  'site.twelvemonths': '12 months',
  'site.users': 'Site\'s users',
  'site.usersFromContractors': 'Contractors\' users',
  'site.usersFromMyCompany': '{0}\'s users',
  'site.usesInvoiceHours.reduceLunch.tooltip': 'Lunch is automatically reduced from invoice hours, if total worktime is atleast 6 hours and person has only one in- and out-checkins for the day',
  'site.usesInvoiceHours.reduceLunch': 'Automatic reduction of lunch',
  'site.usesInvoiceHours': 'Invoice hours used',
  'site.workHourBalancePeriodType': 'Work hour balance period',
  'site.workSafetyPlan': 'Work safety plan',
  'site.workSafetyPlans': 'Work safety plans',
  'siteCard.closeReason.damaged': 'Damaged',
  'siteCard.closeReason.lost': 'Lost',
  'siteCard.closeReason.returned': 'Returned',
  'siteCard.status.passive': 'Inadequate',
  'siteCard.status.printed': 'The site card has been picked up',
  'siteCard.status.printedShort': 'Picked up',
  'siteCard.status.suspended': 'Temporary site card is in use',
  'siteCard.status.waitingForPhotoshooting': 'Waiting for the site card pick-up',
  'siteCard.status.waitingForPrinting': 'Waiting for printing',
  'siteContractors.contractorList': 'Contractor list',
  'siteContractors.contractorTree': 'Contractor tree',
  'siteContractors.showInContractorTree': 'Show in contractor tree',
  'siteDashboard.searchHelp': 'Search for persons, contractors and others...',
  'siteEditor.fileFormatInfo': 'Excel (xlsx or xls) is recommended file format. Jobnumber in the first column and description in the second. If you wish to use csv-format, content must be UTF-8 encoded in the following form: jobnumber ; description',
  'siteEditor.getCurrentJobNumberList': 'Get current jobnumber list',
  'siteEditor.selectCSV': 'File format must be a .csv: jobnumber ; description',
  'siteLocation.mapHelp': '<strong>Start</strong> by clicking on a corner of the site. <strong>Add</strong> more points along the site border. <strong>Finish</strong> the area by clicking the starting point.',
  'siteLocation.mapInformation': 'The area is used in eq. mobile checkIns, site selection, teehavainto.fi-service.',
  'sites.usesInvoiceHours': 'Invoice hours used',
  'smfc.switch': 'Switch to Zeroni for Contractor',
  'smfc': 'Zeroni for Contractor',
  'sort.latestEndDate': 'Latest end date',
  'sort.latestStartDate': 'Latest start date',
  'sort.siteNumberAsc': 'Site number A→Z',
  'tag.allocateToSiteInfo': 'Tag can be used to clock in to all sites but by choosing a site you can better control the returning of tags in a site with tag report.',
  'tag.canOnlyBeReturnedBy': 'Not returnable. (Owner: {0})',
  'tag.changeOwner': 'Change owner',
  'tag.changing': 'Changing...',
  'tag.ClosedMessage': '<strong>Valtti card is closed</strong>. Enter another number.',
  'tag.closeReason.damaged': 'Damaged',
  'tag.closeReason.lost': 'Lost',
  'tag.closeReason.returned': 'Returned',
  'tag.confirmDamaged': 'Tag <strong>{0}</strong> as damaged?',
  'tag.confirmDeleted': 'Delete tag <strong>{0}</strong>?',
  'tag.confirmLost': 'Tag <strong>{0}</strong> as lost?',
  'tag.confirmReturn': 'Return tag <strong>{0}</strong>?',
  'tag.delete': 'Delete',
  'tag.employer': 'Employer:',
  'tag.ExpiredMessage': '<strong>Valtti card is expired</strong>. Enter another number.',
  'tag.help': 'Number for VALTTI-kortti, keyring, or other id tag',
  'tag.keyRingOrOther': 'Key right tag or other NFC id tag',
  'tag.markAsDamaged': 'Mark as damaged',
  'tag.markAsDamagedHeading': 'Mark tag as damaged?',
  'tag.markAsDeleted': 'Mark as deleted',
  'tag.markAsLost': 'Mark as lost',
  'tag.markAsLostHeading': 'Mark tag as lost?',
  'tag.markTagAsDamagedHeading': 'Mark tag as damaged',
  'tag.markTagAsDeletedHeading': 'Mark tag as deleted?',
  'tag.markTagAsLostHeading': 'Mark tag as lost?',
  'tag.newTag': 'New tag',
  'tag.noTags': 'No tags.',
  'tag.notAvailableMessage': '<strong>Tag is in use</strong> by another Zeroni -user</strong>. Add another number.',
  'tag.otherPersonsValttiMessage': '<strong>Tag belongs to another person </strong> VALTTI-card. VALTTI-cards can’t be transferred to other people.',
  'tag.return': 'Return',
  'tag.returnableMessage': '<strong>Tag is in use</strong> by person <strong>{0}</strong>. Do you want to move the tag to person <strong>{1}</strong>?',
  'tag.returnTagHeading': 'Return tag?',
  'tag.serviceError': 'An error occured when accessing id tags.',
  'tag.status.passive': 'Inadequate',
  'tag.status.printed': 'The site card has been picked up',
  'tag.status.printedShort': 'Picked up',
  'tag.status.suspended': 'Temporary site card is in use',
  'tag.status.waitingForPhotoshooting': 'Waiting for the site card pick-up',
  'tag.status.waitingForPrinting': 'Waiting for printing',
  'tag.status': 'Status:',
  'tag.statusClosed': 'Closed',
  'tag.statusExpired': 'Expired',
  'tag.statusInuse': 'Inuse',
  'tag.tagNumber': 'Tag number',
  'tag.tags': 'Tags',
  'tag.validUntil': 'Valid until:',
  'tag.valttiExpiredOrClosedMessage': 'Valtti card has expired or closed and is no longer available for use.',
  'tags.sending': 'Sending...',
  'tags.sendNew': 'Send new tags',
  'taitorekisteri': 'Taitorekisteri',
  'tax.contracts.info': 'These settings are only relevant to the reports of company <strong>{0}</strong> on this site.',
  'tax.reportsSentOnSite': 'Tax reports are sent on the site',
  'topMenu.contractors': 'Contractors',
  'topMenu.dailyReport': 'Diary',
  'topMenu.invoiceHours': 'Hours',
  'topMenu.loginAsCompany': 'Company to login as',
  'topMenu.loginAsEmployee': 'Employee to login as',
  'topMenu.manage': 'Manage',
  'travelExpenseClaim.accommodationDays': 'Accommodation Days',
  'travelExpenseClaim.alertAdded': 'Travel expense added',
  'travelExpenseClaim.alertApproved': 'Travel expense approved',
  'travelExpenseClaim.alertDeleted': 'Travel expense claim deleted',
  'travelExpenseClaim.alertMarkedAsInvoiced': 'Travel expense marked as invoiced',
  'travelExpenseClaim.alertRejected': 'Travel expense rejected',
  'travelExpenseClaim.alertRemoved': 'Travel expense removed',
  'travelExpenseClaim.alertUpdated': 'Travel expense updated',
  'travelExpenseClaim.approve': 'Approve',
  'travelExpenseClaim.approved': 'Approved',
  'travelExpenseClaim.approver': 'Approver',
  'travelExpenseClaim.begun': 'Begun',
  'travelExpenseClaim.car': 'Own car',
  'travelExpenseClaim.concise': 'Concise travel expense claim',
  'travelExpenseClaim.confirmRejection': 'Confirm rejection',
  'travelExpenseClaim.deleteClaimBody': 'Are you sure you want to delete <strong>{0}</strong>\'s travel expense claim?',
  'travelExpenseClaim.deleteClaimHeading': 'Delete claim?',
  'travelExpenseClaim.description': 'Expense description',
  'travelExpenseClaim.distance': 'Distance travelled',
  'travelExpenseClaim.ended': 'Ended',
  'travelExpenseClaim.expenses': 'Expenses',
  'travelExpenseClaim.expenseType': 'Expense type',
  'travelExpenseClaim.fixedExpense': 'Fixed expense',
  'travelExpenseClaim.fixedKilometreAllowanceInfo': '<strong>Enter</strong> fixed travel expense to ‘expenses’',
  'travelExpenseClaim.fullPerDiems': 'Full per diems',
  'travelExpenseClaim.fullPerDiemsUnit': 'Full per diems',
  'travelExpenseClaim.homeToSite': 'Expense from travel between home and site',
  'travelExpenseClaim.invoiced': 'Invoided',
  'travelExpenseClaim.jobNumber.ownVariant': 'Job number',
  'travelExpenseClaim.jobNumber': 'Job number',
  'travelExpenseClaim.jobNumberTh.ownVariant': 'Job number',
  'travelExpenseClaim.jobNumberTh': 'Job number',
  'travelExpenseClaim.kilometreAllowance': 'Kilometre allowance',
  'travelExpenseClaim.kilometreBasedExpense': 'Kilometer based',
  'travelExpenseClaim.markAsInvoiced': 'Mark as invoiced',
  'travelExpenseClaim.newExpense': 'New travel expense claim',
  'travelExpenseClaim.noExpenses': 'No expenses',
  'travelExpenseClaim.noPerDiem': 'No charge daily allowance',
  'travelExpenseClaim.noReceivedClaims': 'No received travel expense claims',
  'travelExpenseClaim.noRejectedClaims': 'No rejected travel claims',
  'travelExpenseClaim.noSentClaims': 'No sent travel expense claims',
  'travelExpenseClaim.notUsedOnSelectedSite': 'Travel expense claims are not being used at the selected site.',
  'travelExpenseClaim.other': 'Other (public, passenger along, taxi etc.)',
  'travelExpenseClaim.otherClaimHasPerDiem': 'Per diem included in another claim.',
  'travelExpenseClaim.otherThanOwnCar': 'Other than own car',
  'travelExpenseClaim.over10km': 'Over 10 km',
  'travelExpenseClaim.over120km': 'Over 120 km',
  'travelExpenseClaim.over20km': 'Over 20 km',
  'travelExpenseClaim.over30km': 'Over 30 km',
  'travelExpenseClaim.over40km': 'Over 40 km',
  'travelExpenseClaim.over50km': 'Over 50 km',
  'travelExpenseClaim.over5km': 'Over 5 km',
  'travelExpenseClaim.over60km': 'Over 60 km',
  'travelExpenseClaim.over70km': 'Over 70 km',
  'travelExpenseClaim.over80km': 'Over 80 km',
  'travelExpenseClaim.over90km': 'Over 90 km',
  'travelExpenseClaim.partialPerDiems': 'Partial per diems',
  'travelExpenseClaim.partialPerDiemsUnit': 'Partial per diems',
  'travelExpenseClaim.pendingApproval': 'Pending approval',
  'travelExpenseClaim.perDiem': 'Daily allowance',
  'travelExpenseClaim.perDiemEnabled': 'Charge daily allowance',
  'travelExpenseClaim.reasonForRejection': 'Reason for rejection',
  'travelExpenseClaim.receivedTravelExpenseClaims': 'Received travel expense claims',
  'travelExpenseClaim.reject': 'Reject',
  'travelExpenseClaim.rejectClaim': 'Reject claim',
  'travelExpenseClaim.rejected': 'Rejected',
  'travelExpenseClaim.removeClaim': 'Remove claim?',
  'travelExpenseClaim.requestConfirmationForDeletion': 'You are removing a travel expense claim for person <strong>{0}</strong>. Are you sure you want to remove the claim?',
  'travelExpenseClaim.route': 'Route',
  'travelExpenseClaim.selectApprover': 'Select approver',
  'travelExpenseClaim.sentTravelExpenseClaims': 'Sent travel expense claims',
  'travelExpenseClaim.showOnlyPending': 'Only claims pending approval',
  'travelExpenseClaim.showOnlyRejected': 'Show only rejected',
  'travelExpenseClaim.totalDays': '<strong>Total days:</strong> {0} days',
  'travelExpenseClaim.travelDays': 'Travel days',
  'travelExpenseClaim.travelHours': 'Travel hours',
  'travelExpenseClaim.undoInvoiced': 'Undo invoiced',
  'travelExpenseClaim.update.add': 'Add travel expense claim',
  'travelExpenseClaim.update': 'Update travel expense',
  'travelExpenseClaim.vehicle': 'Vehicle',
  'travelExpenseClaim': 'Travel expense claim',
  'travelExpenseClaims.changeApprover': 'Change approver',
  'travelExpenseClaims': 'travel expense claims',
  'up': 'Up',
  'user.accessToAllSites': 'Access to all sites',
  'user.admin': 'Administrator',
  'user.adminAccessInfo': 'Users with unrestricted access have access to all sites.',
  'user.changeLanguage': 'Change language',
  'user.grantedAccessToThisSite': 'Access granted to this site',
  'user.noUserRolePermissions': 'No user role permissions.',
  'user.otherUser': 'Other user',
  'user.otherUsers': 'Other users',
  'user.restrictedToSites': 'Access only to selected sites',
  'user.restriction': 'Restriction',
  'user.searchUserRole': 'Search user role',
  'user.selectUserRole': 'Select user role',
  'user.session': 'Session',
  'user.ssoHelp': 'Azure Active Directory is an authentication service. If Azure AD username is entered then this user will login to Zeroni using your Azure AD service.',
  'user.ssoUsername': 'Azure AD username',
  'user.superUser.remainder': '<strong>Warning!</strong> The user will be given an access to the all customer tenants!',
  'user.superUser': 'Super user',
  'user.unrestricted': 'Unrestricted access',
  'user.userInformation': 'User information',
  'user.userRole': 'User role',
  'userEditor.adminRestrictionInfo': 'Admins have automatically access to all sites.',
  'userEditor.emailAddress': 'Email',
  'userEditor.emailDomainIsDifferent': '<strong>Note:</strong> Email domain differs from your own. This user will gain access to your company. User accounts for your <em>contractors</em> are created via contracts.',
  'userEditor.firstName': 'First name',
  'userEditor.lastLogin': 'Last login',
  'userEditor.lastName': 'Last name',
  'userEditor.noLogins': 'User has no logins',
  'userEditor.onlyUserCanChange': 'Only person themself can modify.',
  'userEditor.otherRestrictionInfo': 'The user has a restricted access. You have to select the sites to which the user will have access.',
  'userEditor.removeSites': 'Remove all sites',
  'userEditor.selectUserGroup': 'Select user group',
  'userEditor.telephone': 'Telephone',
  'userEditor.userEmailExists': '<strong>Email address is already in use with another person!</strong> <br><i>Tip 1: Make sure that the person has not already registered with an invalid tax number.</i><br><i>Tip 2: Make sure that there is no another person with the same first and last name in your company.</i>',
  'userEditor.userExists': '<strong>User exists:</strong> Email address already in use.',
  'userEditor.userGroup': 'User group',
  'userEditor.valueChangeText': 'Username, email and phone can\'t be changed because of security reasons. If the values are incorrect, please contact support@zeroni.fi',
  'userGroup.companyRights': 'Access to companies',
  'userGroup.declineAllRights': 'Decline all rights',
  'userGroup.grantAllRights': 'Grant all rights',
  'userGroup.operation.add': 'Add',
  'userGroup.operation.addRemove': 'Add/Remove',
  'userGroup.operation.approve': 'Approve',
  'userGroup.operation.approveReject': 'Approve / Reject',
  'userGroup.operation.asterisk': 'Read',
  'userGroup.operation.cancel': 'Cancel',
  'userGroup.operation.confirm': 'Confirm',
  'userGroup.operation.connectDisconnect': 'Add to site / Remove from site',
  'userGroup.operation.continueSuspend': 'Suspend / Continue',
  'userGroup.operation.modify': 'Modify',
  'userGroup.operation.move': 'Move',
  'userGroup.operation.orient': 'Orientation',
  'userGroup.operation.read': 'Read',
  'userGroup.operation.remove': 'Remove',
  'userGroup.operation.repair': 'Repair',
  'userGroup.operation.subcontract': 'Subcontract',
  'userGroup.permissions.company': 'Companies',
  'userGroup.permissions.person.accessPermit.label': 'Remarks',
  'userGroup.permissions.person.accessPermit.role': 'Role',
  'userGroup.permissions.person.accessPermit': 'Access permits',
  'userGroup.permissions.person.additionalRequiredField.postcode': 'Postcode required',
  'userGroup.permissions.person.competence': 'Competences',
  'userGroup.permissions.person.drugTest': 'Drug test',
  'userGroup.permissions.person.healthCard': 'Health card',
  'userGroup.permissions.person.manualTaxNumberVerification': 'Tax number verification',
  'userGroup.permissions.person.newcomerTraining': 'New comer training',
  'userGroup.permissions.person.securityClearance': 'Security clearance',
  'userGroup.permissions.person.tags': 'Tags',
  'userGroup.permissions.person.workSafetyCard': 'Work safety card',
  'userGroup.permissions.person': 'Persons',
  'userGroup.permissions.site.clockIn': 'Clockins',
  'userGroup.permissions.site.contract.contractorObligation': 'Contractorobligation',
  'userGroup.permissions.site.contract.payment': 'Payments',
  'userGroup.permissions.site.contract.requestForApproval': 'Approval',
  'userGroup.permissions.site.contract': 'Contracts',
  'userGroup.permissions.site.dailyReport.supervisor': 'Supervisors notes',
  'userGroup.permissions.site.dailyReport.template': 'Daily Construction Report Template',
  'userGroup.permissions.site.dailyReport': 'Daily Construction Report',
  'userGroup.permissions.site.device': 'Devices',
  'userGroup.permissions.site.doc': 'Documents',
  'userGroup.permissions.site.invoiceHours': 'Invoice hours',
  'userGroup.permissions.site.requiredCompetences': 'Requirements',
  'userGroup.permissions.site.safety.deviation.anonymous': 'Anonymous',
  'userGroup.permissions.site.safety.deviation.environment': 'Environment',
  'userGroup.permissions.site.safety.deviation.positive': 'Positive',
  'userGroup.permissions.site.safety.deviation': 'Safety deviations',
  'userGroup.permissions.site.safety.measurement': 'Safety measurements',
  'userGroup.permissions.site.safety.summary': 'Safety summary from sites',
  'userGroup.permissions.site.safety': 'Safety',
  'userGroup.permissions.site.travelExpense': 'Travel expenses',
  'userGroup.permissions.site': 'Sites',
  'userGroup.personRights': 'Access to persons',
  'userGroup.safetyRights': 'Rights to safety Oikeudet turvallisuusmerkintÃ¶ihin',
  'userGroup.sitePermissionsInfo.sitePermissionsInfo': 'The right to modify tax report settings of a site is bound to the right to create a new site.',
  'userGroup.sitePermissionsInfo': 'Permission to modify sites tax report settings has been linked to permission to add new sites.',
  'userGroup.siteRights': 'Rights to contracts',
  'userGroup.standardCantBeChanged': 'Standard group cannot be changed.',
  'userGroup.standardGroup': 'Standard group',
  'userGroup.thisIsStandard': 'This is a standard group.',
  'userGroup.userGroupsModifyInfo': 'Only admins have right to modify user roles.',
  'userSettings.changePassword': 'Change password',
  'userSettings.defaultCompany': 'Default company',
  'userSettings.defaultCompanyAndSite': 'Default company and site',
  'userSettings.defaultCompanyAndSiteIntro': 'Default company and site will be automatically selected upon login.',
  'userSettings.defaultCompanyAndSiteShort': 'Default company',
  'userSettings.defaultSite': 'Default site',
  'userSettings.defaultSiteIntro': 'Default site will be automatically selected upon login.',
  'userSettings.defaultSiteOnlyForDefaultCompany': 'Default site can only be set if you\'ve logged in as a default company (currently you are logged in {0}).',
  'userSettings.settingsSaved': 'Settings saved!',
  'userSettings': 'User settings',
  'validator.errors.emailFormat': 'Invalid email address',
  'validator.errors.emailNotUnique': 'Someone else already has this address',
  'validator.errors.phoneFormat': 'Invalid phone number',
  'validator.errors.phoneNotUnique': 'Someone else already has this number',
  'validator.errors.preventEmptying': 'Contact information is required for a person to log in.',
  'validator.errors.required': 'Required',
  'validator.valueNotChanged': 'Value has not changed',
  'vehiclePermit.active': 'Active',
  'vehiclePermit.activeDaily': 'Active daily',
  'vehiclePermit.approvalCancelledUponChange': 'Approval will be cancelled if vehicle details are changed.',
  'vehiclePermit.approved': 'Approved',
  'vehiclePermit.approvedOn': 'Approved {0}.',
  'vehiclePermit.approvers': 'Approvers',
  'vehiclePermit.cancelApprovalAndEdit': 'Cancel approval and edit vehicle details',
  'vehiclePermit.cancelRejectionAndEdit': 'Cancel rejection and edit vehicle details',
  'vehiclePermit.confirmRemoveVehicleFromVehiclePermit': 'Are you sure you want to remove the vehicle {0}?',
  'vehiclePermit.confirmVehiclePermitDeletion': 'Are you sure you want to delete this vehicle permit?',
  'vehiclePermit.customOrforeignRegistrationNumber': 'Custom or foreign registration number',
  'vehiclePermit.duration': 'Duration',
  'vehiclePermit.expired': 'Expired',
  'vehiclePermit.hasToBeActiveForAtLeast1Minute': 'Has to be active for at least 1 minute.',
  'vehiclePermit.missingAccessPermitForPeriods': 'Person doesn\'t have active access permit for following dates {0}. You can create vehicle permit for with currently selected time period, but the permit will not be active during the aforementioned dates.',
  'vehiclePermit.missingUsersFromListOfPossibleApprovers': 'If the person you are looking for is not on the list, make sure that they have user role which has permission to approve vehicle permits.',
  'vehiclePermit.newVehiclePermit': 'New vehicle permit',
  'vehiclePermit.noApproversThus': 'No vehicle approvers selected. Administrators will approve vehicle permits.',
  'vehiclePermit.noPermission': 'No vehicle permits permission.',
  'vehiclePermit.noPermissionVerbose': 'Your user role does not have permission to read vehicle permits. Please ask administrative user of your company to add required permissions to your current user role. Please note that the updated permissions come into effect after you logout and log back in.',
  'vehiclePermit.noPermitsOnThiSite': 'No vehicle permits on this site.',
  'vehiclePermit.notValid': 'Inactive',
  'vehiclePermit.noValidAccessPermitsForSelectedContract': 'Person doesn\'t have access permits which are currently active or will become active in the future for selected contract.',
  'vehiclePermit.pending': 'Pending approval',
  'vehiclePermit.permitApprovers': 'Approvers',
  'vehiclePermit.personHasOverlappingVehiclePermits': 'Person already has the following vehicle permits:',
  'vehiclePermit.rejected': 'Rejected',
  'vehiclePermit.rejectedOn': 'Rejected {0}.',
  'vehiclePermit.requester': 'Requester',
  'vehiclePermit.search': 'Search',
  'vehiclePermit.searchHelp': 'Reg. number or person\'s name',
  'vehiclePermit.searchResults.many': '{0} vehicle permits',
  'vehiclePermit.searchResults.one': '1 vehicle permit',
  'vehiclePermit.showActiveAndUpcoming': 'Active and upcoming',
  'vehiclePermit.showAll': 'All',
  'vehiclePermit.showApprovable': 'Pending approval',
  'vehiclePermit.showApproved': 'Approved',
  'vehiclePermit.showPendingAndWaitingActions': 'Pending approval and waiting actions',
  'vehiclePermit.showWaitingActions': 'Waiting actions',
  'vehiclePermit.status': 'Status',
  'vehiclePermit.timePeriod': 'Time period',
  'vehiclePermit.upcoming': 'Upcoming',
  'vehiclePermit.upcomingWithStartDate': 'Comes into effect at {0}',
  'vehiclePermit.updateRevertsVehiclePermitBackToPendingState': 'Are you sure you want to update this vehicle permit? Updating will revert vehicle permit back to "Pending approval" state.',
  'vehiclePermit.useAsTemplate': 'Use as a template',
  'vendorEvaluation.averageAll': 'Average of all evaluations',
  'vendorEvaluation.siteAverage': 'Average of evaluations at this site',
  'visa.expirationDate': 'Expiration date',
  'vm.contract.continuing': 'Continuing...',
  'vm.contract.interrupting': 'Interrupting...',
  'weeklyHours.help': 'Weekly hours are used to determine frequency for accidents.',
  'workPermit.active': 'Active',
  'workPermit.addPermit': 'Add permit',
  'workPermit.address': 'Address',
  'workPermit.addressPlaceHolder': 'Address',
  'workPermit.approvalAndSignaturesSideButton': 'Approval and Signatures',
  'workPermit.approverSignature': 'Signature of approver',
  'workPermit.area': 'Name of the workplace',
  'workPermit.areDangersIdentified': 'Have work related risks been identified and relevant precautions been applied?',
  'workPermit.assignment': 'Assignment',
  'workPermit.backToWorkPermits': 'Back work permits',
  'workPermit.changePermitOwner': 'Change permit owner',
  'workPermit.close': 'Ending work permit',
  'workPermit.closed': 'No closed work permits',
  'workPermit.confirmationOfHandoverOfPermission': 'The new permit owner confirms that the hazard controls as stated on this PTW and attached documents will be strictly observed and all persons in my work team will be informed of them',
  'workPermit.confirmationTextOfApproval': 'Permit approver acknowledge that all preparatory work including isolations has been completed and that work is safe to proceed.',
  'workPermit.confirmationTextOfPermitOwner': 'Permit owner fully understand that the hazard controls are in place for this work to proceed.',
  'workPermit.confirmChangeOfPermitOwner': 'Confirm change of permit owner',
  'workPermit.currentWorkPermitHolder': 'Current employee',
  'workPermit.dangerEstimation': 'Danger estimation',
  'workPermit.dangerEstimationMissing': 'Reason for the missing evaluation',
  'workPermit.dangerEvaluation': 'Estimation of danger (JSEA)',
  'workPermit.dangerSurvey': 'Danger identification',
  'workPermit.describe': 'Describe',
  'workPermit.description': 'Work description and purpose',
  'workPermit.description2': 'description',
  'workPermit.descriptionAndGeneralInfo': 'Work description, Period of validity, Contractor and Approver',
  'workPermit.descriptionAndGeneralInfoSideButton': 'Description, Employees, danger survey',
  'workPermit.duration': 'Duration',
  'workPermit.electricalLockingNumber': 'Number of electric locking',
  'workPermit.electricLocking': 'Electric locking',
  'workPermit.employeeAndIssuingDate': 'Employee and date of Issue',
  'workPermit.employeeNames': 'Employee names',
  'workPermit.employer': 'Contractor',
  'workPermit.equipments': 'Equipments',
  'workPermit.extend': 'Transferring work permit',
  'workPermit.extending': 'Work permit extension approval',
  'workPermit.exWork': 'Ex-work',
  'workPermit.formerPermitOwnerSignature': 'Signature of former permit owner',
  'workPermit.hotWorkLocking': 'Hot work',
  'workPermit.issuingPermit': 'Issuing a work permit',
  'workPermit.jobTitle': 'Job title',
  'workPermit.jobTitleDescription': 'Job description',
  'workPermit.location': 'Location',
  'workPermit.locked': 'Locked',
  'workPermit.locked2': 'LOCKED',
  'workPermit.locker': 'Submitter',
  'workPermit.lockingNumber': 'lock number',
  'workPermit.mechanicalLocking': 'Mechanical lockings',
  'workPermit.mechanicalLockingNumber': 'Number of mechanical locking',
  'workPermit.mobile.equipments': 'Equipm.',
  'workPermit.mobile.general': 'General',
  'workPermit.mobile.security': 'Secur.',
  'workPermit.mobile.signaturesAndConfirmation': 'Confirm.',
  'workPermit.nameOfSiteBuildingDepartmentRegionPlaceHolder': 'Name of company, building, department or/and location',
  'workPermit.new': 'New work permit',
  'workPermit.newWorkPermit': 'New work permit',
  'workPermit.newWorkPermitOwner': 'New permit owner',
  'workPermit.newWorkPermitOwnerSignature': 'Signature of the permit recipient',
  'workPermit.nextStep': 'Next',
  'workPermit.noActivePermits': 'No active work permits',
  'workPermit.none': 'No work permits',
  'workPermit.noPermitsHaveBeenAdded': 'No work permits have been added',
  'workPermit.noTerminatedPermits': 'No terminated permits',
  'workPermit.opened': 'Opened',
  'workPermit.opened2': 'OPENED',
  'workPermit.openLockings': 'Open lockings',
  'workPermit.otherWork': 'Other work',
  'workPermit.permitOwner': 'Permit holder',
  'workPermit.permitOwners': 'Permit holders',
  'workPermit.permitOwnerSignature': 'Signature of permit owner',
  'workPermit.permitValidDailyAt': 'Permit valid daily at',
  'workPermit.personInCharge': 'PERSON IN RESPONSIBILITY',
  'workPermit.personsInCharge': 'Person in responsibility',
  'workPermit.precautions': 'Precautions',
  'workPermit.precautionsSideButton': 'Precautions',
  'workPermit.remove': 'Remove',
  'workPermit.riskEvaluation': 'Estimation of risks',
  'workPermit.safetyLocking': 'SAFETY LOCKING',
  'workPermit.safetyLockings': 'Safety lockings',
  'workPermit.signatureHolder': 'Signature of the permit holder',
  'workPermit.signatureIssuer': 'Signature of the permit issuer',
  'workPermit.subscriber': 'Work description, validity period,developer and approver',
  'workPermit.terminate': 'Terminate',
  'workPermit.terminated': 'Terminated',
  'workPermit.terminateWorkPermit': 'Terminate work permit',
  'workPermit.terminationConfirmationText': 'All Work associated with this Permit is complete and safe and no further work is permitted.',
  'workPermit.toolsSideButton': 'Equipments',
  'workPermit.transferToOther': 'Transfer of work permit to a different person',
  'workPermit.workDescription': 'Reason:',
  'workPermit.workers': 'Employees',
  'workPermit.workPermitApprover': 'Permit Approver',
  'workPermit.workPermitDelete': 'Remove work permit?',
  'workPermit.workPermitDeleteMessage': 'Are you sure you want to delete the work permit?',
  'workPermit.workPermitHolder': 'Employees',
  'workPermit.workPermits': 'Work permits',
  'workPermit': 'Work permit',
  'workPermits.close': 'Closing work permit',
  'workPermits.extend': 'Extending work permit',
  'workPermits.new': 'New work permit',
  'workPermits': 'Work permits',
  'zfc': 'Zeroni for Contractor',
};
